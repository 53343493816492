import React from 'react'
import { FormGroup, Switch, FormControlLabel } from '@mui/material'

interface SimpleSwitchProps {
    value: string
    label: string
    checked: boolean
    disabled?: boolean
    placement?: 'end' | 'start' | 'top' | 'bottom'
    color?: 'default' | 'primary' | 'secondary'
    onChanged(name: string, checked: boolean): void
}

export default function SimpleSwitch(props: SimpleSwitchProps) {
    const handleChange =
        (name: string) => (event: React.ChangeEvent<{}>, checked: boolean) => {
            props.onChanged(name, checked)
        }

    return (
        <FormGroup row>
            <FormControlLabel
                label={<small>{props.label}</small>}
                labelPlacement={props.placement ?? 'start'}
                control={
                    <Switch
                        checked={props.checked}
                        onChange={handleChange(props.value)}
                        value={props.value}
                        color={props.color ?? 'primary'}
                        disabled={props.disabled}
                    />
                }
            />
        </FormGroup>
    )
}
