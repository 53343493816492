import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import translate from '../i18n/Translator'
import ValidatedInput, {
    useValidatedRequest,
} from '../components/ValidatedInput'
import { TenantListParams } from '../model/Tenant'

function TenantsFilter() {
    const history = useHistory()
    const query = new URLSearchParams(useLocation().search)
    const search = query.get('search')
    const [request, , , hasChanged] = useValidatedRequest({
        search: search,
    } as TenantListParams)

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        query.set('search', request.search ?? '')
        query.set('page', '1')

        history.push(`${window.location.pathname}?${query.toString()}`)
    }

    return (
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Grid
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
            >
                <Grid item xs={12} sm>
                    <ValidatedInput
                        type="text"
                        id="search"
                        name="search"
                        label={translate('tenants.filter') as string}
                        value={request.search}
                        onValueChanged={hasChanged}
                    />
                </Grid>
                <Grid item xs="auto">
                    <Button type="submit" color="secondary" size="medium">
                        {translate('buttons.search')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

const useTenantsParams = (query: URLSearchParams) => {
    const search = query.get('search')
    const params = useMemo(() => {
        return { search: search || '' } as TenantListParams
    }, [search])

    return params
}

export default TenantsFilter

export { useTenantsParams }
