import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { IconButton, Divider, Button, Fab, Box } from '@mui/material'
import { DesignsIcon, MoreVertIcon, AddIcon } from '../components/Icons'
import { listDesigns, deleteDesign } from '../api/DesignAPI'
import { AppContext } from '../context/AppContext'
import { Design, Designs, DesignsListParams } from '../model/Design'
import translate from '../i18n/Translator'
import Pagination, {
    initialPage,
    initialPageSize,
    getOffset,
} from '../components/Pagination'
import { Griddable } from '../components/Griddable'
import Ellipsis from '../components/Ellipsis'
import DialogPopup from '../components/DialogPopup'
import DesignMenu from './DesignMenu'
import { FeedbackContext } from '../feedback/FeedbackContext'
import SearchFilter, { useSearchParams } from '../components/SearchFilter'
import { RouterParams } from '../router/RouterParams'
import { DateFormat } from '../components/DateFormat'
import DesignPreviewPopup from './DesignPreviewPopup'
import { Christian } from '../model/Guest'

export default function DesignsList({ match }: RouterParams) {
    const history = useHistory()
    const feedbackContext = useContext(FeedbackContext)
    const context = useContext(AppContext)
    const paramTenantId = match.params.tenantId
    const tenantId = context.session?.tenant?.id || paramTenantId || '-'
    const firstName = context.session?.user?.first_name

    const query = new URLSearchParams(useLocation().search)
    const params = useSearchParams<DesignsListParams>(query)

    const [status, setStatus] = useState<string>('loading')
    const [data, setData] = useState<Designs>()

    const [design, setDesign] = useState<Design>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [popup, setPopup] = useState<'delete' | 'preview'>()

    const [page, setPage] = useState<number>(initialPage(query))
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query))
    const [isCreateGranted] = useState(
        context.isGrantedAny(['PDesignsCreate', 'TDesignsCreate'])
    )

    const retrievePromise = useCallback(() => {
        const offset = getOffset(page, pageSize)
        return listDesigns(tenantId, { ...params, page: pageSize, offset })
    }, [tenantId, page, pageSize, params])

    const load = () => {
        retrievePromise()
            .then((response) => {
                setData(response)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }

    useEffect(load, [retrievePromise])

    const onChangedPage = (page: number) => {
        setPage(page)
        setData(undefined)
    }

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page)
        setPageSize(pageSize)
        setData(undefined)
    }

    const onClickedOptions =
        (design: Design) => (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setDesign(design)
        }

    const onClosePopup = () => {
        onCloseOption()
        setPopup(undefined)
    }

    const onDelete = () => {
        if (!design) return

        setAnchorEl(null)
        setPopup('delete')
    }

    const onPreview = () => {
        if (!design) return

        setAnchorEl(null)
        setPopup('preview')
    }

    const onConfirmDelete = () => {
        onClosePopup()
        if (!design) return

        feedbackContext.showBackdrop()
        deleteDesign(tenantId, design.id)
            .then(() => {
                feedbackContext.showSuccess(
                    translate('designs.delete.success', {
                        id: design.name,
                    }) as string
                )
                load()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onCloseOption = () => {
        setAnchorEl(null)
        setDesign(undefined)
    }

    const onClickRow = (design: Design) => {
        history.push(
            `${paramTenantId ? `/accounts/${paramTenantId}` : ''}/designs/${
                design.id
            }/edit`
        )
    }

    return (
        <Pagination
            title={translate('designs.title')}
            subtitle={paramTenantId ? data?.tenant?.name : undefined}
            icon={<DesignsIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            backButton={!!paramTenantId}
            action={
                isCreateGranted ? (
                    <Link
                        to={`${
                            paramTenantId ? `/accounts/${paramTenantId}` : ''
                        }/designs/new`}
                    >
                        <Fab
                            color="primary"
                            size="small"
                            title={translate('buttons.add') as string}
                        >
                            <AddIcon />
                        </Fab>
                    </Link>
                ) : undefined
            }
        >
            <SearchFilter title={translate('designs.filter') as string} />
            <Box mx={-2} mt={2}>
                <Divider />
                <Griddable
                    items={data ? data.items : []}
                    loading={status === 'loading'}
                    error={
                        status !== 'loading' && status !== 'loaded'
                            ? status
                            : undefined
                    }
                    empty={translate('designs.empty') as string}
                    clickable={{
                        onClick: onClickRow,
                    }}
                    getId={(design) => design.id}
                    columns={[
                        {
                            title: translate('designs.name') as string,
                            converter: (design) => (
                                <Ellipsis
                                    text={design.name}
                                    lenght={100}
                                    uppercased={false}
                                />
                            ),
                            xs: true,
                        },
                        {
                            title: translate('designs.message') as string,
                            converter: (design) => (
                                <Ellipsis
                                    text={design.message}
                                    lenght={100}
                                    uppercased={false}
                                />
                            ),
                            xs: false,
                            sm: 4,
                            lg: 6,
                            xl: 6,
                        },
                        {
                            title: translate('designs.created_at') as string,
                            converter: (design) => (
                                <DateFormat
                                    date={design.created_at}
                                    pattern="MMM-dd-yyyy"
                                />
                            ),
                            xs: false,
                            sm: 3,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: (
                                <IconButton
                                    size="small"
                                    style={{ visibility: 'hidden' }}
                                    disabled
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            converter: (design) => (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onClickedOptions(design)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            xs: 'auto',
                        },
                    ]}
                />
            </Box>
            {design && anchorEl && (
                <DesignMenu
                    mode="grid"
                    tenantId={paramTenantId}
                    design={design}
                    anchor={anchorEl}
                    onDelete={onDelete}
                    onPreview={onPreview}
                    onClose={onCloseOption}
                />
            )}
            {design && popup === 'delete' && (
                <DialogPopup
                    open
                    title={translate('designs.delete.title')}
                    onClose={onClosePopup}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="outlined"
                            color="error"
                        >
                            {translate('buttons.delete')}
                        </Button>
                    }
                >
                    {translate('designs.delete.text', { id: design.name })}
                </DialogPopup>
            )}
            {design && popup === 'preview' && (
                <DesignPreviewPopup
                    tenantId={tenantId}
                    design={design}
                    guest={
                        firstName
                            ? { ...Christian, name: firstName }
                            : Christian
                    }
                    onClose={onClosePopup}
                />
            )}
        </Pagination>
    )
}
