import { useMemo } from 'react'
import { S3 } from '@aws-sdk/client-s3'
import { Credentials } from '@aws-sdk/types'

export class CustomS3Client {
    client: S3
    bucket: string
    dirName: string
    endpoint: string

    constructor(client: S3, bucket: string, dirName: string, endpoint: string) {
        this.client = client
        this.bucket = bucket
        this.dirName = dirName
        this.endpoint = endpoint
    }

    fixKey(path: string, file: File, randomPreffix: boolean = true): string {
        return this.calculateKey(path, file.name, randomPreffix)
    }

    calculateKey(
        path: string,
        name: string,
        randomPreffix: boolean = true
    ): string {
        return `${this.dirName}/${path}/${
            randomPreffix ? `${new Date().getTime()}_` : ''
        }${name}`
    }

    upload(
        key: string,
        file: File | Blob,
        acl: 'private' | 'public-read' = 'private',
        contentType?: string
    ): Promise<any> {
        return this.client.putObject({
            Bucket: this.bucket,
            Key: key,
            Body: file,
            ACL: acl,
            ContentType: contentType,
            StorageClass: 'STANDARD',
        })
    }

    getUrl(key: string): string {
        if (key.startsWith(this.endpoint)) {
            return key
        }
        return `${this.endpoint}/${key}`
    }
}

export const loadCustomS3Client = (): CustomS3Client => {
    const bucket: string = process.env.REACT_APP_AWS_S3_BUCKET ?? ''
    const dirName: string = process.env.REACT_APP_AWS_S3_DIR ?? ''
    const endpoint: string = process.env.REACT_APP_AWS_S3_URL ?? ''

    return new CustomS3Client(loadS3Client(), bucket, dirName, endpoint)
}

export const loadS3Client = (): S3 => {
    const accessKey: string = process.env.REACT_APP_AWS_S3_ACCESS_KEY ?? ''
    const accessSecret: string = process.env.REACT_APP_AWS_S3_SECRET_KEY ?? ''
    const region: string = process.env.REACT_APP_AWS_S3_REGION ?? ''

    return loadS3ClientWithParams(accessKey, accessSecret, region)
}

export const loadS3ClientWithParams = (
    accessKey: string,
    accessSecret: string,
    region: string
): S3 => {
    return new S3({
        region: region,
        useArnRegion: true,
        credentials: {
            accessKeyId: accessKey,
            secretAccessKey: accessSecret,
        } as Credentials,
    })
}

export const useS3Client = (): CustomS3Client => {
    const s3Client = useMemo(loadCustomS3Client, [])
    return s3Client
}
