import { createContext } from 'react'
import { LayoutData } from './LayoutContext.types'

export const LayoutContext = createContext<LayoutData>({
    mobile: false,
    drawer: {
        open: false,
        width: 0,
    },
    user: {
        blocked: false,
    },
    openDrawer: (open: boolean, write: boolean) => {},
    toggleDrawer: () => {},
    closeDrawer: () => {},
    blockUser: () => {},
})
