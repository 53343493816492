import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
} from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { IconButton, Divider, Box, Button, Fab } from '@mui/material'
import { AddIcon, MoreVertIcon, ProjectsIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import { listProjects, deleteProject } from '../api/ProjectAPI'
import { Project, Projects } from '../model/Project'
import ProjectMenu from './ProjectMenu'
import ProjectsFilter, { useProjectsParams } from './ProjectsFilter'
import { FeedbackContext } from '../feedback/FeedbackContext'
import Pagination, {
    getOffset,
    initialPage,
    initialPageSize,
} from '../components/Pagination'
import { Griddable } from '../components/Griddable'
import { formatDate } from '../components/DateFormat.types'
import DialogPopup from '../components/DialogPopup'
import ProjectPreviewPopup from './ProjectPreviewPopup'
import ProjectDeployPopup from './ProjectDeployPopup'
import { AppContext } from '../context/AppContext'

type Popup = 'delete' | 'preview' | 'deploy' | 'qr'

export default function ProjectsList() {
    const history = useHistory()
    const context = useContext(AppContext)
    const feedbackContext = useContext(FeedbackContext)
    const isGrantedCreated = useMemo(
        () => context.isGrantedAny(['ProjectsCreate']),
        [context]
    )

    const query = new URLSearchParams(useLocation().search)
    const params = useProjectsParams(query)

    const [status, setStatus] = useState<string>('loading')
    const [data, setData] = useState<Projects>()

    const [project, setProject] = useState<Project>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [popup, setPopup] = useState<Popup>()

    const [page, setPage] = useState<number>(initialPage(query))
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query))

    const retrievePromise = useCallback(() => {
        const offset = getOffset(page, pageSize)
        return listProjects({ ...params, page: pageSize, offset })
    }, [page, pageSize, params])

    const load = () => {
        setStatus('loading')
        Promise.all([retrievePromise()])
            .then(([response]) => {
                setData(response)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }

    useEffect(load, [retrievePromise])

    const onChangedPage = (page: number) => {
        setPage(page)
        setData(undefined)
    }

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page)
        setPageSize(pageSize)
        setData(undefined)
    }

    const onClickedOptions =
        (project: Project) => (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setProject(project)
        }

    const onCloseOption = () => {
        setAnchorEl(null)
        setProject(undefined)
    }

    const onClosePopup = () => {
        onCloseOption()
        setPopup(undefined)
    }

    const onPopup = (popup: Popup) => {
        if (!project) return

        setAnchorEl(null)
        setPopup(popup)
    }

    const onDelete = () => {
        onPopup('delete')
    }

    const onPreview = () => {
        onPopup('preview')
    }

    const onQr = () => {
        onPopup('qr')
    }

    const onDeploy = () => {
        onPopup('deploy')
    }

    const onConfirmDelete = () => {
        onClosePopup()
        if (!project) return

        feedbackContext.showBackdrop()
        deleteProject(project.id)
            .then(() => {
                feedbackContext.showSuccess(
                    translate('projects.delete.success', {
                        id: project.id,
                    }) as string
                )
                load()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onClicked = (project: Project) => {
        history.push(`/projects/${project.id}/edit`)
    }

    return (
        <Pagination
            title={translate('projects.title')}
            icon={<ProjectsIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            action={
                isGrantedCreated ? (
                    <Link to="/projects/new">
                        <Fab
                            color="primary"
                            size="medium"
                            title={translate('buttons.add') as string}
                        >
                            <AddIcon />
                        </Fab>
                    </Link>
                ) : undefined
            }
        >
            <ProjectsFilter />
            <Box mx={-2} mt={2}>
                <Divider />
                <Griddable
                    items={data ? data.items : []}
                    loading={status === 'loading'}
                    error={
                        status !== 'loading' && status !== 'loaded'
                            ? status
                            : undefined
                    }
                    empty={translate('projects.empty') as string}
                    getId={(project: Project) => project.id}
                    clickable={{
                        onClick: onClicked,
                    }}
                    columns={[
                        {
                            title: translate('projects.id') as string,
                            converter: (project: Project) => project.id,
                            id: 'id',
                            xs: true,
                        },
                        {
                            title: translate('projects.name') as string,
                            converter: (project: Project) => project.name,
                            id: 'name',
                            xs: 2,
                            sm: 3,
                            md: 3,
                            lg: 4,
                            xl: 4,
                        },
                        {
                            title: translate('projects.description') as string,
                            converter: (project: Project) =>
                                project.description || '---',
                            id: 'description',
                            xs: false,
                            sm: 3,
                            md: 3,
                            lg: 3,
                            xl: 3,
                        },
                        {
                            title: translate('projects.created_at') as string,
                            converter: (project: Project) =>
                                formatDate({ date: project.created_at }),
                            id: 'created_at',
                            xs: false,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: (
                                <IconButton
                                    size="small"
                                    style={{ visibility: 'hidden' }}
                                    disabled
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            converter: (project: Project) => (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onClickedOptions(project)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            xs: 'auto',
                            id: 'actions',
                        },
                    ]}
                />
            </Box>
            {project && anchorEl && (
                <ProjectMenu
                    project={project}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onPreview={onPreview}
                    onQr={onQr}
                    onDeploy={onDeploy}
                    onDelete={onDelete}
                />
            )}
            {project && popup === 'delete' && (
                <DialogPopup
                    open
                    title={translate('projects.delete.title')}
                    onClose={onClosePopup}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="text"
                            color="error"
                        >
                            {translate('buttons.delete')}
                        </Button>
                    }
                >
                    {translate('projects.delete.text', { id: project.id })}
                </DialogPopup>
            )}
            {project && popup === 'preview' && (
                <ProjectPreviewPopup
                    stage="DEV"
                    project={project}
                    onClose={onClosePopup}
                />
            )}
            {project && popup === 'qr' && (
                <ProjectPreviewPopup
                    stage="PROD"
                    project={project}
                    onClose={onClosePopup}
                />
            )}
            {project && popup === 'deploy' && (
                <ProjectDeployPopup
                    project={project}
                    onClose={onClosePopup}
                    onDeployed={setProject}
                />
            )}
        </Pagination>
    )
}
