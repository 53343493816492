import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Grid, Button, Box } from '@mui/material'

import translate from '../i18n/Translator'
import ExternSurface from './ExternSurface'

interface GoHomeProps {
    title?: string
    message?: string
    showTitle?: boolean
    children?: React.ReactNode
}

export default function GoHome(props: GoHomeProps) {
    return (
        <ExternSurface
            title={props.title ?? (translate('errors.title') as string)}
            showTitle={props.showTitle}
            description={props.message}
        >
            {props.children && props.children}
            <Grid item xs={12}>
                <Box my={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        component={RouterLink}
                        to="/signin"
                    >
                        {translate('buttons.back_to_home')}
                    </Button>
                </Box>
            </Grid>
        </ExternSurface>
    )
}
