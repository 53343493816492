import React from 'react'
import {
    Grid,
    Box,
    Typography,
    Backdrop,
    CircularProgress,
} from '@mui/material'
import { styled } from '@mui/material/styles'

const StyleBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.modal + 1,
    color: 'white',
}))

interface CustomBackdropProps {
    open: boolean
    message?: string
    children?: React.ReactNode
}

export default function CustomBackdrop(props: CustomBackdropProps) {
    return (
        <StyleBackdrop open={props.open}>
            <Grid item xs={12}>
                {props.children && props.children}
                {!props.children && (
                    <Grid container justifyContent="center">
                        <Grid item xs="auto">
                            <Box pb={2}>
                                <CircularProgress color="inherit" />
                            </Box>
                        </Grid>
                    </Grid>
                )}
                {props.message && (
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                component="h6"
                                align="center"
                            >
                                {props.message}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </StyleBackdrop>
    )
}
