import React from 'react'
import { Typography } from '@mui/material'

interface EllipsisProps {
    text: string
    lenght: number
    uppercased?: boolean
    secondary?: boolean
}

export default function Ellipsis(props: EllipsisProps) {
    var result = props.text
    if (props.text.length > props.lenght && props.lenght > 10) {
        result = `${result.substring(
            0,
            Math.min(result.length, props.lenght) - 3
        )}...`
    }

    return (
        <Typography
            title={props.text}
            variant={props.secondary ? 'caption' : 'body2'}
            component="span"
            color={props.secondary ? 'textSecondary' : 'inherit'}
        >
            {props.uppercased === undefined || props.uppercased
                ? result.toUpperCase()
                : result}
        </Typography>
    )
}
