import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
} from '@mui/material'
import translate from '../i18n/Translator'
import { DialogPopupProps } from './DialogPopup.types'

export default function DialogPopup(props: DialogPopupProps) {
    const onCloseClick = (
        event: {},
        reason: 'backdropClick' | 'escapeKeyDown'
    ) => {
        if (
            props.disableBackdropClick !== false &&
            reason === 'backdropClick'
        ) {
            return
        }
        if (props.disableEscapeKeyDown && reason === 'escapeKeyDown') {
            return
        }

        props.onClose()
    }

    return (
        <Dialog
            open={props.open}
            maxWidth={props.maxWidth || 'sm'}
            fullScreen={props.fullScreen}
            fullWidth={props.fullWidth}
            scroll="paper"
            onClose={onCloseClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                {props.children}
            </DialogContent>
            <DialogActions sx={{ paddingX: 3 }}>
                <Grid
                    container
                    justifyContent="flex-end"
                    alignContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs="auto">
                        <Button
                            onClick={props.onClose}
                            color="inherit"
                            disabled={props.disable}
                        >
                            {props.closeText ??
                                (translate('buttons.close') as string)}
                        </Button>
                    </Grid>
                    {props.button && (
                        <Grid item xs="auto">
                            {props.button}
                        </Grid>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
