import React from 'react'
import { Typography } from '@mui/material'
import translate from '../i18n/Translator'
import DialogPopup from '../components/DialogPopup'
import { User } from '../model/User'
import Clipboard from '../components/Clipboard'

interface UserActivatePopupProps {
    user: User
    onClose(): any
}

export default function UserActivatePopup(props: UserActivatePopupProps) {
    return (
        <DialogPopup
            title={translate('users.activation.title')}
            open
            onClose={props.onClose}
            fullWidth
            maxWidth="sm"
        >
            <Typography variant="body2" paragraph>
                {translate('users.activation.content', {
                    first_name: props.user.first_name,
                    email: props.user.email,
                })}
            </Typography>
            <Clipboard
                value={`${process.env.REACT_APP_ROOT_URL}/signup/${
                    props.user.signup_code ?? 'invalid_code'
                }/password`}
            />
        </DialogPopup>
    )
}
