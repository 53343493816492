import React, { memo } from 'react'
import { GuestImportRequest } from '../model/Guest'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import { Button, Grid, Typography } from '@mui/material'
import { UploaderInput } from '../components/UploaderInput'
import { isValid, useValidatedRequest } from '../components/ValidatedInput'
import { useS3Client } from '../components/CustomS3Client'

interface GuestImportPopupProps {
    tenantId: string
    onUpload(request: GuestImportRequest): any
    onClose(): any
}

function GuestImportPopup({
    tenantId,
    onUpload,
    onClose,
}: GuestImportPopupProps) {
    const [request, , validations, hasChanged] =
        useValidatedRequest<GuestImportRequest>()
    const s3Client = useS3Client()

    const onSubmit = () => {
        if (!isValid(validations)) return

        onUpload(request)
    }

    return (
        <DialogPopup
            open
            title={translate('guests.import.title')}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            button={
                <Button onClick={onSubmit} color="primary">
                    {translate('buttons.import')}
                </Button>
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {translate('guests.import.text')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput
                        id="path"
                        name="path"
                        label={translate('guests.import.input') as string}
                        value={request?.path}
                        s3Client={s3Client}
                        acceptExtension="csv"
                        acl="private"
                        contentType="text/csv"
                        onValueChanged={hasChanged}
                        required
                        path={`tenants/${tenantId}/guests`}
                    />
                </Grid>
            </Grid>
        </DialogPopup>
    )
}

export default memo(GuestImportPopup)
