import { callAPI, HTTPMethod } from './API'
import {
    Guests,
    Guest,
    GuestsListParams,
    GuestRequest,
    GuestImportRequest,
    GuestImportResponse,
} from '../model/Guest'

const basePath = (tenantId: string) => `/tenants/${tenantId}/guests`

export async function listGuests(
    tenantId: string,
    params: GuestsListParams
): Promise<Guests> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            page: params?.page?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            search: params.search ?? '',
        },
    })
}

export async function createGuest(
    tenantId: string,
    request: GuestRequest
): Promise<Guest> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function importGuests(
    tenantId: string,
    request: GuestImportRequest
): Promise<GuestImportResponse> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function getGuest(tenantId: string, id: string): Promise<Guest> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET,
    })
}

export async function updateGuest(
    tenantId: string,
    id: string,
    request: GuestRequest
): Promise<Guest> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function deleteGuest(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE,
    })
}
