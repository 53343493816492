import { ApiError } from '../model/ApiError'
import { RefreshToken } from '../model/Auth'
import translate from '../i18n/Translator'

const ApiUrl = process.env.REACT_APP_API_URL
const AuthHeaderName =
    process.env.REACT_APP_API_AUTH_HEADER_NAME ?? '---HEADER--NAME---'

export enum HTTPMethod {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PUT = 'PUT',
    PATCH = 'PATCH',
}

interface APIQueryString {
    query?: Record<string, string>
}

interface APIParameters extends APIQueryString {
    method: HTTPMethod
    body?: object | FormData
    multipart?: boolean
}

export async function callAPI<T>(
    path: string,
    parameters: APIParameters,
    withoutToken?: boolean
): Promise<T> {
    if (!path.startsWith('/')) {
        path = '/' + path
    }

    let headers = new Headers()
    headers.append('Accept', 'application/json')

    if (!withoutToken) {
        let storedJwt = await getValidJwt()
        if (storedJwt) {
            headers.append('Authorization', `Bearer ${storedJwt}`)
        }
    }

    if (!parameters.multipart) {
        headers.append('Content-Type', 'application/json')
    }

    let requestOptions: RequestInit = {
        method: parameters.method,
        headers: headers,
        credentials: 'include',
        mode: 'cors',
    }

    if (parameters.body) {
        if (parameters.multipart && (parameters.body as FormData)) {
            requestOptions.body = parameters.body as FormData
        } else {
            requestOptions.body = JSON.stringify(parameters.body)
        }
    }

    if (parameters.query) {
        path += '?' + encodeGetParams(parameters.query)
    }

    const response = await fetch(ApiUrl + path, requestOptions)
    let jwt = response.headers.get(AuthHeaderName)
    if (jwt) {
        writeJWT(jwt)
    }

    if (response.status === 204) {
        return await noContent()
    }

    if (response.ok) {
        return await response.json()
    }

    const errorResponse = await response.json()
    if (errorResponse) {
        throw new Error(getErrorMesssage(errorResponse))
    }
    throw new Error(await response.text())
}

export async function getAuthenticatedGetUrl(
    path: string,
    qs?: APIQueryString
) {
    let jwt = (await getValidJwt()) || ''
    if (!path.startsWith('/')) {
        path = '/' + path
    }

    var parameters = qs?.query
    if (!parameters) {
        parameters = {} as Record<string, string>
    }

    parameters['auth'] = jwt

    return `${ApiUrl}${path}?${encodeGetParams(parameters)}`
}

async function noContent<T>(): Promise<T> {
    return {} as T
}

export async function emptyPromise(): Promise<undefined> {
    return undefined
}

function getErrorMesssage(apiError: ApiError): string {
    return translate(`errors.codes.${apiError.code}`) as string
}

export function hasJwt() {
    return getJWT() !== null
}

async function getValidJwt(): Promise<string | null> {
    let jwt = getJWT()
    if (!jwt) {
        return null
    }

    try {
        let content = JSON.parse(atob(jwt.split('.')[1]))
        let expiration =
            (typeof content.exp === 'number' ? (content.exp as number) : 0) *
            1000
        if (expiration > Date.now()) {
            return jwt
        }
    } catch (error) {
        console.log('Can"t parse JWT', error)
    }

    try {
        let response = await refreshJwt(jwt)
        writeJWT(response.jwt)
        return response.jwt
    } catch (error) {
        console.log('Unable to refresh JWT token', error)
        removeJWT()
        return null
    }
}

async function refreshJwt(jwt: string): Promise<RefreshToken> {
    let request = {
        jwt: jwt,
    } as RefreshToken

    return await callAPI(
        '/auth/tokens/refresh',
        {
            method: HTTPMethod.PUT,
            body: request,
        },
        true
    )
}

function getJWT(): string | null {
    return localStorage.getItem(AuthHeaderName)
}

function writeJWT(jwt: string) {
    localStorage.setItem(AuthHeaderName, jwt)
}

export function removeJWT() {
    localStorage.removeItem(AuthHeaderName)
}

function encodeGetParams(p: object): string {
    return Object.entries(p)
        .map((kv) => kv.map(encodeURIComponent).join('='))
        .join('&')
}
