import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Grid,
    Button,
    Typography,
    Box,
    useMediaQuery,
    Theme,
} from '@mui/material'
import { UserIcon, NewUserIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import Surface from '../components/Surface'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { User, UserRequest, MetadataResponse } from '../model/User'
import { Tenant } from '../model/Tenant'
import {
    createUser as createTenantUser,
    getUser as getTenantUser,
    updateUser as updateTenantUser,
    getMetadata as getTenantMetadata,
} from '../api/TenantUserAPI'
import { createUser, getUser, updateUser, getMetadata } from '../api/UserAPI'
import Progress from '../components/Progress'
import { AppContext } from '../context/AppContext'
import { RouterParams } from '../router/RouterParams'
import { PhoneValidator, phoneFormat } from '../components/Validators'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function UserForm({ match }: RouterParams) {
    const history = useHistory()
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const phoneValidator = new PhoneValidator()
    const feedbackContext = useContext(FeedbackContext)
    const context = useContext(AppContext)
    const sessionUserId = context.session?.user.id
    const userId = match.params.userId
    const tenantId = context.session?.tenant?.id ?? match.params.tenantId
    const [status, setStatus] = useState<string>('loading')
    const [request, setRequest, validations, hasChanged] =
        useValidatedRequest<UserRequest>()

    const [roleIds, setRoleIds] = useState<string[]>([])
    const [roleNames, setRoleNames] = useState<string[]>([])
    const [tenant, setTenant] = useState<Tenant>()

    const [format, setFormat] = useState('')

    const retrievePromise = useCallback((): Promise<User> => {
        if (tenantId) {
            return getTenantUser(tenantId, userId, true)
        }
        return getUser(userId, true)
    }, [tenantId, userId])

    const metadataPromise = useCallback((): Promise<MetadataResponse> => {
        if (tenantId) {
            return getTenantMetadata(tenantId)
        }
        return getMetadata()
    }, [tenantId])

    const submitPromise = (): Promise<User> => {
        if (userId) {
            if (tenantId) {
                return updateTenantUser(tenantId, userId, request)
            }
            return updateUser(userId, request)
        }

        if (tenantId) {
            return createTenantUser(tenantId, request)
        }
        return createUser(request)
    }

    const setMetadata = useCallback((metadata?: MetadataResponse) => {
        if (!metadata) return

        const filtered = metadata.roles
        setRoleIds(filtered.map((role) => role.id))
        setRoleNames(
            filtered.map(
                (role) => translate(`users.roles.${role.id}`) as string
            )
        )
        setTenant(metadata.tenant)
    }, [])

    useEffect(() => {
        if (sessionUserId === userId) {
            history.push('/profile')
            return
        }

        setStatus('loading')
        if (userId) {
            retrievePromise()
                .then((user) => {
                    const defaultRoleId =
                        user.metadata?.roles.length === 1
                            ? user.metadata?.roles[0]?.id
                            : undefined
                    setMetadata(user.metadata)
                    setRequest({
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email: user.email,
                        role_id: user.role?.id ?? defaultRoleId,
                        phone: user.phone,
                    } as UserRequest)

                    setStatus('loaded')
                })
                .catch((error) => {
                    setStatus(error.message)
                })
        } else {
            metadataPromise()
                .then((metadata) => {
                    const defaultRoleId =
                        metadata.roles.length === 1
                            ? metadata.roles[0]?.id
                            : undefined
                    setMetadata(metadata)
                    setRequest({
                        first_name: '',
                        last_name: '',
                        email: '',
                        role_id: defaultRoleId,
                        phone: '',
                    } as UserRequest)
                    setStatus('loaded')
                })
                .catch((error) => {
                    setStatus(error.message)
                })
        }
    }, [
        sessionUserId,
        userId,
        history,
        setRequest,
        retrievePromise,
        metadataPromise,
        setMetadata,
    ])

    useEffect(() => {
        setFormat(phoneFormat(request.phone))
    }, [request.phone])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) return

        feedbackContext.showBackdrop()
        submitPromise()
            .then((response) => {
                if (match.params.tenantId) {
                    history.push(`/accounts/${match.params.tenantId}/users`)
                } else {
                    history.push('/users')
                }
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return (
            <Typography
                variant="body1"
                component="h5"
                color="error"
                align="center"
            >
                {status}
            </Typography>
        )
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface
                        title={translate(
                            userId ? 'users.edit_user' : 'users.new_user'
                        )}
                        subtitle={tenant ? tenant.name : undefined}
                        icon={userId ? <UserIcon /> : <NewUserIcon />}
                    >
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="first_name"
                                        name="first_name"
                                        value={request.first_name}
                                        label={
                                            translate(
                                                'users.first_name'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="last_name"
                                        name="last_name"
                                        value={request.last_name}
                                        label={
                                            translate(
                                                'users.last_name'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={request.email}
                                        label={
                                            translate('users.email') as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={request.phone}
                                        label={
                                            translate('users.phone') as string
                                        }
                                        validator={phoneValidator}
                                        format={format}
                                        mask=" "
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="role_id"
                                        name="role_id"
                                        label={
                                            translate(
                                                'users.roles.title'
                                            ) as string
                                        }
                                        value={request.role_id}
                                        onValueChanged={hasChanged}
                                        required
                                        options={roleIds}
                                        optionLabels={roleNames}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            spacing={1}
                                            direction="row-reverse"
                                        >
                                            <Grid item xs={12} sm="auto">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    fullWidth={isXs}
                                                >
                                                    {translate(
                                                        userId
                                                            ? 'buttons.update'
                                                            : 'buttons.add'
                                                    )}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm="auto">
                                                <Button
                                                    variant="text"
                                                    color="inherit"
                                                    size="large"
                                                    onClick={history.goBack}
                                                    fullWidth={isXs}
                                                >
                                                    {translate(
                                                        'buttons.cancel'
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    )
}
