import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from '@mui/material'
import { EditIcon, DeleteIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import { Design } from '../model/Design'
import { AppContext } from '../context/AppContext'
import { VisibilityIcon } from '../components/Icons'

interface DesignMenuProps {
    mode?: 'grid' | 'form'
    tenantId?: string
    design: Design
    anchor: HTMLElement | null
    onClose(): any
    onDelete(): any
    onPreview(): any
}

export default function DesignMenu({
    mode = 'grid',
    tenantId,
    design,
    anchor,
    onClose,
    onDelete,
    onPreview,
}: DesignMenuProps) {
    const context = useContext(AppContext)
    const [isUpdateGranted] = useState(
        context.isGrantedAny(['PDesignsUpdate', 'TDesignsUpdate'])
    )
    const [isDeleteGranted] = useState(
        context.isGrantedAny(['PDesignsDelete', 'TDesignsDelete'])
    )

    return (
        <Menu
            id="designs-item"
            anchorEl={anchor}
            keepMounted
            onClose={onClose}
            open
            TransitionComponent={Fade}
        >
            {mode === 'grid' && isUpdateGranted && (
                <MenuItem
                    component={Link}
                    to={`${tenantId ? `/accounts/${tenantId}` : ''}/designs/${
                        design.id
                    }/edit`}
                    onClick={onClose}
                    dense
                >
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.edit')}
                    </Typography>
                </MenuItem>
            )}
            <MenuItem onClick={onPreview} dense>
                <ListItemIcon>
                    <VisibilityIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {translate('buttons.preview')}
                </Typography>
            </MenuItem>
            {mode === 'grid' && isDeleteGranted && (
                <MenuItem onClick={onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.delete')}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    )
}
