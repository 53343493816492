import { callAPI, HTTPMethod } from './API'
import {
    Postcards,
    Postcard,
    PostcardsListParams,
    PostcardRequest,
} from '../model/Postcard'

const basePath = (tenantId: string) => `/tenants/${tenantId}/postcards`

export async function listPostcards(
    tenantId: string,
    params: PostcardsListParams
): Promise<Postcards> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            page: params?.page?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            search: params.search ?? '',
            guestId: params.guestId ?? '',
        },
    })
}

export async function createPostcard(
    tenantId: string,
    request: PostcardRequest
): Promise<Postcards> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function getPostcard(
    tenantId: string,
    id: string
): Promise<Postcard> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET,
    })
}

export async function updatePostcard(
    tenantId: string,
    id: string,
    request: PostcardRequest
): Promise<Postcard> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function deletePostcard(
    tenantId: string,
    id: string
): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE,
    })
}
