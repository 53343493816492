import React, { useState, useEffect, useContext } from 'react'
import { Grid, Button, Link, Box } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { getSignin2FA, completeSignin2FA } from '../api/AuthAPI'
import { User, DoubleFactorAuthenticationRequest } from '../model/User'
import translate from '../i18n/Translator'
import { RouterParams } from '../router/RouterParams'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { AppContext } from '../context/AppContext'
import { Code2FAValidator } from '../components/Validators'
import Progress from '../components/Progress'
import GoHome from '../components/GoHome'
import ExternSurface from '../components/ExternSurface'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function SignIn2FAForm({ match }: RouterParams) {
    const context = useContext(AppContext)
    const feedbackContext = useContext(FeedbackContext)
    const code2FAValidator = new Code2FAValidator()

    const [status, setStatus] = useState<string>('loading')
    const [user, setUser] = useState<User>()
    const [request, , validations, hasChanged] =
        useValidatedRequest<DoubleFactorAuthenticationRequest>()

    useEffect(() => {
        setStatus('loading')
        getSignin2FA(match.params.code)
            .then((response) => {
                setUser(response.user)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [match.params.code])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        completeSignin2FA(match.params.code, request)
            .then((_) => {
                context.onSignedIn()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return <GoHome message={status} />
    }

    return (
        <ExternSurface
            title={
                translate('auth.2fa.title', {
                    name: user!.first_name,
                }) as string
            }
            description={translate('auth.2fa.text') as string}
        >
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="code"
                            name="code"
                            label={translate('users.2fa_code') as string}
                            value={request?.code}
                            maxLength={6}
                            onValueChanged={hasChanged}
                            validator={code2FAValidator}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                            >
                                {translate('buttons.signin')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs="auto">
                        <Link component={RouterLink} to="/recover-password">
                            <small>{translate('buttons.recover')}</small>
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </ExternSurface>
    )
}
