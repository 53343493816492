import React, { memo, useContext, useEffect, useState } from 'react'
import { Guest } from '../model/Guest'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import { listDesigns } from '../api/DesignAPI'
import { Design, MAX_LENGHT_DESCRIPTION } from '../model/Design'
import {
    Button,
    Card,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material'
import ValidatedInput, {
    useValidatedRequest,
    isValid,
} from '../components/ValidatedInput'
import { InputRef } from '../components/Input.types'
import Flipper from '../components/Flipper'
import { ExpandLessIcon, ExpandMoreIcon } from '../components/Icons'
import Progress from '../components/Progress'
import DesignPreviewGrid from '../designs/DesignPreviewGrid'
import { PostcardRequest } from '../model/Postcard'
import { PostcardCreatePopupProps } from './PostcardCreatePopup.types'
import { createPostcard } from '../api/PostcardAPI'
import { FeedbackContext } from '../feedback/FeedbackContext'

function PostcardCreatePopup({
    tenantId,
    guests,
    onClose,
}: PostcardCreatePopupProps) {
    const feedbackContext = useContext(FeedbackContext)
    const [status, setStatus] = useState('loading')
    const [designs, setDesigns] = useState<Design[]>([])
    const [design, setDesign] = useState<Design>()
    const [designIds, setDesignIds] = useState<string[]>([])
    const [designLabels, setDesignLabels] = useState<string[]>([])
    const [request, setRequest, validations, hasChanged] =
        useValidatedRequest<PostcardRequest>()
    const [open, setOpen] = useState(false)
    const [selectedGuest, setSelectedGuest] = useState<Guest | undefined>(
        guests.length === 1 ? guests[0] : undefined
    )
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        setStatus('loading')
        listDesigns(tenantId, { page: 0, offset: 0 })
            .then((designs) => {
                setDesigns(designs.items)
                setDesignIds(designs.items.map((el) => el.id))
                setDesignLabels(designs.items.map((el) => el.name))
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [tenantId])

    const submit = () => {
        if (!isValid(validations)) return

        feedbackContext.showBackdrop()
        const guestIds = guests.map((el) => el.id)
        const fixedRequest = {
            ...request,
            guest_ids: guestIds,
            message:
                design?.message === request.message
                    ? undefined
                    : request.message,
        } as PostcardRequest

        createPostcard(tenantId, fixedRequest)
            .then((response) => {
                feedbackContext.showSuccess(
                    translate('postcards.created', {
                        items: response.items.length,
                    }) as string
                )
                onClose()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const hasChangedDesign = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        const newDesign = designs.find((el) => el.id === value)
        setDesign(newDesign)
        setOpen(false)

        if (newDesign?.message) {
            setRequest({ ...request, message: newDesign.message })
        }

        hasChanged(name, value, inputRef)
    }

    const hasChangedGuest = (
        event: React.ChangeEvent<HTMLInputElement>,
        value: string
    ) => {
        const newGuest = guests.find((el) => el.id === value)
        if (newGuest) {
            setSelectedGuest(newGuest)
        }
    }

    const hasChangedDescription = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        setCounter(MAX_LENGHT_DESCRIPTION - value.length)
        hasChanged(name, value, inputRef)
    }

    return (
        <DialogPopup
            open
            title={translate('postcards.sender.title')}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            button={
                <Button
                    color="primary"
                    onClick={submit}
                    disabled={status !== 'loaded'}
                >
                    {translate('buttons.send_postcard')}
                </Button>
            }
        >
            {status === 'loading' && <Progress />}
            {status === 'loaded' && (
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md>
                        <ValidatedInput
                            id="design_id"
                            type="text"
                            name="design_id"
                            label={translate('designs.single') as string}
                            value={request.design_id}
                            options={designIds}
                            optionLabels={designLabels}
                            onValueChanged={hasChangedDesign}
                        />
                        <ValidatedInput
                            id="message"
                            type="text"
                            name="message"
                            label={translate('designs.message') as string}
                            value={request.message}
                            multiline
                            minRows={4}
                            maxRows={4}
                            maxLength={400}
                            disabled={open}
                            onValueChanged={hasChangedDescription}
                        />
                        <Typography component="h6" variant="caption" color={counter < 10 ? "textSecondary" : "textPrimary"} textAlign="right">
                            {translate("counter", { counter })}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Card variant="outlined">
                            <Flipper
                                front={design?.front_image}
                                back={design?.back_image}
                            />
                        </Card>
                    </Grid>
                    {design && (
                        <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        size="small"
                                        variant="text"
                                        color="secondary"
                                        startIcon={
                                            open ? (
                                                <ExpandLessIcon />
                                            ) : (
                                                <ExpandMoreIcon />
                                            )
                                        }
                                        onClick={() => setOpen(!open)}
                                    >
                                        {translate('designs.preview', design)}
                                    </Button>
                                </Grid>
                                {open && (
                                    <>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ pt: 0.5, pb: 2 }}
                                        >
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl
                                                margin="none"
                                                fullWidth
                                            >
                                                <FormLabel id="guests-list">
                                                    {translate('guests.title')}
                                                </FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="guests-list"
                                                    value={
                                                        selectedGuest?.id ?? ''
                                                    }
                                                    name="guestId"
                                                    onChange={hasChangedGuest}
                                                >
                                                    {guests.map((guest) => (
                                                        <FormControlLabel
                                                            key={guest.id}
                                                            value={guest.id}
                                                            control={
                                                                <Radio size="small" />
                                                            }
                                                            label={guest.name}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        {selectedGuest && (
                                            <Grid item xs={12} md={6}>
                                                <Card variant="outlined">
                                                    <DesignPreviewGrid
                                                        tenantId={tenantId}
                                                        design={design}
                                                        guest={selectedGuest}
                                                        message={
                                                            design.message ===
                                                                request.message
                                                                ? undefined
                                                                : request.message
                                                        }
                                                    />
                                                </Card>
                                            </Grid>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
            {status !== 'loading' && status !== 'loaded' && (
                <Typography
                    variant="body1"
                    component="h5"
                    color="error"
                    align="center"
                >
                    {status}
                </Typography>
            )}
        </DialogPopup>
    )
}

export default memo(PostcardCreatePopup)
