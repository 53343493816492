import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import translate from '../i18n/Translator'

import { User } from '../model/User'
import { deleteUser } from '../api/UserAPI'
import { deleteUser as deleteTenantUser } from '../api/TenantUserAPI'
import DialogPopup from '../components/DialogPopup'

interface UserDeletePopupProps {
    user: User
    tenantId?: string
    onCompleted(): any
    onError(error: string): any
    onCancelled(): any
}

export default function UserDeletePopup(props: UserDeletePopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false)
    const deletePromise = (user: User): Promise<void> => {
        if (props.tenantId) {
            return deleteTenantUser(props.tenantId, user.id)
        }
        return deleteUser(user.id)
    }

    const onConfirm = () => {
        setSubmitting(true)
        deletePromise(props.user)
            .then(() => {
                setSubmitting(false)
                props.onCompleted()
            })
            .catch((error) => {
                setSubmitting(false)
                props.onError(error.message)
            })
    }

    return (
        <DialogPopup
            open
            title={translate('users.delete.title') as string}
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate('buttons.cancel') as string}
            onClose={props.onCancelled}
            fullWidth
            maxWidth="sm"
            button={
                <Button onClick={onConfirm} color="error" disabled={submitting}>
                    {translate('buttons.delete')}
                </Button>
            }
        >
            <Typography variant="body2">
                {translate('users.delete.description', {
                    first_name: props.user.first_name,
                })}
            </Typography>
        </DialogPopup>
    )
}
