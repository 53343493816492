import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
    Grid,
    Button,
    Typography,
    Box,
    styled,
    Card,
    IconButton,
} from '@mui/material'
import { DesignsIcon, MoreVertIcon } from '../components/Icons'

import { createDesign, getDesign, updateDesign } from '../api/DesignAPI'
import translate from '../i18n/Translator'
import { DESIGN_TYPES, Design, DesignRequest, MAX_LENGHT_DESCRIPTION } from '../model/Design'

import Progress from '../components/Progress'
import Surface from '../components/Surface'
import ValidatedInput, { isValid } from '../components/ValidatedInput'
import { InputRef } from '../components/Input.types'
import { AppContext } from '../context/AppContext'
import { RouterParams } from '../router/RouterParams'
import { FeedbackContext } from '../feedback/FeedbackContext'
import { UploaderInput } from '../components/UploaderInput'
import { useS3Client } from '../components/CustomS3Client'
import { emptyPromise } from '../api/API'
import DesignMenu from './DesignMenu'
import DesignPreview from './DesignPreviewPopup'
import { Christian } from '../model/Guest'

const placeholder = '/image-target-placeholder.jpg'

const Image = styled('img')(({ theme }) => ({
    width: '100%',
    float: 'left',
}))

export default function DesignForm({ match }: RouterParams) {
    const history = useHistory()
    const feedbackContext = useContext(FeedbackContext)
    const context = useContext(AppContext)
    const paramTenantId = match.params.tenantId
    const tenantId = context.session?.tenant?.id || paramTenantId || '-'
    const designId = match.params.designId
    const firstName = context.session?.user?.first_name
    const s3Client = useS3Client()
    const typeLabels = useMemo(
        () =>
            DESIGN_TYPES.map((el) => translate(`designs.type.${el}`) as string),
        []
    )

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [status, setStatus] = useState<string>('loading')
    const [design, setDesign] = useState<Design>()
    const [request, setRequest] = useState<DesignRequest>({} as DesignRequest)
    const [validations, setValidations] = useState({} as any)
    const [preview, setPreview] = useState(false)
    const [counter, setCounter] = useState(0)

    const retreivePromise = useCallback(() => {
        if (designId) {
            return getDesign(tenantId, designId)
        }
        return emptyPromise()
    }, [tenantId, designId])

    const submitPromise = (): Promise<Design> => {
        if (designId) {
            return updateDesign(tenantId, designId, request)
        }
        return createDesign(tenantId, request)
    }

    useEffect(() => {
        setStatus('loading')
        retreivePromise()
            .then((design) => {
                setDesign(design)
                setRequest({
                    name: design?.name ?? '',
                    message: design?.message ?? '',
                    type: design?.type ?? '',
                    front_image: design?.front_image ?? '',
                    back_image: design?.back_image ?? '',
                } as DesignRequest)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [retreivePromise])


    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value })
        validations[name] = inputRef
        setValidations(validations)

        if (name === "message") {
            setCounter(MAX_LENGHT_DESCRIPTION - value.length)
        }
    }

    const hasChangedImage = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        setRequest({ ...request, [name]: value ? s3Client.getUrl(value) : '' })
        validations[name] = inputRef
        setValidations(validations)
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        submitPromise()
            .then((response) => {
                if (design) {
                    feedbackContext.showSuccess(
                        translate('designs.edited', response) as string
                    )
                } else {
                    feedbackContext.showSuccess(
                        translate('designs.created', response) as string
                    )
                }

                setDesign(response)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const showMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const onPreview = () => {
        closeMenu()
        setPreview(true)
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return (
            <Typography
                variant="body1"
                component="h5"
                color="error"
                align="center"
            >
                {status}
            </Typography>
        )
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Surface
                        title={translate(
                            designId ? 'designs.edit' : 'designs.new'
                        )}
                        icon={<DesignsIcon />}
                        titleActions={
                            design ? (
                                <IconButton
                                    color="default"
                                    size="small"
                                    onClick={showMenu}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ) : undefined
                        }
                    >
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={request.name}
                                    label={translate('designs.name') as string}
                                    required
                                    onValueChanged={hasChanged}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    type="text"
                                    id="message"
                                    name="message"
                                    value={
                                        request.message ? request.message : ''
                                    }
                                    label={
                                        translate(
                                            'designs.default_message'
                                        ) as string
                                    }
                                    multiline
                                    minRows={4}
                                    maxRows={10}
                                    maxLength={MAX_LENGHT_DESCRIPTION}
                                    onValueChanged={hasChanged}
                                />
                                <Typography component="h6" variant="caption" color={counter < 10 ? "textSecondary" : "textPrimary"} textAlign="right">
                                    {translate("counter", { counter })}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    type="text"
                                    id="type"
                                    name="type"
                                    value={request.type ? request.type : ''}
                                    label={
                                        translate(
                                            'designs.type.title'
                                        ) as string
                                    }
                                    options={DESIGN_TYPES}
                                    optionLabels={typeLabels}
                                    required
                                    disabled={!!designId}
                                    onValueChanged={hasChanged}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Typography variant="subtitle1">
                                    {translate('designs.front_image') as string}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={8}>
                                        <UploaderInput
                                            id="front_image"
                                            name="front_image"
                                            value={request.front_image}
                                            label={
                                                translate(
                                                    'designs.front_image'
                                                ) as string
                                            }
                                            required
                                            onValueChanged={hasChangedImage}
                                            s3Client={s3Client}
                                            acceptExtension={'.jpg,.png'}
                                            path={'designs'}
                                            acl="public-read"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Card variant="outlined">
                                            <Image
                                                src={
                                                    request?.front_image ||
                                                    placeholder
                                                }
                                                alt="target"
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Typography variant="subtitle1">
                                    {translate('designs.back_image') as string}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={8}>
                                        <UploaderInput
                                            id="back_image"
                                            name="back_image"
                                            value={request.back_image}
                                            label={
                                                translate(
                                                    'designs.back_image'
                                                ) as string
                                            }
                                            required
                                            onValueChanged={hasChangedImage}
                                            s3Client={s3Client}
                                            acceptExtension={'.jpg,.png'}
                                            path={'designs'}
                                            acl="public-read"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Card variant="outlined">
                                            <Image
                                                src={
                                                    request?.back_image ||
                                                    placeholder
                                                }
                                                alt="target"
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pt={2}>
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        spacing={1}
                                        direction="row-reverse"
                                    >
                                        <Grid item xs={12} md="auto">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                            >
                                                {translate(
                                                    designId
                                                        ? 'buttons.update'
                                                        : 'buttons.add'
                                                )}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md="auto">
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="large"
                                                onClick={history.goBack}
                                            >
                                                {translate('buttons.cancel')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </form>
                    </Surface>
                </Grid>
            </Grid>
            {design && anchorEl && (
                <DesignMenu
                    mode="form"
                    tenantId={paramTenantId}
                    design={design}
                    anchor={anchorEl}
                    onDelete={() => { }}
                    onPreview={onPreview}
                    onClose={closeMenu}
                />
            )}
            {design && preview && (
                <DesignPreview
                    tenantId={tenantId}
                    design={design}
                    guest={
                        firstName
                            ? { ...Christian, name: firstName }
                            : Christian
                    }
                    onClose={() => setPreview(false)}
                />
            )}
        </Grid>
    )
}
