import React from 'react'
import { SvgIcon, styled } from '@mui/material'

const GuestsStyleOne = styled('path')(({ theme }) => ({
    fill: '#fff',
}))
const GuestsRectOne = styled('rect')(({ theme }) => ({
    fill: '#fff',
}))
const GuestsStyleTwo = styled('path')(({ theme }) => ({
    fill: '#c4c8e3',
}))
const GuestsStyleThree = styled('path')(({ theme }) => ({
    fill: 'currentColor',
}))
const GuestsRectThree = styled('rect')(({ theme }) => ({
    fill: 'currentColor',
}))
const GuestsStyleFour = styled('polyline')(({ theme }) => ({
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '8.25px',
}))

export const GuestsIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 224 224">
            <g>
                <GuestsStyleThree d="M18.44,2.15c-4.29,0-8.49,1.74-11.52,4.77-3.04,3.03-4.77,7.22-4.77,11.51V205.56c0,4.29,1.74,8.48,4.77,11.51,3.03,3.03,7.23,4.77,11.52,4.77H205.56c4.29,0,8.49-1.74,11.52-4.77,3.03-3.03,4.77-7.23,4.77-11.51V18.44c0-4.29-1.74-8.48-4.77-11.51-3.03-3.03-7.23-4.77-11.52-4.77H18.44ZM61.53,24.18c6.09,0,11.04,4.94,11.04,11.04s-4.95,11.04-11.04,11.04-11.04-4.94-11.04-11.04,4.94-11.04,11.04-11.04Zm-33.7,0c6.1,0,11.04,4.94,11.04,11.04s-4.94,11.04-11.04,11.04-11.04-4.94-11.04-11.04,4.94-11.04,11.04-11.04ZM207.36,199.66H16.79V61.95H207.36V199.66Z" />
                <GuestsRectOne
                    x="35.09"
                    y="146.64"
                    width="30.79"
                    height="29.2"
                />
                <GuestsRectOne
                    x="35.06"
                    y="85.74"
                    width="30.86"
                    height="28.62"
                />
                <GuestsStyleOne d="M16.79,199.66H207.36V61.95H16.79V199.66ZM87.09,82h106.33v12.34H87.09v-12.34Zm0,18.97h106.33v12.34H87.09v-12.34Zm0,44.62h106.33v12.35H87.09v-12.35Zm0,18.97h106.33v12.34H87.09v-12.34ZM27.57,82c0-.99,.4-1.95,1.09-2.65,.7-.7,1.66-1.1,2.65-1.1h38.35c.99,0,1.95,.4,2.65,1.1,.69,.7,1.09,1.66,1.09,2.65v36.1c0,.99-.41,1.95-1.09,2.65-.7,.7-1.67,1.1-2.65,1.1H31.31c-.99,0-1.95-.4-2.65-1.1-.69-.7-1.09-1.66-1.09-2.65v-36.1Zm-.04,60.86c0-.99,.41-1.97,1.11-2.67,.7-.7,1.68-1.11,2.67-1.11h38.35c.99,0,1.96,.4,2.67,1.11,.71,.7,1.11,1.68,1.11,2.67v36.75c0,.99-.4,1.97-1.11,2.67-.7,.7-1.68,1.11-2.67,1.11H31.31c-1,0-1.97-.4-2.67-1.11-.7-.7-1.11-1.68-1.11-2.67v-36.75Z" />
                <GuestsRectThree
                    x="87.09"
                    y="82"
                    width="106.33"
                    height="12.34"
                />
                <GuestsRectThree
                    x="87.09"
                    y="100.97"
                    width="106.33"
                    height="12.34"
                />
                <GuestsRectThree
                    x="87.09"
                    y="145.58"
                    width="106.33"
                    height="12.35"
                />
                <GuestsRectThree
                    x="87.09"
                    y="164.55"
                    width="106.33"
                    height="12.34"
                />
                <GuestsStyleThree d="M31.31,78.26c-.99,0-1.95,.4-2.65,1.1-.69,.7-1.09,1.66-1.09,2.65v36.1c0,.99,.4,1.95,1.09,2.65,.7,.7,1.66,1.1,2.65,1.1h38.35c.99,0,1.95-.4,2.65-1.1,.69-.7,1.09-1.66,1.09-2.65v-36.1c0-.99-.41-1.95-1.09-2.65-.7-.7-1.67-1.1-2.65-1.1H31.31Zm34.6,36.11h-30.86v-28.62h30.86v28.62Z" />
                <GuestsStyleThree d="M31.31,139.08c-1,0-1.97,.4-2.67,1.11-.7,.7-1.11,1.68-1.11,2.67v36.75c0,.99,.41,1.97,1.11,2.67,.7,.7,1.68,1.11,2.67,1.11h38.35c.99,0,1.96-.4,2.67-1.11,.71-.7,1.11-1.68,1.11-2.67v-36.75c0-.99-.4-1.97-1.11-2.67-.7-.7-1.68-1.11-2.67-1.11H31.31Zm34.57,36.75h-30.79v-29.2h30.79v29.2Z" />
                <GuestsStyleTwo d="M50.48,35.22c0,6.1,4.94,11.04,11.04,11.04s11.04-4.94,11.04-11.04-4.95-11.04-11.04-11.04-11.04,4.94-11.04,11.04Z" />
                <GuestsStyleOne d="M16.79,35.22c0,6.1,4.94,11.04,11.04,11.04s11.04-4.94,11.04-11.04-4.94-11.04-11.04-11.04-11.04,4.94-11.04,11.04Z" />
            </g>
            <GuestsStyleFour points="42.42 95.29 50.32 107.14 76.49 75.54" />
        </SvgIcon>
    )
}
