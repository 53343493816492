import React, { MouseEvent, useMemo, useState } from 'react'
import { Button, Grid, IconButton } from '@mui/material'
import translate from '../i18n/Translator'
import DialogPopup from '../components/DialogPopup'
import { ImageTargetFormProps } from './ImageTargetForm.types'
import { styled } from '@mui/material/styles'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { UploaderInput } from '../components/UploaderInput'
import { InputRef } from '../components/Input.types'
import SimpleSwitch from '../components/SimpleSwitch'
import { ImageTargetButton } from '../model/Project'
import { DeleteIcon } from '../components/Icons'
import { TimingValidator } from '../components/Validators'

const placeholder = '/image-target-placeholder.jpg'

const Image = styled('img')(({ theme }) => ({
    maxWidth: '100%',
}))

function ImageTargetForm({
    imageTarget,
    s3Client,
    onUpdated,
    onClose,
}: ImageTargetFormProps) {
    const timingValidator = useMemo(() => new TimingValidator(), [])
    const [request, setRequest, validations, hasChanged, , hasChangedAny] =
        useValidatedRequest(
            imageTarget ? { ...imageTarget } : undefined,
            (name: string, value: string) => {
                if (name === 'width' || name === 'height') {
                    return !!value ? +value : value
                }
                return value
            }
        )
    const [loading, setLoading] = useState(false)
    const [hasButton, setHashButton] = useState(
        () => imageTarget?.button !== undefined
    )

    const hasChangedImage = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        setLoading(name === 'image')
        hasChangedAny(
            inputRef.name,
            value ? s3Client.getUrl(value) : '',
            inputRef
        )
    }

    const onLoadedImage = (event: React.SyntheticEvent<any, Event>) => {
        const source: string = event.currentTarget?.currentSrc ?? ''
        if (source && !source.endsWith(placeholder)) {
            setLoading(false)

            const width = event?.currentTarget?.naturalWidth ?? 0
            const height = event?.currentTarget?.naturalHeight ?? 0
            setRequest({ ...request, width, height })
        }
    }

    const onUpdate = () => {
        if (!isValid(validations)) return

        if (hasButton) {
            onUpdated(request)
        } else {
            const { button, ...fixedRequest } = request
            onUpdated(fixedRequest)
        }
    }

    const hasChangedButton =
        (field: string) =>
        (name: string, value: string, inputRef: InputRef) => {
            const currentButton = request.button ?? ({} as ImageTargetButton)
            let fixedValue = value
            if (field === 'image') {
                fixedValue = value ? s3Client.getUrl(value) : ''
            }

            const newButton = { ...currentButton, [field]: fixedValue }
            validations[name] = inputRef

            setRequest({ ...request, button: newButton })
        }

    const removeButtonImage = (event: MouseEvent) => {
        event.stopPropagation()
        const currentButton = request.button ?? ({} as ImageTargetButton)
        const newButton = { ...currentButton, image: '' }
        setRequest({ ...request, button: newButton })
    }

    return (
        <DialogPopup
            open
            title={translate(
                `projects.image_targets.popup.${imageTarget ? 'edit' : 'add'}`
            )}
            maxWidth="md"
            fullWidth
            disable={loading}
            disableBackdropClick={loading}
            disableEscapeKeyDown={loading}
            onClose={onClose}
            button={
                <Button onClick={onUpdate} color="primary" disabled={loading}>
                    {translate('buttons.update')}
                </Button>
            }
        >
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                    <Image
                        src={request?.image || placeholder}
                        alt="target"
                        onLoad={onLoadedImage}
                    />
                </Grid>
                <Grid item xs>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <UploaderInput
                                id="image"
                                name="image"
                                label={
                                    translate(
                                        'projects.image_targets.image'
                                    ) as string
                                }
                                s3Client={s3Client}
                                value={request.image}
                                acceptExtension={'.jpg,.png'}
                                path={'target-images'}
                                required
                                disabled={false}
                                acl="public-read"
                                onValueChanged={hasChangedImage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="number"
                                        id="width"
                                        name="width"
                                        value={`${request.width ?? 0}`}
                                        label={
                                            translate(
                                                'projects.image_targets.width'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="number"
                                        id="height"
                                        name="height"
                                        value={`${request.height ?? 0}`}
                                        label={
                                            translate(
                                                'projects.image_targets.height'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <UploaderInput
                                id="video"
                                name="video"
                                label={
                                    translate(
                                        'projects.image_targets.video'
                                    ) as string
                                }
                                s3Client={s3Client}
                                value={request.video}
                                acceptExtension={'.mp4'}
                                contentType="video/mp4"
                                path={'videos'}
                                required
                                disabled={false}
                                acl="public-read"
                                onValueChanged={hasChangedImage}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <SimpleSwitch
                                checked={hasButton}
                                value=""
                                label={
                                    translate(
                                        'projects.image_targets.button.title'
                                    ) as string
                                }
                                onChanged={(name, checked) =>
                                    setHashButton(checked)
                                }
                                placement="end"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={hasButton ? {} : { display: 'none' }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="button.text"
                                        name="button.text"
                                        value={`${request.button?.text ?? ''}`}
                                        label={
                                            translate(
                                                'projects.image_targets.button.text'
                                            ) as string
                                        }
                                        required={hasButton}
                                        disabled={!hasButton}
                                        onValueChanged={hasChangedButton(
                                            'text'
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="button.url"
                                        name="button.url"
                                        value={`${request.button?.url ?? ''}`}
                                        label={
                                            translate(
                                                'projects.image_targets.button.url'
                                            ) as string
                                        }
                                        required={hasButton}
                                        disabled={!hasButton}
                                        onValueChanged={hasChangedButton('url')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <UploaderInput
                                        id="button.image"
                                        name="button.image"
                                        label={
                                            translate(
                                                'projects.image_targets.button.image'
                                            ) as string
                                        }
                                        s3Client={s3Client}
                                        value={request.button?.image}
                                        acceptExtension={'.jpg,.png'}
                                        path={'target-images'}
                                        disabled={!hasButton}
                                        acl="public-read"
                                        onValueChanged={hasChangedButton(
                                            'image'
                                        )}
                                        endAdornment={
                                            request.button?.image ? (
                                                <IconButton
                                                    color="error"
                                                    edge="end"
                                                    size="medium"
                                                    onClick={removeButtonImage}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            ) : undefined
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="button.timing"
                                        name="button.timing"
                                        value={request.button?.timing ?? ''}
                                        label={
                                            translate(
                                                'projects.image_targets.button.timing'
                                            ) as string
                                        }
                                        required={hasButton}
                                        disabled={!hasButton}
                                        validator={timingValidator}
                                        onValueChanged={hasChangedButton(
                                            'timing'
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </DialogPopup>
    )
}

export default ImageTargetForm
