import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
} from 'react'
import { useLocation, Link } from 'react-router-dom'

import { IconButton, Divider, Fab, Box, Typography } from '@mui/material'
import { UsersIcon, MoreVertIcon, AddIcon } from '../components/Icons'

import Pagination, {
    initialPage,
    initialPageSize,
    getOffset,
} from '../components/Pagination'
import { listUsers } from '../api/UserAPI'
import { getUsers as getTenantUsers } from '../api/TenantUserAPI'
import { Users, User, UserListParams } from '../model/User'
import { AppContext } from '../context/AppContext'
import translate from '../i18n/Translator'
import { RouterParams } from '../router/RouterParams'
import UserMenu from './UserMenu'
import UserActivatePopup from './UserActivatePopup'
import UserBlockPopup from './UserBlockPopup'
import UserUnblockPopup from './UserUnblockPopup'
import UserDeletePopup from './UserDeletePopup'
import { PatternFormat } from 'react-number-format'
import { phoneFormat } from '../components/Validators'

import { Griddable } from '../components/Griddable'
import Ellipsis from '../components/Ellipsis'
import UsersFilter, { useUsersParams } from './UsersFilter'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function UsersList({ match }: RouterParams) {
    const feedbackContext = useContext(FeedbackContext)
    const context = useContext(AppContext)
    const paramTenantId = match.params.tenantId
    const tenantId = context.session?.tenant?.id ?? paramTenantId
    const isGrantedCreated = useMemo(
        () => context.isGrantedAny(['UsersCreate', 'TenantsUsersCreate']),
        [context]
    )

    const query = new URLSearchParams(useLocation().search)
    const params = useUsersParams(query)

    const [status, setStatus] = useState<string>('loading')
    const [data, setData] = useState<Users | undefined>()

    const [user, setUser] = useState<User>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activatePopup, setActivatePopup] = useState<boolean>(false)
    const [dialog, setDialog] = useState<'block' | 'unblock' | 'delete'>()

    const [page, setPage] = useState<number>(initialPage(query))
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query))

    const retrievePromise = useCallback(
        (params: UserListParams): Promise<Users> => {
            const offset = getOffset(page, pageSize)
            const fixedParams = { ...params, offset, page: pageSize }
            if (tenantId) {
                return getTenantUsers(tenantId, fixedParams)
            }
            return listUsers(fixedParams)
        },
        [tenantId, page, pageSize]
    )

    const load = () => {
        setStatus('loading')
        retrievePromise(params)
            .then((response) => {
                setData(response)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }

    useEffect(load, [params, retrievePromise])

    const onChangedPage = (page: number) => {
        setPage(page)
        setData(undefined)
    }

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page)
        setPageSize(pageSize)
        setData(undefined)
    }

    const onClickedOptions =
        (user: User) => (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
            setUser(user)
        }

    const showDialog = (dialog: 'block' | 'unblock' | 'delete') => () => {
        if (!user) return
        setAnchorEl(null)
        setDialog(dialog)
    }

    const onBlocked = () => {
        if (!user) return

        user.blocked = true
        feedbackContext.showSuccess(
            translate('users.block.success', {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
            }) as string
        )
        setUser(undefined)
        setDialog(undefined)
    }

    const onUnblocked = () => {
        if (!user) return

        user.blocked = false
        feedbackContext.showSuccess(
            translate('users.unblock.success', {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
            }) as string
        )
        setUser(undefined)
        setDialog(undefined)
    }

    const onDelete = () => {
        if (!user) return

        load()
        feedbackContext.showSuccess(
            translate('users.delete.success', {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
            }) as string
        )
        setUser(undefined)
        setDialog(undefined)
    }

    const onError = (error: string) => {
        feedbackContext.showError(error)
        setUser(undefined)
        setDialog(undefined)
    }

    const onCancel = () => {
        setUser(undefined)
        setDialog(undefined)
    }

    const onCloseOption = () => {
        setAnchorEl(null)
        setUser(undefined)
    }

    const showActivatePopup = () => {
        setAnchorEl(null)
        setActivatePopup(true)
    }

    const hideActivatePopup = () => {
        setActivatePopup(false)
        setUser(undefined)
    }

    return (
        <Pagination
            title={translate('users.title')}
            subtitle={data?.tenant ? data.tenant.name : undefined}
            icon={<UsersIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            backButton={!!paramTenantId}
            action={
                isGrantedCreated ? (
                    <Link
                        to={
                            paramTenantId
                                ? `/accounts/${paramTenantId}/users/new`
                                : '/users/new'
                        }
                    >
                        <Fab
                            color="primary"
                            size="medium"
                            title={translate('buttons.add') as string}
                        >
                            <AddIcon />
                        </Fab>
                    </Link>
                ) : undefined
            }
        >
            <UsersFilter roles={data?.metadata.roles ?? []} />
            <Box mx={-2} mt={2}>
                <Divider />
                <Griddable
                    items={data ? data.items : []}
                    loading={status === 'loading'}
                    error={
                        status !== 'loading' && status !== 'loaded'
                            ? status
                            : undefined
                    }
                    empty={translate('users.empty') as string}
                    getId={(user) => user.id}
                    columns={[
                        {
                            title: translate('users.name') as string,
                            converter: (user) => (
                                <div>
                                    <Ellipsis
                                        text={`${user.first_name} ${user.last_name}`}
                                        lenght={0}
                                        uppercased={false}
                                    />
                                    <Box
                                        sx={{
                                            display: {
                                                xs: 'block',
                                                sm: 'none',
                                            },
                                        }}
                                    >
                                        <Ellipsis
                                            text={user.email}
                                            lenght={0}
                                            uppercased={false}
                                            secondary
                                        />
                                        <br></br>
                                        <Ellipsis
                                            text={
                                                user.role
                                                    ? (translate(
                                                          `users.roles.${user.role.id}`
                                                      ) as string)
                                                    : '---'
                                            }
                                            lenght={0}
                                            uppercased={false}
                                            secondary
                                        />
                                    </Box>
                                </div>
                            ),
                            xs: true,
                        },
                        {
                            title: translate('users.email') as string,
                            converter: (user) => user.email,
                            xs: false,
                            sm: 3,
                            lg: 3,
                        },
                        {
                            title: translate('users.phone') as string,
                            converter: (contact) =>
                                contact.phone ? (
                                    <PatternFormat
                                        value={contact.phone}
                                        format={phoneFormat(contact.phone)}
                                        displayType="text"
                                        renderText={(text) => (
                                            <Typography variant="body2">
                                                {text}
                                            </Typography>
                                        )}
                                    />
                                ) : (
                                    '---'
                                ),
                            xs: false,
                            sm: false,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: translate('users.status.title') as string,
                            converter: (user) =>
                                translate(
                                    `users.status.${
                                        user.blocked
                                            ? 'blocked'
                                            : user.signup_code
                                            ? 'inactive'
                                            : 'active'
                                    }`
                                ),
                            xs: false,
                            sm: 2,
                            lg: 1,
                        },
                        {
                            title: (
                                <IconButton
                                    size="small"
                                    style={{ visibility: 'hidden' }}
                                    disabled
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            converter: (user) => (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onClickedOptions(user)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            xs: 'auto',
                        },
                    ]}
                />
            </Box>
            {user && anchorEl && (
                <UserMenu
                    user={user}
                    tenantId={paramTenantId}
                    anchor={anchorEl}
                    onClose={onCloseOption}
                    onBlock={showDialog('block')}
                    onUnblock={showDialog('unblock')}
                    onDelete={showDialog('delete')}
                    onActivated={showActivatePopup}
                />
            )}
            {user && activatePopup && (
                <UserActivatePopup user={user} onClose={hideActivatePopup} />
            )}
            {user && dialog === 'block' && (
                <UserBlockPopup
                    user={user}
                    tenantId={tenantId}
                    onCompleted={onBlocked}
                    onError={onError}
                    onCancelled={onCancel}
                />
            )}
            {user && dialog === 'unblock' && (
                <UserUnblockPopup
                    user={user}
                    tenantId={tenantId}
                    onCompleted={onUnblocked}
                    onError={onError}
                    onCancelled={onCancel}
                />
            )}
            {user && dialog === 'delete' && (
                <UserDeletePopup
                    user={user}
                    tenantId={tenantId}
                    onCompleted={onDelete}
                    onError={onError}
                    onCancelled={onCancel}
                />
            )}
        </Pagination>
    )
}
