import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    Fade,
    Divider,
} from '@mui/material'
import {
    EditIcon,
    DeleteIcon,
    SendPostcardIcon,
    PostcardsIcon,
} from '../components/Icons'

import translate from '../i18n/Translator'
import { Guest } from '../model/Guest'
import { AppContext } from '../context/AppContext'

interface GuestMenuProps {
    tenantId?: string
    guest: Guest
    anchor: HTMLElement | null
    onClose(): any
    onSendPostcard?(): any
    onDelete(): any
}

export default function GuestMenu({
    tenantId,
    guest,
    anchor,
    onClose,
    onSendPostcard,
    onDelete,
}: GuestMenuProps) {
    const context = useContext(AppContext)
    const [isUpdateGranted] = useState(
        context.isGrantedAny(['PGuestsUpdate', 'TGuestsUpdate'])
    )
    const [isDeleteGranted] = useState(
        context.isGrantedAny(['PGuestsDelete', 'TGuestsDelete'])
    )
    const [isPostcardsCreate] = useState(
        context.isGrantedAny(['PPostcardsCreate', 'TPostcardsCreate'])
    )
    const [isPostcardsRead] = useState(
        context.isGrantedAny(['PPostcardsRead', 'TPostcardsRead'])
    )

    return (
        <Menu
            id="guest-item"
            anchorEl={anchor}
            keepMounted
            onClose={onClose}
            open
            TransitionComponent={Fade}
        >
            {isUpdateGranted && (
                <MenuItem
                    component={Link}
                    to={`${tenantId ? `/accounts/${tenantId}` : ''}/guests/${
                        guest.id
                    }/edit`}
                    onClick={onClose}
                    dense
                >
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.edit')}
                    </Typography>
                </MenuItem>
            )}
            {isPostcardsRead && (
                <MenuItem
                    component={Link}
                    to={`${
                        tenantId ? `/accounts/${tenantId}` : ''
                    }/postcards?guestId=${guest.id}`}
                    onClick={onClose}
                    dense
                >
                    <ListItemIcon>
                        <PostcardsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('postcards.title')}
                    </Typography>
                </MenuItem>
            )}
            {isPostcardsCreate && onSendPostcard && (
                <MenuItem onClick={onSendPostcard} dense>
                    <ListItemIcon>
                        <SendPostcardIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.send_postcard')}
                    </Typography>
                </MenuItem>
            )}
            {(isUpdateGranted || isPostcardsCreate) && isDeleteGranted && (
                <Divider />
            )}
            {isDeleteGranted && (
                <MenuItem onClick={onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.delete')}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    )
}
