import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Grid,
    Button,
    Typography,
    Box,
    useMediaQuery,
    Theme,
} from '@mui/material'
import { TenantsIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import { Tenant, TenantRequest } from '../model/Tenant'
import {
    createTenant,
    getTenant,
    updateTenant,
    getMetadata,
} from '../api/TenantAPI'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import Surface from '../components/Surface'
import { RouterParams } from '../router/RouterParams'
import Progress from '../components/Progress'
import { emptyPromise } from '../api/API'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function TenantForm({ match }: RouterParams) {
    const history = useHistory()
    const feedbackContext = useContext(FeedbackContext)
    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const tenantId = match.params.tenantId

    const [status, setStatus] = useState<string>('loading')
    const [planIds, setPlanIds] = useState<string[]>([])
    const [planLabels, setPlanLabels] = useState<string[]>([])
    const [request, setRequest, validations, hasChanged] =
        useValidatedRequest<TenantRequest>()

    const promiseGet = useCallback((): Promise<Tenant | undefined> => {
        if (tenantId) {
            return getTenant(tenantId)
        }
        return emptyPromise()
    }, [tenantId])

    const submitPromise = (): Promise<Tenant> => {
        if (tenantId) {
            return updateTenant(tenantId, request)
        }
        return createTenant(request)
    }

    useEffect(() => {
        setStatus('loading')
        Promise.all([getMetadata(), promiseGet()])
            .then(([metadata, tenant]) => {
                const planIds = metadata.plans.map((plan) => plan.id)
                const defaultPlanId =
                    planIds.length === 1 ? planIds[0] : undefined

                setPlanIds(planIds)
                setPlanLabels(metadata.plans.map((plan) => plan.name))
                setRequest({
                    tenant_name: tenant?.name || '',
                    plan_id: tenant?.plan?.id || defaultPlanId || '',
                } as TenantRequest)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [promiseGet, setRequest])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        submitPromise()
            .then((tenant) => {
                feedbackContext.showSuccess(
                    translate(`tenants.${tenantId ? 'edited' : 'created'}`, {
                        name: tenant.name,
                    }) as string
                )
                history.push('/accounts')
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return (
            <Typography
                variant="body1"
                component="h5"
                color="error"
                align="center"
            >
                {status}
            </Typography>
        )
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface
                        title={translate(
                            tenantId
                                ? 'tenants.edit_tenant'
                                : 'tenants.new_tenant'
                        )}
                        icon={<TenantsIcon />}
                    >
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="tenant_name"
                                        name="tenant_name"
                                        value={request.tenant_name}
                                        label={
                                            translate('tenants.name') as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="plan_id"
                                        name="plan_id"
                                        value={request.plan_id}
                                        label={
                                            translate('tenants.plan') as string
                                        }
                                        options={planIds}
                                        optionLabels={planLabels}
                                        disabled={!!tenantId}
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            spacing={1}
                                            direction="row-reverse"
                                        >
                                            <Grid item xs={12} sm="auto">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    fullWidth={isXs}
                                                >
                                                    {translate(
                                                        tenantId
                                                            ? 'buttons.update'
                                                            : 'buttons.add'
                                                    )}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} sm="auto">
                                                <Button
                                                    variant="text"
                                                    color="inherit"
                                                    size="large"
                                                    onClick={history.goBack}
                                                    fullWidth={isXs}
                                                >
                                                    {translate(
                                                        'buttons.cancel'
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    )
}
