import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, MenuItem, MenuList, ListItemIcon } from '@mui/material'

import { UserIcon, SecurityIcon, LogoutIcon } from '../components/Icons'
import translate from '../i18n/Translator'
import { AccountMenuProps } from './AccountMenu.types'
import { PopperMenu } from '../components/PopperMenu'

export function AccountMenu(props: AccountMenuProps) {
    const handleListKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab' || event.key === 'Escape') {
            event.preventDefault()
            props.onClose()
        }
    }

    return (
        <PopperMenu placement="bottom-end" {...props}>
            <MenuList
                autoFocusItem
                id="account-menu"
                onKeyDown={handleListKeyDown}
            >
                <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={props.onClose}
                >
                    <ListItemIcon>
                        <UserIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('users.profile.title')}
                    </Typography>
                </MenuItem>
                <MenuItem
                    component={Link}
                    to="/security"
                    onClick={props.onClose}
                >
                    <ListItemIcon>
                        <SecurityIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('users.security.title')}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={props.onSignOut}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.signout')}
                    </Typography>
                </MenuItem>
            </MenuList>
        </PopperMenu>
    )
}
