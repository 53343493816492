import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Grid,
    Button,
    Typography,
    Box,
    IconButton,
    Divider,
    styled,
} from '@mui/material'
import {
    AddIcon,
    DeleteIcon,
    EditIcon,
    MoreVertIcon,
    ProjectsIcon,
} from '../components/Icons'
import translate from '../i18n/Translator'
import { FeedbackContext } from '../feedback/FeedbackContext'
import { RouterParams } from '../router/RouterParams'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import {
    ImageTarget,
    isEquals,
    Project,
    ProjectRequest,
    ProjectSplash,
    PROJECT_ORIENTATIONS,
} from '../model/Project'
import { emptyPromise } from '../api/API'
import { createProject, getProject, updateProject } from '../api/ProjectAPI'
import Progress from '../components/Progress'
import Surface from '../components/Surface'
import { useS3Client } from '../components/CustomS3Client'
import ImageTargetForm from './ImageTargetForm'
import { generateMind } from './ImageTargetCompiler'
import ProjectImageTargets from './ProjectImageTargets'
import ProjectMenu from './ProjectMenu'
import ProjectPreviewPopup from './ProjectPreviewPopup'
import { HelpTwoTone } from '@mui/icons-material'
import ImageTargetDeletePopup from './ImageTargetDeletePopup'
import ProjectDomainPopup from './ProjectDomainPopup'
import SplashScreenForm from './SplashScreenForm'
import ProjectSplashScreen from './ProjectSplashScreen'
import ProjectDeployPopup from './ProjectDeployPopup'

const Image = styled('img')(({ theme }) => ({
    position: 'fixed',
    top: '-5000px',
    visibility: 'hidden',
}))

type Popup =
    | 'add'
    | 'delete'
    | 'edit'
    | 'preview'
    | 'domain'
    | 'splash'
    | 'deploy'
    | 'qr'

export default function ProjectForm({ match }: RouterParams) {
    const history = useHistory()
    const feedbackContext = useContext(FeedbackContext)
    const projectId = match.params.projectId
    const s3Client = useS3Client()
    const projectOrientations = PROJECT_ORIENTATIONS.map(
        (el) => translate(`projects.orientation.${el}`) as string
    )

    const [status, setStatus] = useState<string>('loading')
    const [request, setRequest, validations, hasChanged] =
        useValidatedRequest<ProjectRequest>()

    const [popup, setPopup] = useState<Popup>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [project, setProject] = useState<Project>()
    const [imageTarget, setImageTarget] = useState<ImageTarget>()
    const [index, setIndex] = useState(-1)

    const retrievePromise = useCallback((): Promise<Project | undefined> => {
        if (projectId) {
            return getProject(projectId)
        }
        return emptyPromise()
    }, [projectId])

    useEffect(() => {
        setStatus('loading')
        Promise.all([retrievePromise()])
            .then(([project]) => {
                const data = project?.content?.DEV
                setProject(project)
                setRequest({
                    id: project?.id || '',
                    name: project?.name || '',
                    orientation: project?.orientation || 'landscape',
                    description: project?.description,
                    domain: project?.domain,
                    stage_data: {
                        mind: data?.mind || '',
                        splash: data?.splash,
                        image_targets: data?.image_targets ?? [],
                    },
                } as ProjectRequest)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [retrievePromise, setRequest])

    useEffect(() => {
        if (projectId || !request.name) {
            return
        }

        const calculatedId = request.name //
            .trim() //
            .toLowerCase() //
            .replaceAll(' ', '-') //
            .normalize('NFKD') //
            .replace(/\p{Diacritic}/gu, '')
        setRequest({ ...request, id: calculatedId })
        // eslint-disable-next-line
    }, [projectId, request.name, setRequest])

    const submitPromise = (url: string): Promise<Project> => {
        const data = {
            ...request,
            stage_data: { ...request.stage_data, mind: url },
        }
        if (projectId) {
            return updateProject(projectId, data)
        }
        return createProject(data)
    }

    const submit = (callback: (project: Project) => any) => {
        if (!isValid(validations)) {
            return
        }

        const imageTargets = document.querySelectorAll('[data-ar-content]')
        if (imageTargets.length === 0) {
            feedbackContext.showWarning(
                translate('projects.missing_images') as string
            )
            return
        }

        feedbackContext.showBackdrop(
            translate('projects.creating_mind') as string
        )
        generateMind(imageTargets)
            .then(async (file: Blob) => {
                feedbackContext.showBackdrop(
                    translate('projects.uploading_mind') as string
                )
                const mindKey = s3Client.calculateKey(
                    'mind-files',
                    'targets.zip',
                    true
                )
                await s3Client.upload(
                    mindKey,
                    file,
                    'public-read',
                    'application/octet-stream'
                )
                return s3Client.getUrl(mindKey)
            })
            .then((url) => {
                feedbackContext.showBackdrop(
                    translate('projects.updating') as string
                )
                return submitPromise(url)
            })
            .then(callback)
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        submit((project) => {
            feedbackContext.showSuccess(
                translate(`projects.${projectId ? 'edited' : 'created'}`, {
                    name: project.name,
                }) as string
            )

            if (!projectId) {
                history.replace(`/projects/${project.id}/edit`)
            }
        })
    }

    const addImageTarget = () => {
        setIndex(-1)
        setPopup('add')
    }

    const closePopup = () => {
        setIndex(-1)
        setPopup(undefined)
    }

    const updateImageTarget = (imageTarget: ImageTarget) => {
        let imageTargets: ImageTarget[]
        if (index < 0) {
            imageTargets = [...request.stage_data.image_targets, imageTarget]
        } else {
            imageTargets = request.stage_data.image_targets.map((el, i) =>
                i === index ? imageTarget : el
            )
        }

        const content = {
            ...request.stage_data,
            image_targets: imageTargets,
        }
        setRequest({ ...request, stage_data: content })
        closePopup()
    }

    const deleteImageTarget = (index: number) => {
        setIndex(index)
        setPopup('delete')
    }

    const editImageTarget = (index: number) => {
        const imageTarget = request.stage_data.image_targets[index]
        setImageTarget(imageTarget)
        setIndex(index)
        setPopup('edit')
    }

    const onDelete = () => {
        const imageTargets = request.stage_data.image_targets.filter(
            (el, i) => i !== index
        )
        const content = { ...request.stage_data, image_targets: imageTargets }
        setRequest({ ...request, stage_data: content })
        closePopup()
    }

    const showMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const handleChanges = (popup: Popup, message: string) => {
        closeMenu()

        if (
            isEquals(
                project?.content?.DEV.image_targets ?? [],
                request.stage_data.image_targets
            )
        ) {
            setPopup(popup)
        } else {
            if (!isValid(validations)) {
                feedbackContext.showWarning(translate(message) as string)
                return
            }

            submit((project) => {
                setProject(project)
                setPopup(popup)
            })
        }
    }

    const onPreview = () => {
        handleChanges('preview', 'projects.DEV.bad_request')
    }

    const onQr = () => {
        setAnchorEl(null)
        setPopup('qr')
    }

    const onDeploy = () => {
        handleChanges('deploy', 'projects.deploy.bad_request')
    }

    const onSplashScreen = () => {
        closeMenu()
        setPopup('splash')
    }

    const onUpdatedSplashScreen = (data?: ProjectSplash) => {
        setPopup(undefined)
        setRequest({
            ...request,
            stage_data: { ...request.stage_data, splash: data },
        })
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return (
            <Typography
                variant="body1"
                component="h5"
                color="error"
                align="center"
            >
                {status}
            </Typography>
        )
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface
                        title={translate(
                            projectId ? 'projects.edit' : 'projects.new'
                        )}
                        icon={<ProjectsIcon />}
                        backButton
                        titleActions={
                            project ? (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={showMenu}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ) : undefined
                        }
                    >
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={request.name}
                                        label={
                                            translate('projects.name') as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="id"
                                        name="id"
                                        value={request.id}
                                        label={
                                            translate('projects.id') as string
                                        }
                                        required
                                        disabled={!!projectId}
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="orientation"
                                        name="orientation"
                                        value={request.orientation}
                                        options={PROJECT_ORIENTATIONS}
                                        optionLabels={projectOrientations}
                                        label={
                                            translate(
                                                'projects.orientation.title'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="description"
                                        name="description"
                                        value={request.description ?? ''}
                                        label={
                                            translate(
                                                'projects.description'
                                            ) as string
                                        }
                                        multiline
                                        minRows={4}
                                        maxRows={10}
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ display: 'none' }}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item xs>
                                            <ValidatedInput
                                                type="text"
                                                id="domain"
                                                name="domain"
                                                value={request.domain ?? ''}
                                                label={
                                                    translate(
                                                        'projects.domain'
                                                    ) as string
                                                }
                                                multiline
                                                minRows={4}
                                                maxRows={10}
                                                onValueChanged={hasChanged}
                                            />
                                        </Grid>
                                        <Grid item xs="auto">
                                            <IconButton
                                                size="small"
                                                color="info"
                                                disabled={!request.domain}
                                                onClick={() =>
                                                    setPopup('domain')
                                                }
                                            >
                                                <HelpTwoTone />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        justifyItems="center"
                                        alignContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs>
                                            <Typography variant="subtitle1">
                                                {translate(
                                                    'projects.image_targets.title'
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <IconButton
                                                color="primary"
                                                size="large"
                                                onClick={addImageTarget}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <ProjectImageTargets
                                        imageTargets={
                                            request.stage_data.image_targets
                                        }
                                        onEdit={editImageTarget}
                                        onDelete={deleteImageTarget}
                                    />
                                    <Divider sx={{ mb: 1 }} />
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        justifyItems="center"
                                        alignContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs>
                                            <Typography variant="subtitle1">
                                                {translate(
                                                    'projects.splash.title'
                                                )}
                                            </Typography>
                                        </Grid>
                                        {request.stage_data.splash && (
                                            <Grid item xs="auto">
                                                <IconButton
                                                    color="error"
                                                    size="large"
                                                    onClick={() =>
                                                        onUpdatedSplashScreen(
                                                            undefined
                                                        )
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                        <Grid item xs="auto">
                                            <IconButton
                                                color="primary"
                                                size="large"
                                                onClick={onSplashScreen}
                                            >
                                                {!request.stage_data.splash && (
                                                    <AddIcon />
                                                )}
                                                {request.stage_data.splash && (
                                                    <EditIcon />
                                                )}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <ProjectSplashScreen
                                        splash={request.stage_data.splash}
                                    />
                                    <Divider sx={{ mb: 1 }} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            spacing={1}
                                            direction="row-reverse"
                                        >
                                            <Grid item xs={12} md="auto">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    disabled={
                                                        request.stage_data
                                                            .image_targets
                                                            .length === 0
                                                    }
                                                >
                                                    {translate('buttons.save')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    size="large"
                                                    onClick={history.goBack}
                                                >
                                                    {translate(
                                                        'buttons.cancel'
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                        {(popup === 'add' || popup === 'edit') && (
                            <ImageTargetForm
                                imageTarget={imageTarget}
                                s3Client={s3Client}
                                onUpdated={updateImageTarget}
                                onClose={closePopup}
                            />
                        )}
                        {popup === 'delete' && (
                            <ImageTargetDeletePopup
                                onDelete={onDelete}
                                onClose={closePopup}
                            />
                        )}
                        {popup === 'domain' && (
                            <ProjectDomainPopup
                                domain={request.domain}
                                onClose={closePopup}
                            />
                        )}
                        {project && popup === 'preview' && (
                            <ProjectPreviewPopup
                                stage="DEV"
                                project={project}
                                onClose={closePopup}
                            />
                        )}
                        {project && popup === 'qr' && (
                            <ProjectPreviewPopup
                                stage="PROD"
                                project={project}
                                onClose={closePopup}
                            />
                        )}
                        {project && popup === 'deploy' && (
                            <ProjectDeployPopup
                                project={project}
                                onDeployed={setProject}
                                onClose={closePopup}
                            />
                        )}
                        {popup === 'splash' && (
                            <SplashScreenForm
                                current={request.stage_data.splash}
                                s3Client={s3Client}
                                onUpdated={onUpdatedSplashScreen}
                                onClose={closePopup}
                            />
                        )}
                        {project && anchorEl && (
                            <ProjectMenu
                                project={project}
                                anchor={anchorEl}
                                variant="form"
                                onClose={closeMenu}
                                onPreview={onPreview}
                                onQr={onQr}
                                onDeploy={onDeploy}
                                onDelete={() => {}}
                            />
                        )}
                    </Surface>
                </Grid>
            </Grid>
            {request.stage_data.image_targets
                .map((el) => el.image)
                .map((src) => (
                    <Image
                        key={src}
                        src={src}
                        alt=""
                        crossOrigin="anonymous"
                        data-ar-content
                    />
                ))}
        </Grid>
    )
}
