import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
    IconButton,
    Divider,
    Button,
    Fab,
    Box,
    Grid,
    Typography,
} from '@mui/material'
import { PostcardsIcon, MoreVertIcon, AddIcon } from '../components/Icons'
import { listPostcards, deletePostcard } from '../api/PostcardAPI'
import { AppContext } from '../context/AppContext'
import { Postcard, Postcards, PostcardsListParams } from '../model/Postcard'
import translate from '../i18n/Translator'
import Pagination, {
    initialPage,
    initialPageSize,
    getOffset,
} from '../components/Pagination'
import { Griddable } from '../components/Griddable'
import DialogPopup from '../components/DialogPopup'
import PostcardMenu from './PostcardMenu'
import SearchFilter, { useSearchParams } from '../components/SearchFilter'
import { FeedbackContext } from '../feedback/FeedbackContext'
import { RouterParams } from '../router/RouterParams'
import { formatDateTime } from '../components/DateFormat.types'
import Image from '../components/Image'
import { getStatistics } from '../api/CreditAPI'
import { CreditStatisticResponse } from '../model/Credit'

type Popup = 'delete' | 'image'

export default function PostcardsList({ match }: RouterParams) {
    const feedbackContext = useContext(FeedbackContext)
    const context = useContext(AppContext)
    const paramTenantId = match.params.tenantId
    const tenantId = context.session?.tenant?.id || paramTenantId || '-'

    const query = new URLSearchParams(useLocation().search)
    const params = useSearchParams<PostcardsListParams>(query)
    const guestId = query.get('guestId')

    const [status, setStatus] = useState<string>('loading')
    const [data, setData] = useState<Postcards>()
    const [subtitle, setSubtitle] = useState<string>()
    const [statistics, setStatistics] = useState<CreditStatisticResponse>()

    const [postcard, setPostcard] = useState<Postcard>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [popup, setPopup] = useState<Popup>()

    const [page, setPage] = useState<number>(initialPage(query))
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query))
    const [isCreateGranted] = useState(
        context.isGrantedAny(['PPostcardsCreate', 'TPostcardsCreate'])
    )

    const retrievePromise = useCallback(() => {
        const offset = getOffset(page, pageSize)
        return listPostcards(tenantId, {
            ...params,
            page: pageSize,
            offset,
            guestId,
        })
    }, [tenantId, page, pageSize, params, guestId])

    const statisticsPromise = useCallback(() => {
        return getStatistics(tenantId)
    }, [tenantId])

    const fetchData = useCallback(async (): Promise<
        [Postcards, CreditStatisticResponse]
    > => {
        const [data, statistics] = await Promise.all([
            retrievePromise(),
            statisticsPromise(),
        ])

        return [data, statistics]
    }, [retrievePromise, statisticsPromise])

    const load = () => {
        fetchData()
            .then(([data, statistics]) => {
                setData(data)
                setSubtitle(
                    [
                        data?.guest?.name,
                        paramTenantId ? data?.tenant?.name : undefined,
                    ]
                        .filter((el) => !!el)
                        .join(' - ')
                )
                setStatistics(statistics)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }

    useEffect(load, [fetchData, paramTenantId])

    const onChangedPage = (page: number) => {
        setPage(page)
        setData(undefined)
    }

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page)
        setPageSize(pageSize)
        setData(undefined)
    }

    const onClickedOptions =
        (postcard: Postcard) => (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setPostcard(postcard)
        }

    const onClosePopup = () => {
        onCloseOption()
        setPopup(undefined)
    }

    const onDelete = () => {
        if (!postcard) return

        setAnchorEl(null)
        setPopup('delete')
    }

    const onShowImage = () => {
        if (!postcard) return

        setAnchorEl(null)
        setPopup('image')
    }

    const onConfirmDelete = () => {
        onClosePopup()
        if (!postcard) return

        feedbackContext.showBackdrop()
        deletePostcard(tenantId, postcard.id)
            .then(() => {
                feedbackContext.showSuccess(
                    translate('postcards.delete.success', {
                        id: postcard.id,
                    }) as string
                )
                load()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onCloseOption = () => {
        setAnchorEl(null)
        setPostcard(undefined)
    }

    return (
        <Pagination
            title={translate('postcards.title')}
            subtitle={subtitle}
            icon={<PostcardsIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            backButton={!!guestId}
            action={
                <Grid
                    container
                    columnSpacing={4}
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    sx={{ mt: 1 }}
                >
                    <Grid item xs="auto">
                        <Typography
                            component="h6"
                            variant="subtitle1"
                            color="textSecondary"
                            textAlign="right"
                        >
                            {translate('postcards.sent')}
                        </Typography>
                        <Typography
                            component="h6"
                            variant="subtitle1"
                            textAlign="right"
                        >
                            {statistics?.sent ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography
                            component="h6"
                            variant="subtitle1"
                            color="textSecondary"
                            textAlign="right"
                        >
                            {translate('postcards.purchased')}
                        </Typography>
                        <Typography
                            component="h6"
                            variant="subtitle1"
                            textAlign="right"
                        >
                            {statistics?.left ?? 0}
                        </Typography>
                    </Grid>
                    {isCreateGranted && (
                        <Grid item xs="auto">
                            <Link
                                to={`${
                                    paramTenantId
                                        ? `/accounts/${paramTenantId}`
                                        : ''
                                }/postcards/new`}
                            >
                                <Fab
                                    color="primary"
                                    size="medium"
                                    title={translate('buttons.add') as string}
                                >
                                    <AddIcon />
                                </Fab>
                            </Link>
                        </Grid>
                    )}
                </Grid>
            }
        >
            <SearchFilter title={translate('postcards.filter') as string} />
            <Box mx={-2} mt={2}>
                <Divider />
                <Griddable
                    items={data ? data.items : []}
                    loading={status === 'loading'}
                    error={
                        status !== 'loading' && status !== 'loaded'
                            ? status
                            : undefined
                    }
                    empty={translate('postcards.empty') as string}
                    getId={(item) => item.id}
                    columns={[
                        {
                            title: translate('postcards.id') as string,
                            converter: (postcard) => postcard.id,
                            id: 'id',
                            xs: false,
                            sm: false,
                            md: 3,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: translate('guests.single') as string,
                            converter: (postcard) =>
                                postcard.guest?.name ?? '---',
                            id: 'status',
                            xs: true,
                            sm: true,
                            md: true,
                            lg: true,
                            xl: true,
                        },
                        {
                            title: translate('designs.type.title') as string,
                            converter: (postcard) =>
                                postcard.design
                                    ? translate(
                                          `designs.type.${postcard.design.type}`
                                      )
                                    : '---',
                            id: 'type',
                            xs: false,
                            sm: 3,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: translate(
                                'postcards.status.title'
                            ) as string,
                            converter: (postcard) =>
                                translate(
                                    `postcards.status.${postcard.status}`
                                ),
                            id: 'status',
                            xs: 3,
                            sm: 3,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: translate('postcards.created_at') as string,
                            converter: (postcard) =>
                                formatDateTime({ date: postcard.created_at }),
                            id: 'status',
                            xs: false,
                            sm: false,
                            md: false,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: (
                                <IconButton
                                    size="small"
                                    style={{ visibility: 'hidden' }}
                                    disabled
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            converter: (postcard) => (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onClickedOptions(postcard)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            xs: 'auto',
                        },
                    ]}
                />
            </Box>
            {postcard && anchorEl && (
                <PostcardMenu
                    tenantId={paramTenantId}
                    postcard={postcard}
                    anchor={anchorEl}
                    onDelete={onDelete}
                    onShowImage={onShowImage}
                    onClose={onCloseOption}
                />
            )}
            {postcard && popup === 'delete' && (
                <DialogPopup
                    open
                    title={translate('postcards.delete.title')}
                    onClose={onClosePopup}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="outlined"
                            color="error"
                        >
                            {translate('buttons.delete')}
                        </Button>
                    }
                >
                    {translate('postcards.delete.text', { id: postcard.id })}
                </DialogPopup>
            )}
            {postcard?.back_image && popup === 'image' && (
                <DialogPopup
                    open
                    title={translate('postcards.single')}
                    onClose={onClosePopup}
                    maxWidth="sm"
                    fullWidth
                >
                    <Image src={postcard.back_image} />
                </DialogPopup>
            )}
        </Pagination>
    )
}
