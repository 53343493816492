import { ListParams, ListResponse } from './Generic'
import { GuestRequest } from './Guest'
import { Tenant } from './Tenant'

export interface Design {
    id: string
    name: string
    front_image: string
    back_image: string
    message: string
    type: DesignType
    status: DesignStatus
    created_at: Date
}

export interface Designs extends ListResponse<Design> {
    tenant?: Tenant
}

export interface DesignsListParams extends ListParams {}

export interface DesignRequest {
    name: string
    front_image: string
    back_image: string
    message?: string
    type: DesignType
}

export interface DesignPreview {
    front_image_url: string
    back_image: string
}

export interface DesignPreviewRequest {
    guest: GuestRequest
    message?: string
}

export type DesignStatus = 'ACTIVE' | 'INACTIVE' | 'DELETED'

export type DesignType = 'PRE_ARRIVAL' | 'POST_DEPARTURE' | 'CUSTOM'

export const DESIGN_TYPES: DesignType[] = [
    'PRE_ARRIVAL',
    'POST_DEPARTURE',
    'CUSTOM',
]

export const MAX_LENGHT_DESCRIPTION = 400