import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import React from 'react'
import Clipboard from '../components/Clipboard'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import {
    ProjectDomainPopupProps,
    VIEWER_DOMAIN,
} from './ProjectDomainPopup.types'

function ProjectDomainPopup({ domain, onClose }: ProjectDomainPopupProps) {
    return (
        <DialogPopup
            open
            title={translate('projects.domain_popup.title') as string}
            onClose={onClose}
        >
            <Typography>
                {translate('projects.domain_popup.text', {
                    domain: domain ?? '---',
                })}
            </Typography>
            <Table sx={{ mt: 1, mb: 2 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Record Type</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>CNAME</TableCell>
                        <TableCell>
                            <Clipboard value={VIEWER_DOMAIN} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </DialogPopup>
    )
}

export default ProjectDomainPopup
