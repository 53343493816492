import React from 'react'
import { Grid, CircularProgress } from '@mui/material'

export interface ProgressProps {
    size?: string
    mt?: number
}

export default function Progress({ size = '1rem', mt }: ProgressProps) {
    return (
        <Grid
            container
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            sx={mt === undefined ? undefined : { mt }}
        >
            <Grid item xs="auto">
                <CircularProgress size={size} color="secondary" />
            </Grid>
        </Grid>
    )
}
