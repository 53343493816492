import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import translate from '../i18n/Translator'

import { User } from '../model/User'
import { blockUser } from '../api/UserAPI'
import { blockUser as blockTenantUser } from '../api/TenantUserAPI'
import DialogPopup from '../components/DialogPopup'

interface UserBlockPopupProps {
    user: User
    tenantId?: string
    onCompleted(): any
    onError(error: string): any
    onCancelled(): any
}

export default function UserBlockPopup(props: UserBlockPopupProps) {
    const [submitting, setSubmitting] = useState<boolean>(false)
    const blockPromise = (user: User): Promise<void> => {
        if (props.tenantId) {
            return blockTenantUser(props.tenantId, user.id)
        }
        return blockUser(user.id)
    }

    const onConfirm = () => {
        setSubmitting(true)
        blockPromise(props.user)
            .then(() => {
                setSubmitting(false)
                props.onCompleted()
            })
            .catch((error) => {
                setSubmitting(false)
                props.onError(error.message)
            })
    }

    return (
        <DialogPopup
            open
            title={translate('users.block.title') as string}
            disableEscapeKeyDown={submitting}
            disableBackdropClick={submitting}
            disable={submitting}
            closeText={translate('buttons.cancel') as string}
            onClose={props.onCancelled}
            button={
                <Button
                    onClick={onConfirm}
                    color="warning"
                    disabled={submitting}
                >
                    {translate('buttons.block')}
                </Button>
            }
            fullWidth
            maxWidth="sm"
        >
            <Typography variant="body2">
                {translate('users.block.description', {
                    first_name: props.user.first_name,
                })}
            </Typography>
        </DialogPopup>
    )
}
