import { callAPI, HTTPMethod } from './API'
import {
    Projects,
    Project,
    ProjectListParams,
    ProjectRequest,
    ProjectPreview,
    ProjectStage,
} from '../model/Project'

const getBaseApi = () => '/projects'

const getSingleApi = (id: string) => `${getBaseApi()}/${id}`

const getParams = (params: ProjectListParams) => {
    return {
        page: params?.page?.toString() ?? '',
        offset: params?.offset?.toString() ?? '',
        search: params.search ?? '',
    }
}

export async function listProjects(
    params: ProjectListParams
): Promise<Projects> {
    return await callAPI(getBaseApi(), {
        method: HTTPMethod.GET,
        query: getParams(params),
    })
}

export async function createProject(request: ProjectRequest): Promise<Project> {
    return await callAPI(getBaseApi(), {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function getProject(id: string): Promise<Project> {
    return await callAPI(getSingleApi(id), {
        method: HTTPMethod.GET,
    })
}

export async function updateProject(
    id: string,
    request: ProjectRequest
): Promise<Project> {
    return await callAPI(getSingleApi(id), {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function deleteProject(id: string): Promise<any> {
    return await callAPI(getSingleApi(id), {
        method: HTTPMethod.DELETE,
    })
}

export async function deployProject(id: string): Promise<Project> {
    return await callAPI(`${getSingleApi(id)}/deploy`, {
        method: HTTPMethod.PUT,
    })
}

export async function previewProject(
    id: string,
    stage: ProjectStage
): Promise<ProjectPreview> {
    return await callAPI(`${getSingleApi(id)}/preview`, {
        method: HTTPMethod.GET,
        query: {
            stage: stage,
        },
    })
}
