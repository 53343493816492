import React from 'react'
import { SvgIcon, styled } from '@mui/material'

const UsersStyleOne = styled('path')(({ theme }) => ({
    fill: 'currentColor',
}))
const UsersStyleTwo = styled('path')(({ theme }) => ({
    fill: '#c4c8e3',
    fillRule: 'evenodd',
}))

export const UsersIcon = (props) => {
    return (
        <SvgIcon {...props} viewBox="0 0 292 194.94">
            <UsersStyleTwo d="M219.59,107.3v12.21c46.53,26.76,70.09,13.38,70.09,73.88H98.88c0-62.82,23.56-47.11,70.1-73.88v-10.76c-8.15-5.81-9.89-16.28-12.8-27.33h-.3c-1.16,0-2.33-1.74-2.33-4.08,0-.58,.3-.58,.3-1.16-.88-1.16-1.75-2.91-2.34-5.23-.87-3.49-3.78-10.18-1.16-13.39,2.32-2.03,3.78,.3,3.78,.3-3.2-17.75-7.85-41.01,16.87-48.29,0-15.12,57.88-8.44,64.57,16.29,3.2,9.59-.87,31.69-.87,31.69,.87-1.45,2.04-2.03,3.2-.86,3.21,2.03-.29,11.34-1.16,14.25-.58,2.61-1.45,4.36-2.33,5.81l.29,.58c0,2.04-1.16,4.08-2.33,4.08-2.61,9.89-5.23,20.65-12.79,25.88h0Z" />
            <UsersStyleOne d="M30.25,132.9c3.19,3.78,7.86,5.81,13.09,6.39-23.27,15.41-36.94,14.54-41.02,54.1H194.28c-2.6-29.96-11.62-37.52-26.75-45.95-2.04-.87-15.13-7.57-15.41-8.15,6.12,.29,11.93-2.03,15.7-6.39-11.05,0-20.93-7.27-22.97-18.33-4.37-24.44-.89-38.4-2.63-61.95C139.89,24.71,125.06,1.72,96.27,2.3c-18.04,0-32.29,9.89-39.84,25.89-10.48,28.8,2.91,52.35-3.21,86.67-2.02,10.76-12.2,18.04-22.97,18.04h0Z" />
        </SvgIcon>
    )
}
