import React, { useContext } from 'react'
import { CssBaseline, Box } from '@mui/material'
import { AppContext } from '../context/AppContext'

import { InternAppBar } from './InternAppBar'
import { InternDrawer } from './InternDrawer'
import { InternSurface } from './InternSurface'
import { InternLayoutProps } from './InternLayout.types'

export function InternLayout({ routes }: InternLayoutProps) {
    const context = useContext(AppContext)

    const onSignOut = () => {
        context.removeSession()
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <InternAppBar
                email={context.session?.user?.email ?? ''}
                onSignOut={onSignOut}
            />
            <InternDrawer routes={routes} />
            <InternSurface routes={routes} />
        </Box>
    )
}
