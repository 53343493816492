import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from '@mui/material'
import EditIcon from '@mui/icons-material/BorderColorTwoTone'
import BlockIcon from '@mui/icons-material/RemoveCircleTwoTone'
import UnblockIcon from '@mui/icons-material/AddCircleTwoTone'
import ActivateIcon from '@mui/icons-material/BeenhereTwoTone'
import DeleteIcon from '@mui/icons-material/DeleteTwoTone'

import translate from '../i18n/Translator'
import { User } from '../model/User'
import { AppContext } from '../context/AppContext'

interface UserMenuProps {
    user: User
    tenantId?: string
    anchor: HTMLElement | null
    onClose(): any
    onActivated(): any
    onBlock(): any
    onUnblock(): any
    onDelete(): any
}

export default function UserMenu(props: UserMenuProps) {
    const context = useContext(AppContext)
    const itself = props.user.id === context.session?.user.id
    const isGrantedUpdate = useMemo(
        () => context.isGrantedAny(['UsersUpdate', 'TenantsUsersUpdate']),
        [context]
    )
    const isGrantedBlock = useMemo(
        () => context.isGrantedAny(['UsersBlock', 'TenantsUsersBlock']),
        [context]
    )
    const isGrantedDelete = useMemo(
        () => context.isGrantedAny(['UsersDelete', 'TenantsUsersDelete']),
        [context]
    )

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {itself && (
                <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={props.onClose}
                    dense
                >
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.edit')}
                    </Typography>
                </MenuItem>
            )}
            {!itself && isGrantedUpdate && (
                <MenuItem
                    component={Link}
                    to={`${
                        props.tenantId ? `/accounts/${props.tenantId}` : ''
                    }/users/${props.user.id}/edit`}
                    onClick={props.onClose}
                    dense
                >
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.edit')}
                    </Typography>
                </MenuItem>
            )}
            {!itself &&
                !props.user.blocked &&
                props.user.signup_code &&
                isGrantedUpdate && (
                    <MenuItem onClick={() => props.onActivated()} dense>
                        <ListItemIcon>
                            <ActivateIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography variant="inherit">
                            {translate('buttons.activate')}
                        </Typography>
                    </MenuItem>
                )}
            {!itself && !props.user.blocked && isGrantedBlock && (
                <MenuItem onClick={props.onBlock} dense>
                    <ListItemIcon>
                        <BlockIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.block')}
                    </Typography>
                </MenuItem>
            )}
            {!itself && props.user.blocked && isGrantedBlock && (
                <MenuItem onClick={props.onUnblock} dense>
                    <ListItemIcon>
                        <UnblockIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.unblock')}
                    </Typography>
                </MenuItem>
            )}
            {!itself && isGrantedDelete && (
                <MenuItem onClick={props.onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="error">
                        {translate('buttons.delete')}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    )
}
