import React, { useContext, useState } from 'react'
import { Link as RouterLink, Route, Switch, Redirect } from 'react-router-dom'
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Container,
    Collapse,
    styled,
} from '@mui/material'
import { ExpandLessIcon, ExpandMoreIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import { AppContext, AppContextData } from '../context/AppContext'
import { routes, RouteItem } from './Routes'

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}))

export const curateRoutes = (context: AppContextData): RouteItem[] => {
    let curated = routes.filter((route: RouteItem) => {
        return (
            (!route.grants || context.isGrantedAny(route.grants)) &&
            (!route.exclude || !context.isGrantedAny(route.exclude))
        )
    })

    curated
        .filter((route) => route.items && route.items.length > 0)
        .forEach((route) => {
            route.items = route.items!.filter((subroute: RouteItem) => {
                return (
                    (!subroute.grants ||
                        context.isGrantedAny(subroute.grants)) &&
                    (!subroute.exclude ||
                        !context.isGrantedAny(subroute.exclude))
                )
            })
        })

    return curated
}

export default function AppRouter() {
    const context = useContext(AppContext)
    let curated = curateRoutes(context)
    let mappings: RouteItem[] = []

    curated.forEach((route) => {
        if (route.path) {
            mappings.push(route)
        }

        route.items
            ?.filter((subroute) => !!subroute.path)
            .forEach((subroute) => {
                mappings.push(subroute)
            })
    })

    return (
        <Container maxWidth={false}>
            <Switch>
                {mappings.map((route, index) => (
                    <Route
                        key={index}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                ))}
                <Redirect from="/*" to="/users" />
            </Switch>
        </Container>
    )
}

interface RouteListProps {
    routes: RouteItem[]
    inner?: boolean
}

function RouteList(props: RouteListProps) {
    return (
        <List dense disablePadding>
            {props.routes
                .filter((route, _index) => !route.hidden)
                .map((route, index) => (
                    <RouteListItem
                        key={index}
                        route={route}
                        index={index}
                        inner={props.inner}
                    />
                ))}
        </List>
    )
}

interface RouteListItemProps {
    index: number
    route: RouteItem
    inner?: boolean
}

function RouteListItem(props: RouteListItemProps) {
    const [open, setOpen] = useState<boolean>(false)

    if (props.route.path) {
        return (
            <ListItem
                key={props.index}
                component={RouterLink}
                to={props.route.path}
                sx={props.inner ? { paddingLeft: 2 } : undefined}
            >
                <CustomListItemIcon>{props.route.icon}</CustomListItemIcon>
                <ListItemText
                    primary={translate(props.route.title) as string}
                    primaryTypographyProps={{
                        variant: 'subtitle1',
                        color: 'primary',
                        sx: { fontWeight: 'bold' },
                    }}
                />
            </ListItem>
        )
    }

    return (
        <div>
            <ListItem key={props.index} onClick={() => setOpen(!open)}>
                <CustomListItemIcon>{props.route.icon}</CustomListItemIcon>
                <ListItemText
                    primary={translate(props.route.title)}
                    primaryTypographyProps={{
                        variant: 'subtitle1',
                        color: 'primary',
                        sx: { fontWeight: 'bold' },
                    }}
                />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            {props.route.items && props.route.items.length > 0 && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <RouteList routes={props.route.items} inner />
                </Collapse>
            )}
        </div>
    )
}

export function AppMenu() {
    const context = useContext(AppContext)
    let curated = curateRoutes(context)

    return <RouteList routes={curated} />
}
