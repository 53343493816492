import React from 'react'
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material'
import { PopperMenuProps } from './PopperMenu.types'

export function PopperMenu({
    anchorRef,
    placement = 'bottom-end',
    onClose,
    children,
}: PopperMenuProps) {
    const handleClose = (event: MouseEvent | TouchEvent): void => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }
        onClose()
    }

    return (
        <Popper
            open
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={placement}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement,
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            {children}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}
