import React, { useContext, useState } from 'react'
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from '@mui/material'
import { SendPostcardIcon, ImportIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import { AppContext } from '../context/AppContext'
import { Guest } from '../model/Guest'

interface GuestsMenuProps {
    anchor: HTMLElement | null
    selected: Guest[]
    onImport(): any
    onSendPostcard(): any
    onClose(): any
}

export default function GuestsMenu({
    anchor,
    selected,
    onClose,
    onImport,
    onSendPostcard,
}: GuestsMenuProps) {
    const context = useContext(AppContext)
    const [isCreateGranted] = useState(
        context.isGrantedAny(['PGuestsRead', 'TGuestsRead'])
    )
    const [isPostcardsGranted] = useState(
        context.isGrantedAny(['PPostcardsRead', 'TPostcardsRead'])
    )

    return (
        <Menu
            id="guest-item"
            anchorEl={anchor}
            keepMounted
            onClose={onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem onClick={onImport} disabled={!isCreateGranted} dense>
                <ListItemIcon>
                    <ImportIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {translate('buttons.import')}
                </Typography>
            </MenuItem>
            {isPostcardsGranted && (
                <MenuItem
                    onClick={onSendPostcard}
                    disabled={selected.length === 0}
                    dense
                >
                    <ListItemIcon>
                        <SendPostcardIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.send_postcards')}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    )
}
