import { callAPI, HTTPMethod } from './API'
import {
    Tenants,
    Tenant,
    TenantListParams,
    TenantRequest,
} from '../model/Tenant'
import { SignUpMetadataResponse } from '../model/SignUp'

export async function listTenants(params: TenantListParams): Promise<Tenants> {
    return await callAPI('/tenants', {
        method: HTTPMethod.GET,
        query: {
            page: params?.page?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            search: params.search ?? '',
        },
    })
}

export async function createTenant(request: TenantRequest): Promise<Tenant> {
    return await callAPI('/tenants', {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function getMetadata(): Promise<SignUpMetadataResponse> {
    return await callAPI('/tenants/metadata', {
        method: HTTPMethod.GET,
    })
}

export async function getTenant(tenantId: string): Promise<Tenant> {
    return await callAPI(`/tenants/${tenantId}`, {
        method: HTTPMethod.GET,
    })
}

export async function updateTenant(
    tenantId: string,
    request: TenantRequest
): Promise<Tenant> {
    return await callAPI(`/tenants/${tenantId}`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}
