import React from 'react'
import {
    UsersIcon,
    ProfileIcon,
    SecurityIcon,
    ProjectsIcon,
    TenantsIcon,
    GuestsIcon,
    DesignsIcon,
    PostcardsIcon,
} from '../components/Icons'

import Users from '../users/Users'
import UserProfile from '../users/UserProfile'
import UserSecurity from '../users/UserSecurity'
import UserForm from '../users/UserForm'

import Projects from '../projects/Projects'
import ProjectForm from '../projects/ProjectForm'
import { ActionId } from '../model/Action'
import TenantForm from '../tenants/TenantForm'
import Tenants from '../tenants/Tenants'
import GuestForm from '../guests/GuestForm'
import Guests from '../guests/Guests'
import DesignForm from '../designs/DesignForm'
import Designs from '../designs/Designs'
import Postcards from '../postcards/Postcards'

export const routes = [
    {
        title: 'users.profile.title',
        path: '/profile',
        icon: <ProfileIcon />,
        component: UserProfile,
        hidden: true,
    },
    {
        title: 'users.security.title',
        path: '/security',
        icon: <SecurityIcon />,
        component: UserSecurity,
        hidden: true,
    },
    {
        title: 'users.title',
        path: '/accounts/:tenantId/users/new',
        icon: <UsersIcon />,
        component: UserForm,
        grants: ['UsersCreate'],
        hidden: true,
    },
    {
        title: 'users.title',
        path: '/accounts/:tenantId/users/:userId/edit',
        icon: <UsersIcon />,
        component: UserForm,
        grants: ['UsersUpdate'],
        hidden: true,
    },
    {
        title: 'users.title',
        path: '/accounts/:tenantId/users',
        icon: <UsersIcon />,
        component: Users,
        grants: ['UsersRead'],
        hidden: true,
    },
    {
        title: 'guests.title',
        path: '/accounts/:tenantId/guests/new',
        icon: <GuestsIcon />,
        component: GuestForm,
        grants: ['PGuestsCreate'],
        hidden: true,
    },
    {
        title: 'guests.title',
        path: '/accounts/:tenantId/guests/:guestId/edit',
        icon: <ProjectsIcon />,
        component: GuestForm,
        grants: ['PGuestsUpdate'],
        hidden: true,
    },
    {
        title: 'guests.title',
        path: '/accounts/:tenantId/guests',
        icon: <GuestsIcon />,
        component: Guests,
        grants: ['PGuestsRead'],
        hidden: true,
    },
    {
        title: 'designs.title',
        path: '/accounts/:tenantId/designs/new',
        icon: <DesignsIcon />,
        component: DesignForm,
        grants: ['PDesignsCreate'],
        hidden: true,
    },
    {
        title: 'designs.title',
        path: '/accounts/:tenantId/designs/:designId/edit',
        icon: <DesignsIcon />,
        component: DesignForm,
        grants: ['PDesignsUpdate'],
        hidden: true,
    },
    {
        title: 'designs.title',
        path: '/accounts/:tenantId/designs',
        icon: <DesignsIcon />,
        component: Designs,
        grants: ['PDesignsRead'],
        hidden: true,
    },
    {
        title: 'postcards.title',
        path: '/accounts/:tenantId/postcards',
        icon: <PostcardsIcon />,
        component: Postcards,
        grants: ['PPostcardsRead'],
        hidden: true,
    },
    {
        title: 'tenants.new_tenant',
        path: '/accounts/:tenantId/edit',
        icon: <TenantsIcon />,
        component: TenantForm,
        grants: ['TenantsUpdate'],
        hidden: true,
    },
    {
        title: 'tenants.new_tenant',
        path: '/accounts/new',
        icon: <TenantsIcon />,
        component: TenantForm,
        grants: ['TenantsCreate'],
        hidden: true,
    },
    {
        title: 'tenants.title',
        path: '/accounts',
        icon: <TenantsIcon />,
        component: Tenants,
        grants: ['TenantsRead'],
    },
    {
        title: 'users.title',
        path: '/users/new',
        icon: <UsersIcon />,
        component: UserForm,
        grants: ['UsersCreate', 'TenantsUsersCreate'],
        hidden: true,
    },
    {
        title: 'users.title',
        path: '/users/:userId/edit',
        icon: <UsersIcon />,
        component: UserForm,
        grants: ['UsersUpdate', 'TenantsUsersUpdate'],
        hidden: true,
    },
    {
        title: 'users.title',
        path: '/users',
        icon: <UsersIcon />,
        component: Users,
        grants: ['UsersRead', 'TenantsUsersRead'],
    },
    {
        title: 'projects.title',
        path: '/projects/new',
        icon: <ProjectsIcon />,
        component: ProjectForm,
        grants: ['ProjectsCreate'],
        hidden: true,
    },
    {
        title: 'projects.title',
        path: '/projects/:projectId/edit',
        icon: <ProjectsIcon />,
        component: ProjectForm,
        grants: ['ProjectsUpdate'],
        hidden: true,
    },
    {
        title: 'projects.title',
        path: '/projects',
        icon: <ProjectsIcon />,
        component: Projects,
        grants: ['ProjectsRead'],
    },
    {
        title: 'guests.title',
        path: '/guests/new',
        icon: <GuestsIcon />,
        component: GuestForm,
        grants: ['TGuestsCreate'],
        hidden: true,
    },
    {
        title: 'guests.title',
        path: '/guests/:guestId/edit',
        icon: <GuestsIcon />,
        component: GuestForm,
        grants: ['TGuestsUpdate'],
        hidden: true,
    },
    {
        title: 'guests.title',
        path: '/guests',
        icon: <GuestsIcon />,
        component: Guests,
        grants: ['TGuestsRead'],
    },
    {
        title: 'designs.title',
        path: '/designs/new',
        icon: <DesignsIcon />,
        component: DesignForm,
        grants: ['TDesignsCreate'],
        hidden: true,
    },
    {
        title: 'designs.title',
        path: '/designs/:designId/edit',
        icon: <DesignsIcon />,
        component: DesignForm,
        grants: ['TDesignsUpdate'],
        hidden: true,
    },
    {
        title: 'designs.title',
        path: '/designs',
        icon: <DesignsIcon />,
        component: Designs,
        grants: ['TDesignsRead'],
    },
    {
        title: 'postcards.title',
        path: '/postcards',
        icon: <PostcardsIcon />,
        component: Postcards,
        grants: ['TPostcardsRead'],
    },
] as RouteItem[]

export interface RouteItem {
    title: string
    path: string
    icon: React.ReactElement
    component?: React.FunctionComponent
    exact?: boolean
    grants?: ActionId[]
    exclude?: ActionId[]
    items?: RouteItem[]
    hidden?: boolean
}
