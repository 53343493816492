import { ListParams, ListResponse } from './Generic'
import { Tenant } from './Tenant'

export interface Guest {
    id: string
    first_name: string
    last_name: string
    name: string
    email?: string
    phone?: string
    arrival: Date
    departure: Date
    address: string
    line1?: string
    city: string
    state: string
    postal_code: string
    country: string
    status: GuestStatus
    created_at: Date
}

export interface Guests extends ListResponse<Guest> {
    tenant?: Tenant
}

export interface GuestsListParams extends ListParams {}

export interface GuestRequest {
    first_name: string
    last_name: string
    email?: string
    phone?: string
    arrival: Date
    departure: Date
    address: string
    line1?: string
    city: string
    state: string
    postal_code: string
    country: string
}

export interface GuestImportRequest {
    path: string
}

type GuestImportErrors = {
    [index: number]: string
}

export interface GuestImportResponse {
    read: number
    created: number
    errors: GuestImportErrors
}

export type GuestStatus = 'ACTIVE' | 'INACTIVE' | 'DELETED'

export const Christian = {
    first_name: 'Christian',
    last_name: 'Johnson',
    name: 'Christian Johnson',
    address: '5th Avenue 1351',
    line1: '',
    city: 'NYC',
    state: 'NY',
    postal_code: '12345',
    country: 'USA',
    departure: new Date(),
    arrival: new Date(),
    status: 'ACTIVE',
} as Guest
