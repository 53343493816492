import { callAPI, HTTPMethod } from './API'
import {
    Users,
    UserListParams,
    UserRequest,
    User,
    MetadataResponse,
} from '../model/User'

export async function getUsers(
    tenantId: string,
    params: UserListParams
): Promise<Users> {
    return await callAPI(`/tenants/${tenantId}/users`, {
        method: HTTPMethod.GET,
        query: {
            page: params?.page?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            search: params.search ?? '',
        },
    })
}

export async function getMetadata(tenantId: string): Promise<MetadataResponse> {
    return await callAPI(`/tenants/${tenantId}/users/metadata`, {
        method: HTTPMethod.GET,
    })
}

export async function createUser(
    tenantId: string,
    request: UserRequest
): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/users`, {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function getUser(
    tenantId: string,
    userId: string,
    includeMetadata?: boolean
): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}`, {
        method: HTTPMethod.GET,
        query: {
            include_metadata: `${!!includeMetadata}`,
        },
    })
}

export async function updateUser(
    tenantId: string,
    userId: string,
    request: UserRequest
): Promise<User> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function deleteUser(
    tenantId: string,
    userId: string
): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}`, {
        method: HTTPMethod.DELETE,
    })
}

export async function blockUser(
    tenantId: string,
    userId: string
): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}/block`, {
        method: HTTPMethod.PUT,
    })
}

export async function unblockUser(
    tenantId: string,
    userId: string
): Promise<void> {
    return await callAPI(`/tenants/${tenantId}/users/${userId}/block`, {
        method: HTTPMethod.DELETE,
    })
}
