import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import {
    Paper,
    Card,
    CardHeader,
    Avatar,
    Divider,
    CardContent,
    CardActions,
    IconButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { BackIcon } from '../components/Icons'

interface SurfaceProps {
    title: string | React.ReactNode
    subtitle?: string | React.ReactNode
    icon: React.ReactNode
    backButton?: boolean
    backTo?: string
    children: React.ReactNode
    titleActions?: React.ReactNode
    footerActions?: React.ReactNode
}

const CustomAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: 'transparent',
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
    },
}))

export default function Surface(props: SurfaceProps) {
    const history = useHistory()

    const avatar = () => {
        if (props.backButton) {
            if (props.backTo) {
                return (
                    <IconButton
                        size="medium"
                        component={Link}
                        to={props.backTo}
                    >
                        <BackIcon />
                    </IconButton>
                )
            }
            return (
                <IconButton size="medium" onClick={history.goBack}>
                    <BackIcon />
                </IconButton>
            )
        }
        return <CustomAvatar variant="circular">{props.icon}</CustomAvatar>
    }

    return (
        <Paper component={Card} variant="outlined">
            <CardHeader
                avatar={avatar()}
                title={props.title}
                subheader={props.subtitle}
                action={props.titleActions}
                titleTypographyProps={{
                    variant: 'h6',
                    color: 'primary',
                }}
            />
            <Divider />
            <CardContent>{props.children}</CardContent>
            {props.footerActions && (
                <CardActions>{props.footerActions}</CardActions>
            )}
        </Paper>
    )
}
