import React, { useState, useEffect, useContext } from 'react'
import { Grid, Button, Box } from '@mui/material'

import { getSignupConfirmation, confirmSignup } from '../api/AuthAPI'
import { SetupPasswordRequest } from '../model/SignUp'
import translate from '../i18n/Translator'
import { RouterParams } from '../router/RouterParams'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { ConfirmPasswordValidator } from '../components/Validators'
import { AppContext } from '../context/AppContext'
import Progress from '../components/Progress'
import GoHome from '../components/GoHome'
import ExternSurface from '../components/ExternSurface'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function ConfirmTenant({ match }: RouterParams) {
    const context = useContext(AppContext)
    const feedbackContext = useContext(FeedbackContext)
    const [status, setStatus] = useState<string>('loading')
    const [email, setEmail] = useState<string>()
    const [tenantName, setTenantName] = useState<string>()
    const [request, , validations, hasChanged] =
        useValidatedRequest<SetupPasswordRequest>()
    const confirmPasswordValidator = new ConfirmPasswordValidator(
        () => request.password
    )

    useEffect(() => {
        setStatus('loading')
        getSignupConfirmation(match.params.code)
            .then((response) => {
                setEmail(response.user.email)
                setTenantName(response.name)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [match.params.code])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        confirmSignup(match.params.code, request)
            .then((_) => {
                context.onSignedIn()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return <GoHome message={status} />
    }

    return (
        <ExternSurface
            title={translate('auth.confirm.title') as string}
            description={
                translate('auth.confirm.text', { tenant: tenantName }) as string
            }
        >
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <input type="hidden" id="email" name="email" value={email} />
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="password"
                            id="password"
                            name="password"
                            value={request.password}
                            label={translate('users.password') as string}
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            value={request.confirm_password}
                            label={
                                translate('users.confirm_password') as string
                            }
                            validator={confirmPasswordValidator}
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                            >
                                {translate('auth.confirm.button')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </ExternSurface>
    )
}
