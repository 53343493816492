import React from 'react'
import { Grid, Typography } from '@mui/material'
import translate from '../i18n/Translator'
import { ImageTarget } from '../model/Project'
import ImageTargetViewer from './ImageTargetViewer'
import { ProjectImageTargetsProps } from './ProjectImageTargets.types'

function ProjectImageTargets({
    imageTargets,
    onEdit,
    onDelete,
}: ProjectImageTargetsProps) {
    if (imageTargets.length === 0) {
        return (
            <Typography
                variant="caption"
                textAlign="center"
                component="h6"
                sx={{ py: 2 }}
            >
                {translate('projects.image_targets.empty')}
            </Typography>
        )
    }

    return (
        <Grid
            container
            justifyContent="flex-start"
            justifyItems="center"
            alignContent="center"
            alignItems="center"
            spacing={2}
            sx={{ py: 2 }}
        >
            {imageTargets.map((imageTarget: ImageTarget, index: number) => (
                <ImageTargetViewer
                    key={imageTarget.image}
                    imageTarget={imageTarget}
                    index={index}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            ))}
        </Grid>
    )
}

export default ProjectImageTargets
