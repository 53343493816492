import React, { useState, useContext, useEffect } from 'react'
import {
    Grid,
    Button,
    Link,
    Switch,
    FormGroup,
    FormControlLabel,
    FormLabel,
    FormControl,
    Box,
} from '@mui/material'
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom'

import { signup, getSignup } from '../api/AuthAPI'
import { SignUpRequest, SignUpMetadataResponse } from '../model/SignUp'
import { TenantIntentRequest } from '../model/TenantIntent'
import ValidatedInput from '../components/ValidatedInput'
import { InputRef } from '../components/Input.types'
import { AppContext } from '../context/AppContext'
import translate from '../i18n/Translator'
import { SignInRequest } from '../model/SignIn'
import ExternSurface from '../components/ExternSurface'
import { PhoneValidator, phoneFormat } from '../components/Validators'
import Progress from '../components/Progress'
import GoHome from '../components/GoHome'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function SignUpForm() {
    const history = useHistory()
    const context = useContext(AppContext)
    const feedbackContext = useContext(FeedbackContext)
    const query = new URLSearchParams(useLocation().search)
    const planIdParam = query.get('planId')

    const [status, setStatus] = useState<string>('loading')
    const [data, setData] = useState<SignUpMetadataResponse>()
    const [planIds, setPlanIds] = useState<string[]>([])
    const [planLabels, setPlanLabels] = useState<string[]>([])
    const phoneValidator = new PhoneValidator()
    const [account, setAccount] = useState<boolean>(false)
    const [format, setFormat] = useState('')

    const [request, setRequest] = useState<TenantIntentRequest>({
        tenant_name: '',
        plan_id: '',
        signup: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
        } as SignUpRequest,
        signin: {
            email: '',
            password: '',
        } as SignInRequest,
    } as TenantIntentRequest)
    const [validations, setValidations] = useState({} as any)
    const [signinValidations, setSigninValidations] = useState({} as any)
    const [signupValidations, setSignupValidations] = useState({} as any)

    useEffect(() => {
        setStatus('loading')
        getSignup()
            .then((response) => {
                setData(response)
                setPlanIds(response.plans.map((plan) => plan.id))
                setPlanLabels(response.plans.map((plan) => plan.name))

                if (planIdParam) {
                    const plan = response.plans.find(
                        (plan) => plan.id === planIdParam
                    )
                    if (plan) {
                        setRequest((req) => {
                            return { ...req, plan_id: plan.id }
                        })
                    }
                }
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [planIdParam])

    const hasChanged = (name: string, value: string, inputRef: InputRef) => {
        setRequest({ ...request, [name]: value })
        validations[name] = inputRef
        setValidations(validations)
    }

    const hasChangedPlan = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        hasChanged(name, value, inputRef)
    }

    const hasChangedSignin = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        setRequest({
            ...request,
            signin: { ...request.signin, [name]: value } as SignInRequest,
        })
        signinValidations[name] = inputRef
        setSigninValidations(signinValidations)
    }

    const hasChangedSignup = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        setRequest({
            ...request,
            signup: { ...request.signup, [name]: value } as SignUpRequest,
        })
        signupValidations[name] = inputRef
        setSignupValidations(signupValidations)
    }

    const hasChangedPhone = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        hasChangedSignup(name, value, inputRef)
        setFormat(phoneFormat(value))
    }

    const isValidSpecific = (items: any) => {
        var valid = true
        for (let field in items) {
            let ref = items[field]
            if (!ref.valid) {
                valid = false
                if (typeof ref.blurer === 'function') {
                    ref.blurer(true)
                }
            }
        }
        return valid
    }

    const isValidTenant = () => isValidSpecific(validations)

    const isValidUser = () =>
        isValidSpecific(account ? signinValidations : signupValidations)

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let validTenant = isValidTenant()
        let validUser = isValidUser()
        if (!validTenant || !validUser) {
            return
        }

        var data = {
            tenant_name: request.tenant_name,
            plan_id: request.plan_id,
        } as TenantIntentRequest

        if (account) {
            data.signin = request.signin
        } else {
            data.signup = request.signup
        }

        feedbackContext.showBackdrop()
        signup(data)
            .then((response) => {
                if (response.signin) {
                    switch (response.signin.status) {
                        case 'SIGNED_IN':
                            context.onSignedIn()
                            break
                        case 'REQUIRED_2FA':
                            history.push(
                                `/signin/${response.signin.signin_2fa_code!}`
                            )
                            break
                        default:
                            feedbackContext.showError(
                                translate(
                                    'auth.signup.wrong_response'
                                ) as string
                            )
                            break
                    }
                } else if (response.tenant_intent) {
                    history.push(`/signup/${response.tenant_intent.id}`)
                } else {
                    feedbackContext.showError(
                        translate('auth.signup.wrong_response') as string
                    )
                }
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const switchMode = () => {
        setAccount(!account)
    }

    if (status === 'loading' || !data) {
        return <Progress />
    }

    if (status !== 'loaded') {
        return <GoHome message={status} />
    }

    return (
        <ExternSurface title={translate('auth.signup.title') as string}>
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="tenant_name"
                            name="tenant_name"
                            value={request.tenant_name}
                            label={
                                translate('auth.signup.tenant_name') as string
                            }
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="text"
                            id="plan_id"
                            name="plan_id"
                            value={request.plan_id}
                            label={translate('auth.signup.plan_id') as string}
                            options={planIds}
                            optionLabels={planLabels}
                            required
                            onValueChanged={hasChangedPlan}
                        />
                    </Grid>
                </Grid>
                {account && (
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Box mt={3}>
                                <FormControl component="fieldset" fullWidth>
                                    <FormLabel
                                        color="primary"
                                        component="legend"
                                    >
                                        {translate('auth.signup.owner')}
                                    </FormLabel>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12}>
                                            <ValidatedInput
                                                type="email"
                                                id="signin.email"
                                                name="email"
                                                value={request.signin!.email}
                                                label={
                                                    translate(
                                                        'users.email'
                                                    ) as string
                                                }
                                                required={account}
                                                onValueChanged={
                                                    hasChangedSignin
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ValidatedInput
                                                type="password"
                                                id="signin.password"
                                                name="password"
                                                value={request.signin!.password}
                                                label={
                                                    translate(
                                                        'users.password'
                                                    ) as string
                                                }
                                                required={account}
                                                onValueChanged={
                                                    hasChangedSignin
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                )}
                {!account && (
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <Box mt={3}>
                                <FormControl component="fieldset" fullWidth>
                                    <FormLabel
                                        color="primary"
                                        component="legend"
                                    >
                                        {translate('auth.signup.owner')}
                                    </FormLabel>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12}>
                                            <ValidatedInput
                                                type="text"
                                                id="signup.first_name"
                                                name="first_name"
                                                value={
                                                    request.signup!.first_name
                                                }
                                                label={
                                                    translate(
                                                        'users.first_name'
                                                    ) as string
                                                }
                                                required={!account}
                                                onValueChanged={
                                                    hasChangedSignup
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ValidatedInput
                                                type="text"
                                                id="signup.last_name"
                                                name="last_name"
                                                value={
                                                    request.signup!.last_name
                                                }
                                                label={
                                                    translate(
                                                        'users.last_name'
                                                    ) as string
                                                }
                                                required={!account}
                                                onValueChanged={
                                                    hasChangedSignup
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ValidatedInput
                                                type="email"
                                                id="signup.email"
                                                name="email"
                                                value={request.signup!.email}
                                                label={
                                                    translate(
                                                        'users.email'
                                                    ) as string
                                                }
                                                required={!account}
                                                onValueChanged={
                                                    hasChangedSignup
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ValidatedInput
                                                type="text"
                                                id="signup.phone"
                                                name="phone"
                                                value={request.signup!.phone}
                                                label={
                                                    translate(
                                                        'users.phone'
                                                    ) as string
                                                }
                                                validator={phoneValidator}
                                                format={format}
                                                mask=" "
                                                onValueChanged={hasChangedPhone}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end">
                            <FormGroup row>
                                <FormControlLabel
                                    label={
                                        <small>
                                            {translate(
                                                'auth.signup.has_account'
                                            )}
                                        </small>
                                    }
                                    labelPlacement="start"
                                    control={
                                        <Switch
                                            checked={account}
                                            onChange={switchMode}
                                            value={account}
                                            color="primary"
                                        />
                                    }
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                            >
                                {translate('buttons.signup')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs="auto">
                        <Link component={RouterLink} to="/signin">
                            <small>{translate('buttons.signin')}</small>
                        </Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Link component={RouterLink} to="/recover-password">
                            <small>{translate('buttons.recover')}</small>
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </ExternSurface>
    )
}
