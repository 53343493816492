import React, { useState, useContext, useEffect } from 'react'
import { Grid } from '@mui/material'
import { PasswordIcon, TwoFALockIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import Surface from '../components/Surface'
import UserPasswordForm from './UserPasswordForm'
import User2FA from './User2FA'
import { AppContext } from '../context/AppContext'
import { User } from '../model/User'
import { me } from '../api/MeAPI'
import Progress from '../components/Progress'
import Error from '../components/Error'

export default function UserSecurity() {
    const context = useContext(AppContext)
    const [status, setStatus] = useState<'loading' | 'loaded' | any>('loading')
    const [user, setUser] = useState<User>()

    useEffect(() => {
        setStatus('loading')
        me()
            .then((user) => {
                if (context.session) {
                    context.session!.user = user
                }
                setUser(user)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
        // eslint-disable-next-line
    }, [context.session])

    const onUpdated = (user: User) => {
        if (context.session) {
            context.session!.user = user
        }
        setUser(user)
    }

    if (status === 'loading') {
        return <Progress />
    } else if (status === 'loaded') {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                alignContent="center"
            >
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface
                        title={translate('users.security.password') as string}
                        icon={<PasswordIcon />}
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            alignContent="center"
                        >
                            <Grid item xs={12}>
                                <UserPasswordForm />
                            </Grid>
                        </Grid>
                    </Surface>
                    <br></br>
                    <br></br>
                    <Surface
                        title={translate('users.security.2fa') as string}
                        icon={<TwoFALockIcon />}
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            alignContent="center"
                        >
                            <Grid item xs={12}>
                                <User2FA user={user!} onUpdated={onUpdated} />
                            </Grid>
                        </Grid>
                    </Surface>
                </Grid>
            </Grid>
        )
    } else {
        return <Error text={status} />
    }
}
