import React, { useState, useEffect, useCallback } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { DatePickerInputConfig } from './DatePickerInput.types'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import { enUS } from 'date-fns/locale'
import { RequiredValidator } from './Validators'
import { InputRef } from './Input.types'
import {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATETIME_FORMAT,
    parseDate,
    formatDate,
} from './DateFormat.types'

const REQUIRED_VALIDATOR = new RequiredValidator()

export function DatePickerInput(config: DatePickerInputConfig) {
    const {
        mode,
        name,
        value = '',
        format = mode === 'date'
            ? DEFAULT_DATE_FORMAT
            : DEFAULT_DATETIME_FORMAT,
        locale = enUS,
        required,
        onValueChanged,
    } = config

    const [blured, setBlured] = useState(false)
    const [date, setDate] = useState<Date | null>(null)
    const [validation, setValidation] = useState<InputRef>({
        valid: true,
        blurer: setBlured,
    } as InputRef)
    const [error, setError] = useState<string>('')

    const validate = useCallback(
        (targetValue: string): [InputRef, Date | null] => {
            let date: Date | null = null
            if (targetValue) {
                date = parseDate(targetValue, format)
            }

            const valid = !required || REQUIRED_VALIDATOR.isValid(targetValue)
            return [
                {
                    valid: valid,
                    blurer: setBlured,
                } as InputRef,
                date,
            ]
        },
        [required, format]
    )

    useEffect(() => {
        const [validation, date] = validate(value)

        setDate(date)
        setValidation(validation)
        setError(validation.valid ? '' : REQUIRED_VALIDATOR.getMessage())
    }, [validate, value])

    const onChange = (value: any) => {
        setDate(value)
        let formattedValue = ''
        if (value) {
            formattedValue = formatDate({
                date: value,
                pattern: format,
                locale: locale,
            })
        }

        const [validation] = validate(formattedValue)
        onValueChanged(name, formattedValue, validation)
    }

    const inputBlured = (_event: React.FocusEvent<HTMLInputElement>) => {
        if (!blured) {
            setBlured(true)
        }
    }

    const commonProperties = () => {
        return {
            label: config.label,
            value: date,
            onChange: onChange,
            disableFuture: config.disableFuture,
            disablePast: config.disablePast,
            disabled: config.disabled,
            minDate: config.minDate,
            maxDate: config.maxDate,
            inputFormat: format,
            disableMaskedInput: true,
            renderInput: (params: TextFieldProps) => (
                <TextField
                    {...params}
                    size={config.size ?? 'small'}
                    variant={config.variant ?? 'outlined'}
                    margin="dense"
                    error={blured && !validation.valid}
                    helperText={blured ? error : ''}
                    required={required}
                    onBlur={inputBlured}
                    fullWidth
                />
            ),
        }
    }

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={locale}
            dateFormats={{
                normalDate: format,
                keyboardDate: format,
            }}
        >
            {mode === 'date' && <DatePicker {...commonProperties()} />}
            {mode === 'datetime' && <DateTimePicker {...commonProperties()} />}
        </LocalizationProvider>
    )
}
