import React, { useState, useContext } from 'react'
import { Grid, Button, Box } from '@mui/material'

import translate from '../i18n/Translator'
import { updateEmail, revertEmail } from '../api/MeAPI'
import { User, EmailRequest } from '../model/User'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import SimpleSwitch from '../components/SimpleSwitch'
import { FeedbackContext } from '../feedback/FeedbackContext'

export interface UserEmailFormProps {
    user: User
    onUpdated(user: User): any
}

export default function UserEmailForm({ user, onUpdated }: UserEmailFormProps) {
    const feedbackContext = useContext(FeedbackContext)
    const [update, setUpdate] = useState(!!user.new_email)
    const [request, , validations, hasChanged, clearForm] = useValidatedRequest(
        {
            email: user.new_email || '',
        } as EmailRequest
    )

    const hasChangeSwitch = (_name: string, checked: boolean) => {
        setUpdate(checked)
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        updateEmail(request)
            .then((user) => {
                feedbackContext.showSuccess(
                    translate('users.profile.updating_email', {
                        email: request.email,
                    }) as string
                )
                onUpdated(user)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onCancel = () => {
        feedbackContext.showBackdrop()
        revertEmail()
            .then((user) => {
                feedbackContext.showSuccess(
                    translate('users.profile.reverted_email', {
                        email: user!.email,
                    }) as string
                )
                clearForm()
                setUpdate(false)
                onUpdated(user)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    return (
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="email"
                        id="old_email"
                        name="old_email"
                        label={translate('users.old_email') as string}
                        value={user.email}
                        disabled
                        required
                        onValueChanged={() => {}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                        <SimpleSwitch
                            value="update"
                            label={
                                translate(
                                    'users.profile.change_email'
                                ) as string
                            }
                            checked={update}
                            onChanged={hasChangeSwitch}
                            disabled={!!user.new_email}
                        />
                    </Grid>
                </Grid>
                {update && (
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    type="email"
                                    id="email"
                                    name="email"
                                    label={
                                        translate('users.new_email') as string
                                    }
                                    value={request.email}
                                    onValueChanged={hasChanged}
                                    disabled={user.new_email === request.email}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box my={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        disabled={
                                            user.new_email === request.email
                                        }
                                    >
                                        {translate('buttons.update')}
                                    </Button>
                                </Box>
                            </Grid>
                            {user.new_email === request.email && (
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        onClick={onCancel}
                                    >
                                        {translate('buttons.cancel')}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </form>
    )
}
