import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, Box, Typography } from '@mui/material'

import { AccountButton } from './AccountButton'
import { MenuButton } from './MenuButton'
import { InternAppBarProps } from './InternAppBar.types'
import Logo from '../components/Logo'
import translate from '../i18n/Translator'
import { AppContext } from '../context/AppContext'

const CustomAppBar = styled(AppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: `0px 2px 5px 1px ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
}))

export function InternAppBar({ email, onSignOut }: InternAppBarProps) {
    const context = useContext(AppContext)
    const tenantName = context.session?.tenant?.name

    return (
        <CustomAppBar position="fixed" color="inherit">
            <Toolbar variant="dense" disableGutters={false}>
                {/* Botón de menu */}
                <MenuButton />

                {/* Logo */}
                <Box sx={{ minWidth: { xs: '100px', md: '205px' }, pr: 1 }}>
                    <Logo text={translate('app.title') as string} />
                </Box>

                {/* Relleno */}
                <Box sx={{ flexGrow: 1 }}></Box>

                {tenantName && (
                    <Typography variant="h6" color="primary" sx={{ mr: 2 }}>
                        {tenantName}
                    </Typography>
                )}

                <AccountButton email={email} onSignOut={onSignOut} />
            </Toolbar>
        </CustomAppBar>
    )
}
