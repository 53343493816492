import React, { memo } from 'react'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import DesignPreviewGrid, { DesignPreviewGridProps } from './DesignPreviewGrid'

interface DesignPreviewPopupProps extends DesignPreviewGridProps {
    onClose(): any
}

function DesignPreviewPopup(props: DesignPreviewPopupProps) {
    return (
        <DialogPopup
            open
            title={translate('designs.preview', props.design)}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth
        >
            <DesignPreviewGrid {...props} />
        </DialogPopup>
    )
}

export default memo(DesignPreviewPopup)
