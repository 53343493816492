import { createContext } from 'react'

export interface FeedbackData {
    showInfo(message: string): any
    showSuccess(message: string): any
    showWarning(message: string): any
    showError(message: string): any
    showBackdrop(message?: string): any
    closeBackdrop(): any
}

export const FeedbackContext = createContext<FeedbackData>({
    showInfo: (message: string) => {},
    showSuccess: (message: string) => {},
    showWarning: (message: string) => {},
    showError: (message: string) => {},
    showBackdrop: (message?: string) => {},
    closeBackdrop: () => {},
})
