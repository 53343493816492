import { callAPI, HTTPMethod } from './API'
import {
    Designs,
    Design,
    DesignsListParams,
    DesignRequest,
    DesignPreview,
    DesignPreviewRequest,
} from '../model/Design'

const basePath = (tenantId: string) => `/tenants/${tenantId}/designs`

export async function listDesigns(
    tenantId: string,
    params: DesignsListParams
): Promise<Designs> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            page: params?.page?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            search: params.search ?? '',
        },
    })
}

export async function createDesign(
    tenantId: string,
    request: DesignRequest
): Promise<Design> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function getDesign(tenantId: string, id: string): Promise<Design> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.GET,
    })
}

export async function updateDesign(
    tenantId: string,
    id: string,
    request: DesignRequest
): Promise<Design> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function deleteDesign(tenantId: string, id: string): Promise<any> {
    return await callAPI(`${basePath(tenantId)}/${id}`, {
        method: HTTPMethod.DELETE,
    })
}

export async function previewDesign(
    tenantId: string,
    id: string,
    request: DesignPreviewRequest
): Promise<DesignPreview> {
    return await callAPI(`${basePath(tenantId)}/${id}/preview`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}
