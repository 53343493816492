import React, { useContext } from 'react'
import { Grid, Button, Box } from '@mui/material'

import translate from '../i18n/Translator'
import { updatePassword } from '../api/MeAPI'
import { PasswordRequest } from '../model/User'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { ConfirmPasswordValidator } from '../components/Validators'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function UserPasswordForm() {
    const feedbackContext = useContext(FeedbackContext)
    const [request, , validations, hasChanged, clearForm] =
        useValidatedRequest<PasswordRequest>()

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        updatePassword(request)
            .then((_response) => {
                feedbackContext.showSuccess(
                    translate('users.security.updated_password') as string
                )
                clearForm()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    return (
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="password"
                        id="old_password"
                        name="old_password"
                        label={translate('users.old_password') as string}
                        value={request.old_password}
                        onValueChanged={hasChanged}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="password"
                        id="password"
                        name="password"
                        label={translate('users.password') as string}
                        value={request.password}
                        onValueChanged={hasChanged}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        label={translate('users.confirm_password') as string}
                        value={request.confirm_password}
                        onValueChanged={hasChanged}
                        required
                        validator={
                            new ConfirmPasswordValidator(() => request.password)
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box my={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                        >
                            {translate('buttons.update')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
