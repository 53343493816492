import React from 'react'
import { Button, Divider, Grid, Typography } from '@mui/material'
import translate from '../i18n/Translator'
import DialogPopup from '../components/DialogPopup'
import { SplashScreenFormProps } from './SplashScreenForm.types'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { UploaderInput } from '../components/UploaderInput'
import { InputRef } from '../components/Input.types'

function SplashScreenForm({
    current,
    s3Client,
    onUpdated,
    onClose,
}: SplashScreenFormProps) {
    const [request, , validations, hasChanged, , hasChangedAny] =
        useValidatedRequest(current ? { ...current } : undefined)

    const hasChangedImage = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        hasChangedAny(
            inputRef.name,
            value ? s3Client.getUrl(value) : '',
            inputRef
        )
    }

    const onUpdate = () => {
        if (!isValid(validations)) return

        onUpdated(request)
    }

    return (
        <DialogPopup
            open
            title={translate('projects.splash.title')}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            onClose={onClose}
            button={
                <Button onClick={onUpdate} color="primary">
                    {translate('buttons.update')}
                </Button>
            }
        >
            <Grid container sx={{ mb: 1 }}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" gutterBottom>
                        {translate('projects.splash.logo') as string}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput
                        id="logo"
                        name="logo"
                        label={translate('projects.splash.image') as string}
                        s3Client={s3Client}
                        value={request.logo}
                        acceptExtension={'.jpg,.png'}
                        path="logos"
                        required
                        disabled={false}
                        acl="public-read"
                        onValueChanged={hasChangedImage}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="logo_size"
                        name="logo_size"
                        value={request.logo_size ?? ''}
                        label={translate('projects.splash.logo_size') as string}
                        disabled={!request.logo}
                        onValueChanged={hasChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="logo_position"
                        name="logo_position"
                        value={request.logo_position ?? ''}
                        label={
                            translate('projects.splash.logo_position') as string
                        }
                        disabled={!request.logo}
                        onValueChanged={hasChanged}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ pt: 2, mb: 1 }} />
                    <Typography variant="subtitle1" gutterBottom>
                        {translate('projects.splash.background') as string}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <UploaderInput
                        id="background"
                        name="background"
                        label={translate('projects.splash.image') as string}
                        s3Client={s3Client}
                        value={request.background}
                        acceptExtension={'.jpg,.png,.svg'}
                        path="splash-screens"
                        required
                        disabled={false}
                        acl="public-read"
                        onValueChanged={hasChangedImage}
                    />
                </Grid>
            </Grid>
        </DialogPopup>
    )
}

export default SplashScreenForm
