import React, {
    useState,
    useEffect,
    useContext,
    useMemo,
    useCallback,
} from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
    IconButton,
    Divider,
    Button,
    Fab,
    Box,
    Grid,
    Chip,
    Typography,
    Stack,
} from '@mui/material'
import { GuestsIcon, MoreVertIcon, AddIcon } from '../components/Icons'
import { listGuests, deleteGuest, importGuests } from '../api/GuestAPI'
import { AppContext } from '../context/AppContext'
import {
    Guest,
    GuestImportRequest,
    GuestImportResponse,
    Guests,
    GuestsListParams,
} from '../model/Guest'
import translate from '../i18n/Translator'
import Pagination, {
    initialPage,
    initialPageSize,
    getOffset,
} from '../components/Pagination'
import { Griddable } from '../components/Griddable'
import DialogPopup from '../components/DialogPopup'
import GuestMenu from './GuestMenu'
import { RouterParams } from '../router/RouterParams'
import { FeedbackContext } from '../feedback/FeedbackContext'
import SearchFilter, { useSearchParams } from '../components/SearchFilter'
import { DateFormat } from '../components/DateFormat'
import PostcardCreatePopup from '../postcards/PostcardCreatePopup'
import GuestsMenu from './GuestsMenu'
import { MONTH_DATE_FORMAT } from '../components/DateFormat.types'
import GuestImportPopup from './GuestImportPopup'
import GuestImportResultPopup from './GuestImportResultPopup'

type Popup = 'delete' | 'designs' | 'import' | 'import_result'

export default function GuestsList({ match }: RouterParams) {
    const feedbackContext = useContext(FeedbackContext)
    const context = useContext(AppContext)
    const paramTenantId = match.params.tenantId
    const tenantId = context.session?.tenant?.id || paramTenantId || '-'
    const isCreateGranted = useMemo(
        () => context.isGrantedAny(['PGuestsCreate', 'TGuestsCreate']),
        [context]
    )
    const isPostcardsGranted = useMemo(
        () => context.isGrantedAny(['PPostcardsCreate', 'TPostcardsCreate']),
        [context]
    )

    const query = new URLSearchParams(useLocation().search)
    const params = useSearchParams<GuestsListParams>(query)

    const [status, setStatus] = useState<string>('loading')
    const [data, setData] = useState<Guests>()

    const [guest, setGuest] = useState<Guest>()
    const [selected, setSelected] = useState<string[]>([])
    const [selectedGuests, setSelectedGuests] = useState<Guest[]>([])
    const [pickedGuests, setPickedGuests] = useState<Guest[]>([])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [gridAnchorEl, setGridAnchorEl] = useState<null | HTMLElement>(null)
    const [popup, setPopup] = useState<Popup>()
    const [page, setPage] = useState<number>(initialPage(query))
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query))
    const [importResult, setImportResult] = useState<GuestImportResponse>()

    const retrievePromise = useCallback(() => {
        const offset = getOffset(page, pageSize)
        return listGuests(tenantId, { ...params, page: pageSize, offset })
    }, [tenantId, page, pageSize, params])

    const load = () => {
        retrievePromise()
            .then((response) => {
                setData(response)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }

    useEffect(load, [retrievePromise])

    const onChangedPage = (page: number) => {
        setPage(page)
        setData(undefined)
    }

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page)
        setPageSize(pageSize)
        setData(undefined)
    }

    const onClickedOptions =
        (guest: Guest) => (event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setGuest(guest)
        }

    const onClickedOptionsGrid = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setGridAnchorEl(event.currentTarget)
    }

    const onClosePopup = () => {
        onCloseOption()
        setPopup(undefined)
        setImportResult(undefined)
    }

    const onDelete = () => {
        if (!guest) return

        setAnchorEl(null)
        setPopup('delete')
    }

    const onSendPostcard = () => {
        if (!guest) return

        setAnchorEl(null)
        setPickedGuests([guest])
        setPopup('designs')
    }

    const onSendPostcardToSelected = () => {
        if (selectedGuests.length === 0) return

        setGridAnchorEl(null)
        setPickedGuests(selectedGuests)
        setPopup('designs')
    }

    const onImport = () => {
        setGridAnchorEl(null)
        setPopup('import')
    }

    const onImportGuests = (request: GuestImportRequest) => {
        onClosePopup()

        feedbackContext.showBackdrop()
        importGuests(tenantId, request)
            .then((response) => {
                setPopup('import_result')
                setImportResult(response)
                load()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onConfirmDelete = () => {
        onClosePopup()
        if (!guest) return

        feedbackContext.showBackdrop()
        deleteGuest(tenantId, guest.id)
            .then(() => {
                feedbackContext.showSuccess(
                    translate('guests.delete.success', {
                        id: guest.name,
                    }) as string
                )
                setSelected(selected.filter((el) => guest.id !== el))
                setSelectedGuests(
                    selectedGuests.filter((el) => guest.id !== el.id)
                )
                load()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onCloseOption = () => {
        setAnchorEl(null)
        setGuest(undefined)
    }

    const onCloseGridOption = () => {
        setGridAnchorEl(null)
        setPickedGuests([])
    }

    const removeSelection = (guest: Guest) => () => {
        setSelected(selected.filter((el) => el !== guest.id))
        setSelectedGuests(selectedGuests.filter((el) => el.id !== guest.id))
    }

    return (
        <Pagination
            title={translate('guests.title')}
            subtitle={paramTenantId ? data?.tenant?.name : undefined}
            icon={<GuestsIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            backButton={!!paramTenantId}
            action={
                <Stack direction="row" spacing={1} alignContent="center">
                    {isCreateGranted && (
                        <Link
                            to={`${
                                paramTenantId
                                    ? `/accounts/${paramTenantId}`
                                    : ''
                            }/guests/new`}
                        >
                            <Fab
                                color="primary"
                                size="small"
                                title={translate('buttons.add') as string}
                            >
                                <AddIcon />
                            </Fab>
                        </Link>
                    )}
                    {isPostcardsGranted && (
                        <IconButton
                            color="default"
                            size="medium"
                            onClick={onClickedOptionsGrid}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    )}
                </Stack>
            }
        >
            <SearchFilter title={translate('guests.filter') as string} />
            {selectedGuests.length > 0 && (
                <>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs>
                            <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
                                {translate('guests.selected', {
                                    total: selectedGuests.length,
                                })}
                            </Typography>
                            <Grid container spacing={1}>
                                {selectedGuests.map((guest) => (
                                    <Grid key={guest.id} item xs="auto">
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            color="secondary"
                                            label={guest.name}
                                            onDelete={removeSelection(guest)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                onClick={onSendPostcardToSelected}
                                color="secondary"
                                variant="outlined"
                                disabled={selectedGuests.length === 0}
                            >
                                {translate('buttons.send_postcards')}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
            <Box mx={-2} mt={2}>
                <Divider />
                <Griddable
                    items={data ? data.items : []}
                    loading={status === 'loading'}
                    error={
                        status !== 'loading' && status !== 'loaded'
                            ? status
                            : undefined
                    }
                    empty={translate('guests.empty') as string}
                    getId={(guest) => guest.id}
                    selectable={{
                        selected: selected,
                        onChange(ids, items) {
                            setSelected(ids)
                            setSelectedGuests(items)
                        },
                    }}
                    columns={[
                        {
                            title: translate('guests.name') as string,
                            converter: (guest) => guest.name,
                            id: 'name',
                            xs: true,
                            sm: true,
                            md: true,
                            lg: true,
                            xl: true,
                        },
                        {
                            title: translate('guests.arrival') as string,
                            converter: (guest) => (
                                <DateFormat
                                    date={guest.arrival}
                                    pattern={MONTH_DATE_FORMAT}
                                />
                            ),
                            id: 'arrival',
                            xs: 3,
                            sm: 3,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: translate('guests.departure') as string,
                            converter: (guest) => (
                                <DateFormat
                                    date={guest.departure}
                                    pattern={MONTH_DATE_FORMAT}
                                />
                            ),
                            id: 'departure',
                            xs: false,
                            sm: 3,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: translate('guests.state') as string,
                            converter: (guest) => guest.state,
                            id: 'state',
                            xs: false,
                            sm: false,
                            md: false,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: translate('guests.country') as string,
                            converter: (guest) =>
                                translate(`countries.${guest.country}`),
                            id: 'country',
                            xs: false,
                            sm: false,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                        {
                            title: (
                                <IconButton
                                    size="small"
                                    style={{ visibility: 'hidden' }}
                                    disabled
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            converter: (guest) => (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onClickedOptions(guest)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            id: 'actions',
                            xs: 'auto',
                            sm: 'auto',
                            md: 'auto',
                            lg: 'auto',
                            xl: 'auto',
                        },
                    ]}
                />
            </Box>

            {guest && anchorEl && (
                <GuestMenu
                    tenantId={paramTenantId}
                    guest={guest}
                    anchor={anchorEl}
                    onDelete={onDelete}
                    onSendPostcard={onSendPostcard}
                    onClose={onCloseOption}
                />
            )}
            {gridAnchorEl && (
                <GuestsMenu
                    anchor={gridAnchorEl}
                    selected={selectedGuests}
                    onSendPostcard={onSendPostcardToSelected}
                    onImport={onImport}
                    onClose={onCloseGridOption}
                />
            )}
            {guest && popup === 'delete' && (
                <DialogPopup
                    open
                    title={translate('guests.delete.title')}
                    onClose={onClosePopup}
                    button={
                        <Button
                            onClick={onConfirmDelete}
                            variant="outlined"
                            color="error"
                        >
                            {translate('buttons.delete')}
                        </Button>
                    }
                >
                    {translate('guests.delete.text', { id: guest.name })}
                </DialogPopup>
            )}
            {pickedGuests.length > 0 && popup === 'designs' && (
                <PostcardCreatePopup
                    tenantId={tenantId}
                    guests={pickedGuests}
                    onClose={onClosePopup}
                />
            )}
            {popup === 'import' && (
                <GuestImportPopup
                    tenantId={tenantId}
                    onUpload={onImportGuests}
                    onClose={onClosePopup}
                />
            )}
            {importResult && popup === 'import_result' && (
                <GuestImportResultPopup
                    result={importResult}
                    onClose={onClosePopup}
                />
            )}
        </Pagination>
    )
}
