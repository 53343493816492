import React, { useState, useEffect, SyntheticEvent } from 'react'
import { CloseIcon } from './Icons'

import { Snackbar, IconButton } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'

interface Props {
    message?: string
    onClose?: () => void
}

interface GenericProps extends Props {
    variant: 'success' | 'info' | 'warning' | 'error'
    vertical?: 'top' | 'bottom'
    horizontal?: 'left' | 'center' | 'right'
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function GenericSnackbar(props: GenericProps) {
    const [open, setOpen] = useState(false)
    const { message, variant, onClose, horizontal, vertical } = props

    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        // if (reason === 'clickaway') return;
        if (typeof onClose === 'function') {
            onClose()
        }
        setOpen(false)
    }

    useEffect(() => {
        setOpen(!!message)
    }, [message])

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: vertical ?? 'bottom',
                horizontal: horizontal ?? 'right',
            }}
            action={
                <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={variant}
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export function ErrorSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="error"
            message={props.message}
            onClose={props.onClose}
        />
    )
}

export function SuccessSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="success"
            message={props.message}
            onClose={props.onClose}
        />
    )
}

export function WarningSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="warning"
            message={props.message}
            onClose={props.onClose}
        />
    )
}

export function InfoSnackbar(props: Props) {
    return (
        <GenericSnackbar
            variant="info"
            message={props.message}
            onClose={props.onClose}
            vertical="bottom"
            horizontal="left"
        />
    )
}
