import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    Fade,
    Divider,
} from '@mui/material'
import {
    UsersIcon,
    EditIcon,
    GuestsIcon,
    DesignsIcon,
    PostcardsIcon,
    PurchaseIcon,
} from '../components/Icons'

import translate from '../i18n/Translator'
import { Tenant } from '../model/Tenant'
import { AppContext } from '../context/AppContext'

interface TenantMenuProps {
    tenant: Tenant
    anchor: HTMLElement | null
    onPurchaseCredits(): any
    onClose(): any
}

export default function TenantMenu(props: TenantMenuProps) {
    const context = useContext(AppContext)
    const isGrantedCreate = useMemo(
        () => context.isGranted('TenantsCreate'),
        [context]
    )
    const isGrantedUpdate = useMemo(
        () => context.isGranted('TenantsUpdate'),
        [context]
    )
    const isGrantedUsersRead = useMemo(
        () => context.isGranted('UsersRead'),
        [context]
    )
    const isGrantedGuestsRead = useMemo(
        () => context.isGrantedAny(['PGuestsRead', 'TGuestsRead']),
        [context]
    )
    const isGrantedDesignsRead = useMemo(
        () => context.isGrantedAny(['PDesignsRead', 'TDesignsRead']),
        [context]
    )
    const isGrantedPostcardsRead = useMemo(
        () => context.isGrantedAny(['PPostcardsRead', 'TPostcardsRead']),
        [context]
    )

    return (
        <Menu
            id="tenants-item"
            anchorEl={props.anchor}
            keepMounted
            onClose={props.onClose}
            open
            TransitionComponent={Fade}
        >
            {isGrantedUpdate && (
                <MenuItem
                    component={Link}
                    to={`/accounts/${props.tenant.id}/edit`}
                    onClick={props.onClose}
                    dense
                >
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.edit')}
                    </Typography>
                </MenuItem>
            )}
            {isGrantedCreate && <Divider />}
            {isGrantedCreate && (
                <MenuItem onClick={props.onPurchaseCredits} dense>
                    <ListItemIcon>
                        <PurchaseIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('credits.purchase.title')}
                    </Typography>
                </MenuItem>
            )}
            {isGrantedCreate && <Divider />}
            {isGrantedUsersRead && (
                <MenuItem
                    component={Link}
                    to={`/accounts/${props.tenant.id}/users`}
                    onClick={props.onClose}
                    dense
                >
                    <ListItemIcon>
                        <UsersIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('users.title')}
                    </Typography>
                </MenuItem>
            )}
            {isGrantedGuestsRead && (
                <MenuItem
                    component={Link}
                    to={`/accounts/${props.tenant.id}/guests`}
                    onClick={props.onClose}
                    dense
                >
                    <ListItemIcon>
                        <GuestsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('guests.title')}
                    </Typography>
                </MenuItem>
            )}
            {isGrantedDesignsRead && (
                <MenuItem
                    component={Link}
                    to={`/accounts/${props.tenant.id}/designs`}
                    onClick={props.onClose}
                    dense
                >
                    <ListItemIcon>
                        <DesignsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('designs.title')}
                    </Typography>
                </MenuItem>
            )}
            {isGrantedPostcardsRead && (
                <MenuItem
                    component={Link}
                    to={`/accounts/${props.tenant.id}/postcards`}
                    onClick={props.onClose}
                    dense
                >
                    <ListItemIcon>
                        <PostcardsIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('postcards.title')}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    )
}
