import { format, parse } from 'date-fns'

interface DateFormatProps {
    date?: Date
    pattern?: string
    locale?: Locale
    zone?: boolean
}

// https://date-fns.org/v2.29.3/docs/format

const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy'

const DEFAULT_DATETIME_FORMAT = 'MM-dd-yyyy HH:mm:ss'

const HUMAN_DATE_FORMAT = 'P'

const HUMAN_DATETIME_FORMAT = 'P, kk:mm'

const MONTH_DATE_FORMAT = 'MMM dd, yyyy'

const parseDateTime = (
    input?: string,
    format: string = DEFAULT_DATETIME_FORMAT
): Date | null => {
    return parseDate(input, format)
}

const parseDate = (
    input?: string,
    format: string = DEFAULT_DATE_FORMAT
): Date | null => {
    if (!input) return null

    return parse(input, format, new Date())
}

const formatDateTime = ({
    date,
    pattern = DEFAULT_DATETIME_FORMAT,
    locale,
    zone,
}: DateFormatProps): string => {
    return formatDate({ date, pattern, locale, zone })
}

const formatDate = ({
    date,
    pattern = DEFAULT_DATE_FORMAT,
    locale,
    zone,
}: DateFormatProps) => {
    if (date) {
        let suffix = ''
        if (zone) {
            suffix = ` ${format(date, 'zzzz', { locale: locale })}`
        }

        return `${format(date, pattern, {
            locale: locale,
        })}${suffix}`
    }

    return ''
}

export type { DateFormatProps }

export {
    DEFAULT_DATE_FORMAT,
    DEFAULT_DATETIME_FORMAT,
    HUMAN_DATE_FORMAT,
    HUMAN_DATETIME_FORMAT,
    MONTH_DATE_FORMAT,
    parseDate,
    parseDateTime,
    formatDate,
    formatDateTime,
}
