export const generateMind = async (imageTargets) => {
    const exportedBuffer = await compileImages(imageTargets)
    return new Blob([exportedBuffer])
}

export const compileImages = async (images, progressMonitor) => {
    const { IMAGE: mindarImage } = window.MINDAR
    const compiler = new mindarImage.Compiler()
    const monitor = progressMonitor ?? console.log

    for (let image of images) {
        console.log(
            `image ${image.src} [w: ${image.clientWidth}, h: ${image.clientHeight}]`
        )
    }

    await compiler.compileImageTargets(images, monitor)
    console.log('Targets were compiled')

    const exportedBuffer = await compiler.exportData()
    console.log('Targets were exported')

    return exportedBuffer
}
