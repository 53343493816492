import { Button } from '@mui/material'
import React from 'react'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import { ImageTargetDeletePopupProps } from './ImageTargetDeletePopup.types'

function ImageTargetDeletePopup({
    onDelete,
    onClose,
}: ImageTargetDeletePopupProps) {
    return (
        <DialogPopup
            open
            title={translate('projects.image_targets.delete.title')}
            closeText={translate('buttons.cancel') as string}
            button={
                <Button onClick={onDelete} color="error">
                    {translate('buttons.delete')}
                </Button>
            }
            onClose={onClose}
        >
            {translate('projects.image_targets.delete.description')}
        </DialogPopup>
    )
}

export default ImageTargetDeletePopup
