import React, { memo, useCallback, useEffect, useState } from 'react'
import {
    TextField,
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    SelectChangeEvent,
} from '@mui/material'
import {
    InputValidator,
    RequiredValidator,
    EmailValidator,
    PhoneValidator,
    NumberValidator,
    PasswordValidator,
    URLValidator,
} from './Validators'
import { PatternFormat } from 'react-number-format'
import { ValidatedInputConfig } from './ValidatedInput.types'
import {
    InputRef,
    ValueTransformer,
    InputChangeEvent,
    EmptyEvent,
    RequestEvent,
    AnyChangeEvent,
} from './Input.types'

const REQUIRED_VALIDATOR = new RequiredValidator()
const EMAIL_VALIDATOR = new EmailValidator()
const PHONE_VALIDATOR = new PhoneValidator()
const NUMBER_VALIDATOR = new NumberValidator()
const PASSWORD_VALIDATOR = new PasswordValidator()
const URL_VALIDATOR = new URLValidator()

function ValidatedInput(config: ValidatedInputConfig) {
    const [blured, setBlured] = useState(false)
    const [validation, setValidation] = useState<InputRef>({
        name: config.name,
        value: config.value || '',
        valid: true,
        blured: false,
        blurer: setBlured,
        reset: () => {
            setValidation((v) => {
                return { ...v, value: '', valid: true, blured: false }
            })
        },
    } as InputRef)

    const size = config.size ?? 'small'

    const validate = useCallback(
        (targetValue: string) => {
            let validators: InputValidator[] = []
            if (config.required) {
                validators.push(REQUIRED_VALIDATOR)
            }
            if (config.validator) {
                validators.push(config.validator)
            }
            if (config.validators) {
                validators = [...validators, ...config.validators]
            }

            switch (config.type) {
                case 'email':
                    validators.push(EMAIL_VALIDATOR)
                    break
                case 'tel':
                    validators.push(PHONE_VALIDATOR)
                    break
                case 'password':
                    validators.push(PASSWORD_VALIDATOR)
                    break
                case 'number':
                    validators.push(NUMBER_VALIDATOR)
                    break
                case 'url':
                    validators.push(URL_VALIDATOR)
                    break
            }

            let valid = true
            let error: string | undefined
            for (let validator of validators) {
                if (!validator.isValid(targetValue)) {
                    valid = false
                    error = validator.getMessage()
                    break
                }
            }

            setValidation((cur) => {
                return {
                    ...cur,
                    value: targetValue,
                    valid: valid,
                    error: error,
                }
            })
        },
        [config]
    )

    const inputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        validate(event.target.value)
    }

    const selectChanged = (
        event: SelectChangeEvent<string>,
        _child: React.ReactNode
    ) => {
        const value = event.target.value
        if (value) {
            validate(value)
        } else {
            validate('')
        }
    }

    const inputBlured = (_event: React.FocusEvent<HTMLInputElement>) => {
        if (!blured) {
            setBlured(true)
        }
    }

    useEffect(() => {
        // se solicita una validación inicialmente
        validate(config.value || '')
        // eslint-disable-next-line
    }, [config.value, config.required])

    useEffect(() => {
        // se reporta cada cambio en la referencia
        config.onValueChanged(
            validation.name,
            validation.value as string,
            validation
        )
        // eslint-disable-next-line
    }, [validation])

    const commonProperties = () => {
        return {
            key: config.id,
            id: config.id,
            name: config.name,
            value: validation.value as string,
            label: config.label,
            fullWidth: true,
            required: config.required,
            error: blured && !validation.valid,
            helperText: blured ? validation.error : undefined,
            inputProps: {
                maxLength: config.maxLength,
            },
            onBlur: inputBlured,
            multiline: config.multiline,
            maxRows: config.maxRows,
            minRows: config.minRows,
            disabled: config.disabled,
            InputProps: {
                startAdornment: config.startAdornment,
                endAdornment: config.endAdornment,
            },
        }
    }

    const onMaskedInputChanged = (values: any) => {
        if (!values) return
        let fixed =
            values['value'] || values['floatValue'] || values['formattedValue']
        validate(fixed)
    }

    if (config.options) {
        return (
            <FormControl
                margin="dense"
                size={size}
                variant={config.variant ?? 'outlined'}
                fullWidth
                required={config.required}
                error={blured && !validation.valid}
            >
                <InputLabel htmlFor={config.id}>{config.label}</InputLabel>
                <Select
                    native
                    value={validation.value ? (validation.value as string) : ''}
                    label={config.label}
                    disabled={config.disabled}
                    onChange={selectChanged}
                    inputProps={{
                        name: config.name,
                        id: config.id,
                    }}
                    required={config.required}
                >
                    <option></option>
                    {config.options.map((value, index) => {
                        return (
                            <option key={value} value={value}>
                                {config.optionLabels
                                    ? config.optionLabels[index]
                                    : value}
                            </option>
                        )
                    })}
                </Select>
                {blured && <FormHelperText>{validation.error}</FormHelperText>}
            </FormControl>
        )
    }

    if (config.format) {
        return (
            <PatternFormat
                customInput={TextField}
                size={size}
                margin="dense"
                format={config.format}
                mask={config.mask || '_'}
                onValueChange={onMaskedInputChanged}
                {...commonProperties()}
            />
        )
    }

    return (
        <TextField
            type={config.type}
            variant={config.variant || 'outlined'}
            size={size}
            margin="dense"
            autoComplete={config.autocomplete}
            onChange={inputChanged}
            {...commonProperties()}
        />
    )
}

export const isValid = (validations: any, avoidBlur?: boolean) => {
    var valid = true
    for (let field in validations) {
        let ref = validations[field]
        if (!ref.valid) {
            if (!avoidBlur) {
                ref.blurer(true)
            }
            valid = false
        }
    }
    return valid
}

export function useValidatedRequest<R>(
    initial?: R,
    transformer?: ValueTransformer
): [R, RequestEvent<R>, any, InputChangeEvent, EmptyEvent, AnyChangeEvent] {
    const [request, setRequest] = useState(initial ?? ({} as R))
    const [validations, setValidations] = useState({} as any)

    const hasChangedAny = useCallback(
        (name: string, value: any, inputRef: InputRef) => {
            setRequest((request) => {
                return { ...request, [name]: value }
            })

            setValidations((validations: any) => {
                validations[inputRef.name] = inputRef
                return validations
            })
        },
        [setRequest, setValidations]
    )

    const hasChanged = useCallback(
        (name: string, value: string, inputRef: InputRef) => {
            const fixedValue = transformer ? transformer(name, value) : value
            hasChangedAny(name, fixedValue, inputRef)
        },
        [hasChangedAny, transformer]
    )

    const updateRequest = useCallback(
        (request: R) => {
            setRequest(request)
        },
        [setRequest]
    )

    const clear = () => {
        setRequest({} as R)

        for (const field in validations) {
            const ref = validations[field]
            ref.reset()
        }
    }

    return [
        request,
        updateRequest,
        validations,
        hasChanged,
        clear,
        hasChangedAny,
    ]
}

export default memo(ValidatedInput)
