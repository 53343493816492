import React, { useContext, useState } from 'react'
import { Typography, Menu, MenuItem, ListItemIcon, Fade } from '@mui/material'
import { DeleteIcon, VisibilityIcon } from '../components/Icons'

import translate from '../i18n/Translator'
import { Postcard } from '../model/Postcard'
import { AppContext } from '../context/AppContext'

interface PostcardMenuProps {
    tenantId?: string
    postcard: Postcard
    anchor: HTMLElement | null
    onClose(): any
    onShowImage(): any
    onDelete(): any
}

export default function PostcardMenu({
    postcard,
    anchor,
    onClose,
    onShowImage,
    onDelete,
}: PostcardMenuProps) {
    const context = useContext(AppContext)
    const [isDeleteGranted] = useState(
        context.isGrantedAny(['PPostcardsDelete', 'TPostcardsDelete'])
    )

    return (
        <Menu
            id="postcards-item"
            anchorEl={anchor}
            keepMounted
            onClose={onClose}
            open
            TransitionComponent={Fade}
        >
            <MenuItem
                onClick={onShowImage}
                dense
                disabled={!postcard.back_image}
            >
                <ListItemIcon>
                    <VisibilityIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {translate('buttons.view_postcard')}
                </Typography>
            </MenuItem>
            {isDeleteGranted && (
                <MenuItem onClick={onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.delete')}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    )
}
