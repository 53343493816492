import { callAPI, HTTPMethod } from './API'
import {
    Credit,
    CreditPurchaseRequest,
    Credits,
    CreditsListParams,
    CreditStatisticResponse,
} from '../model/Credit'

const basePath = (tenantId: string) => `/tenants/${tenantId}/credits`

export async function listCredits(
    tenantId: string,
    params: CreditsListParams
): Promise<Credits> {
    return await callAPI(`${basePath(tenantId)}`, {
        method: HTTPMethod.GET,
        query: {
            page: params?.page?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            search: params.search ?? '',
        },
    })
}

export async function purchaseCredits(
    tenantId: string,
    request: CreditPurchaseRequest
): Promise<Credit> {
    return await callAPI(`${basePath(tenantId)}/purchase`, {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function getStatistics(
    tenantId: string
): Promise<CreditStatisticResponse> {
    return await callAPI(`${basePath(tenantId)}/statistics`, {
        method: HTTPMethod.GET,
    })
}
