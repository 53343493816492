import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useLocation, Link } from 'react-router-dom'

import { IconButton, Divider, Fab, Box } from '@mui/material'
import { TenantsIcon, MoreVertIcon, AddIcon } from '../components/Icons'

import Pagination, {
    initialPage,
    initialPageSize,
    getOffset,
} from '../components/Pagination'
import { listTenants } from '../api/TenantAPI'
import { Tenant, Tenants } from '../model/Tenant'
import { AppContext } from '../context/AppContext'
import translate from '../i18n/Translator'
import { formatDate } from '../components/DateFormat.types'
import TenantMenu from './TenantMenu'
import { Griddable } from '../components/Griddable'
import TenantsFilter, { useTenantsParams } from './TenantsFilter'
import CreditPurchaseForm from '../credits/CreditPurchaseForm'

export default function TenantsList() {
    const context = useContext(AppContext)
    const query = new URLSearchParams(useLocation().search)
    const params = useTenantsParams(query)
    const isGrantedCreated = useMemo(
        () => context.isGrantedAny(['TenantsCreate']),
        [context]
    )

    const [status, setStatus] = useState<string>('loading')
    const [data, setData] = useState<Tenants>()

    const [tenant, setTenant] = useState<Tenant>()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const [page, setPage] = useState<number>(initialPage(query))
    const [pageSize, setPageSize] = useState<number>(initialPageSize(query))
    const [popup, setPopup] = useState(false)

    useEffect(() => {
        setStatus('loading')
        const offset = getOffset(page, pageSize)
        const fixedParams = { ...params, offset, page: pageSize }
        listTenants(fixedParams)
            .then((response) => {
                setData(response)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [page, pageSize, params])

    const onChangedPage = (page: number) => {
        setPage(page)
        setData(undefined)
    }

    const onChangedPageSize = (page: number, pageSize: number) => {
        setPage(page)
        setPageSize(pageSize)
        setData(undefined)
    }

    const onClickedOptions =
        (tenant: Tenant) => (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget)
            setTenant(tenant)
        }

    const onPurchaseCredits = () => {
        setAnchorEl(null)
        setPopup(true)
    }

    const onCloseOption = () => {
        setPopup(false)
        setAnchorEl(null)
        setTenant(undefined)
    }

    return (
        <Pagination
            title={translate('tenants')}
            icon={<TenantsIcon />}
            page={page}
            pageSize={pageSize}
            count={data ? data.items.length : 0}
            total={data ? data.total : 0}
            onChangedPage={onChangedPage}
            onChangedPageSize={onChangedPageSize}
            action={
                isGrantedCreated ? (
                    <Link to="/accounts/new">
                        <Fab
                            color="primary"
                            size="medium"
                            title={translate('buttons.add') as string}
                        >
                            <AddIcon />
                        </Fab>
                    </Link>
                ) : undefined
            }
        >
            <TenantsFilter />
            <Box mx={-2} mt={2}>
                <Divider />
                <Griddable
                    items={data ? data.items : []}
                    loading={status === 'loading'}
                    error={
                        status !== 'loading' && status !== 'loaded'
                            ? status
                            : undefined
                    }
                    empty={translate('tenants.empty') as string}
                    getId={(tenant) => tenant.id}
                    columns={[
                        {
                            title: translate('tenants.name') as string,
                            converter: (tenant) => tenant.name,
                            xs: true,
                        },
                        {
                            title: translate('tenants.active.title') as string,
                            converter: (tenant) =>
                                translate(`tenants.active.${tenant.active}`),
                            xs: false,
                            sm: 3,
                            lg: 3,
                            xl: 2,
                        },
                        {
                            title: translate('tenants.created_at') as string,
                            converter: (tenant) =>
                                formatDate({ date: tenant.created_at }),
                            md: false,
                            lg: 2,
                        },
                        {
                            title: (
                                <IconButton
                                    size="small"
                                    style={{ visibility: 'hidden' }}
                                    disabled
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            converter: (tenant) => (
                                <IconButton
                                    aria-label="options"
                                    color="default"
                                    size="small"
                                    onClick={onClickedOptions(tenant)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            ),
                            xs: 'auto',
                        },
                    ]}
                />
            </Box>
            {tenant && anchorEl && (
                <TenantMenu
                    tenant={tenant}
                    anchor={anchorEl}
                    onPurchaseCredits={onPurchaseCredits}
                    onClose={onCloseOption}
                />
            )}
            {popup && tenant && (
                <CreditPurchaseForm tenant={tenant} onClose={onCloseOption} />
            )}
        </Pagination>
    )
}
