import React, { useState, useRef } from 'react'
import { IconButton, Avatar } from '@mui/material'
import Gravatar from 'react-gravatar'
import { AccountMenu } from './AccountMenu'
import { AccountButtonProps } from './AccountButton.types'

export function AccountButton({ email, onSignOut }: AccountButtonProps) {
    const avatarSize = 24
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    return (
        <>
            <IconButton
                ref={anchorRef}
                aria-haspopup="true"
                edge="end"
                color="inherit"
                size="small"
                onClick={() => setOpen(!open)}
            >
                <Avatar
                    sx={{
                        width: avatarSize,
                        height: avatarSize,
                    }}
                >
                    <Gravatar
                        email={email}
                        size={avatarSize}
                        default="wavatar"
                    />
                </Avatar>
            </IconButton>
            {open && (
                <AccountMenu
                    anchorRef={anchorRef}
                    onClose={() => setOpen(!open)}
                    onSignOut={onSignOut}
                />
            )}
        </>
    )
}
