import React, { useState, useContext, useEffect } from 'react'
import { Grid, Button, Box } from '@mui/material'

import translate from '../i18n/Translator'
import { updateBasicProfile } from '../api/MeAPI'
import { User, BasicProfileRequest } from '../model/User'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { PhoneValidator, phoneFormat } from '../components/Validators'
import { FeedbackContext } from '../feedback/FeedbackContext'

export interface UserBasicProfileFormProps {
    user: User
    onUpdated(user: User): any
}

export default function UserBasicProfileForm({
    user,
    onUpdated,
}: UserBasicProfileFormProps) {
    const phoneValidator = new PhoneValidator()
    const feedbackContext = useContext(FeedbackContext)
    const [format, setFormat] = useState('')
    const [request, , validations, hasChanged] = useValidatedRequest({
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
    } as BasicProfileRequest)

    useEffect(() => {
        setFormat(phoneFormat(request.phone))
    }, [request.phone])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        updateBasicProfile(request)
            .then((user) => {
                feedbackContext.showSuccess(
                    translate('users.profile.updated_basic') as string
                )
                onUpdated(user)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    return (
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
            <Grid container>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="first_name"
                        name="first_name"
                        label={translate('users.first_name') as string}
                        value={request.first_name}
                        onValueChanged={hasChanged}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="last_name"
                        name="last_name"
                        label={translate('users.last_name') as string}
                        value={request.last_name}
                        onValueChanged={hasChanged}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <ValidatedInput
                        type="text"
                        id="phone"
                        name="phone"
                        label={translate('users.phone') as string}
                        value={request.phone}
                        onValueChanged={hasChanged}
                        validator={phoneValidator}
                        format={format}
                        mask=" "
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box my={2}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                        >
                            {translate('buttons.update')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    )
}
