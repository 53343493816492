import React, { useContext } from 'react'
import { Box, Grid } from '@mui/material'
import { InternSurfaceProps } from './InternSurface.types'
import { LayoutContext } from './LayoutContext'
import AppRouter from '../router/AppRouter'

export function InternSurface({ routes }: InternSurfaceProps) {
    const layoutContext = useContext(LayoutContext)

    return (
        <Box
            component="main"
            sx={(theme) => {
                const props = {
                    flexGrow: 1,
                    marginTop: theme.spacing(8),
                    marginBottom: theme.spacing(4),
                    maxWidth: `calc(100vw - ${layoutContext.drawer.width}px)`,
                }
                if (layoutContext.mobile) {
                    const transition = theme.transitions.create('margin', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    })
                    return { ...props, marginLeft: 0, transition: transition }
                }

                const transition = theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                })

                return {
                    ...props,
                    marginLeft: `${layoutContext.drawer.width}px`,
                    transition: transition,
                }
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <AppRouter />
                </Grid>
            </Grid>
        </Box>
    )
}
