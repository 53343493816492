import React, { useState, useContext, useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

import { getSession } from '../api/MeAPI'
import { logout } from '../api/AuthAPI'
import ExternApp from './ExternApp'
import { AppContext } from './AppContext'
import { FeedbackContext } from '../feedback/FeedbackContext'
import { LayoutProvider } from '../layout/LayoutProvider'
import { InternLayout } from '../layout/InternLayout'
import { RouteItem } from '../router/Routes'

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
}))

export interface SessionHolderProps {
    routes: RouteItem[]
}

export default function SessionHolder(props: SessionHolderProps) {
    const context = useContext(AppContext)
    const feedbackContext = useContext(FeedbackContext)

    const [status, setStatus] = useState<
        'unknown' | 'out' | 'in' | 'switching'
    >('unknown')
    const [completed, setCompleted] = useState<number>(0)

    context.removeSession = () => {
        setStatus('switching')
        feedbackContext.showBackdrop()
        logout()
            .then(() => {
                setStatus('out')
                context.setSession(undefined)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
                setStatus('in')
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    context.onSignedIn = () => {
        setCompleted(new Date().getTime())
    }

    useEffect(() => {
        getSession()
            .then((session) => {
                context.setSession(session)
                setStatus('in')
            })
            .catch((_) => {
                context.setSession(undefined)
                setStatus('out')
            })
        // eslint-disable-next-line
    }, [completed])

    if (status === 'unknown') {
        return (
            <Container>
                <CircularProgress
                    sx={(theme) => {
                        return { margin: theme.spacing(4) }
                    }}
                />
            </Container>
        )
    }
    if (status === 'out') {
        return <ExternApp />
    }

    return (
        <LayoutProvider>
            <InternLayout routes={props.routes} />
        </LayoutProvider>
    )
}
