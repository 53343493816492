import React, { ReactNode, useState } from 'react'
import { FeedbackContext, FeedbackData } from './FeedbackContext'
import {
    InfoSnackbar,
    SuccessSnackbar,
    WarningSnackbar,
    ErrorSnackbar,
} from '../components/Snackbars'
import CustomBackdrop from '../components/CustomBackdrop'

interface FeedbackProviderProps {
    children: ReactNode
}

function FeedbackProvider({ children }: FeedbackProviderProps) {
    const [info, setInfo] = useState<string>()
    const [success, setSuccess] = useState<string>()
    const [warning, setWarning] = useState<string>()
    const [error, setError] = useState<string>()
    const [backdrop, setBackdrop] = useState(false)
    const [backdropText, setBackdropText] = useState<string>()

    const [value] = useState<FeedbackData>({
        showInfo: setInfo,
        showSuccess: setSuccess,
        showWarning: setWarning,
        showError: setError,
        showBackdrop: (message?: string) => {
            setBackdrop(true)
            setBackdropText(message)
        },
        closeBackdrop: () => {
            setBackdrop(false)
            setBackdropText(undefined)
        },
    })

    return (
        <FeedbackContext.Provider value={value}>
            {children}
            <InfoSnackbar message={info} onClose={() => setInfo(undefined)} />
            <SuccessSnackbar
                message={success}
                onClose={() => setSuccess(undefined)}
            />
            <WarningSnackbar
                message={warning}
                onClose={() => setWarning(undefined)}
            />
            <ErrorSnackbar
                message={error}
                onClose={() => setError(undefined)}
            />
            <CustomBackdrop open={backdrop} message={backdropText} />
        </FeedbackContext.Provider>
    )
}

export default FeedbackProvider
