import React, { useState, useEffect, useRef } from 'react'
import {
    InputAdornment,
    IconButton,
    TextField,
    Grid,
    Tooltip,
} from '@mui/material'
import { AttachIcon } from '../components/Icons'
import { InputValidator, RequiredValidator } from './Validators'
import translate from '../i18n/Translator'
import Progress from './Progress'
import { UploaderInputConfig } from './UploaderInput.types'
import { InputRef } from './Input.types'

const REQUIRED_VALIDATOR = new RequiredValidator()

export function UploaderInput(config: UploaderInputConfig) {
    const { s3Client } = config
    const [blured, setBlured] = useState(false)
    const [validation, setValidation] = useState<InputRef>({
        name: config.name,
        valid: true,
        blurer: setBlured,
    } as InputRef)
    const [error, setError] = useState<string>('')
    const [value, setValue] = useState<string>(config.value || '')

    const [uploading, setUploading] = useState(false)
    const fileInput = useRef<HTMLInputElement>(null)

    const inputBlured = (_event: React.FocusEvent<HTMLInputElement>) => {
        if (!blured) {
            setBlured(true)
        }
    }

    useEffect(() => {
        validate(config.value || '')
        // eslint-disable-next-line
    }, [config.required, config.disabled, config.value])

    const openFile = () => {
        fileInput.current!.click()
    }

    const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return
        }

        const file = event.target.files[0]
        const newFileName = s3Client.fixKey(
            config.path || config.id,
            file,
            config.randomPrefix ?? true
        )
        const contentType = config.contentType || file.type

        setUploading(true)
        s3Client
            .upload(newFileName, file, config.acl, contentType)
            .then((data: any) => {
                validate(data.location ?? newFileName)
            })
            .catch((err: any) => {
                setError(err.message)
            })
            .finally(() => {
                setUploading(false)
                fileInput.current!.value = ''
            })
    }

    const validate = (targetValue: string) => {
        var inptValue = ''
        if (targetValue) {
            let values = targetValue.split('/')
            inptValue = values[values.length - 1]
        }
        setValue(inptValue)

        let validators: InputValidator[] = []
        if (config.required) {
            validators.push(REQUIRED_VALIDATOR)
        }

        var valid = true,
            message = ''
        for (let validator of validators) {
            if (!validator.isValid(targetValue)) {
                valid = false
                message = validator.getMessage()
                break
            }
        }

        validation.valid = valid
        setValidation(validation)
        setError(message)

        if (typeof config.onValueChanged === 'function') {
            config.onValueChanged(validation.name, targetValue, validation)
        }
    }

    return (
        <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={0}
        >
            <Grid item xs={12}>
                <TextField
                    id={config.id}
                    value={value}
                    label={config.label}
                    variant={config.variant || 'outlined'}
                    margin="dense"
                    size={config.size ?? 'small'}
                    required={config.required}
                    disabled={config.disabled || config.readOnly}
                    error={blured && !validation.valid}
                    helperText={blured ? error : ''}
                    onClick={openFile}
                    fullWidth
                    onChange={handleUpload}
                    InputProps={{
                        readOnly: config.readOnly || false,
                        startAdornment: config.startAdornment,
                        endAdornment:
                            config.endAdornment ??
                            (config.readOnly ? undefined : (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        size="medium"
                                        disabled={uploading}
                                    >
                                        {uploading && <Progress />}
                                        {!uploading && config.tooltip && (
                                            <Tooltip title={config.tooltip}>
                                                <AttachIcon fontSize="medium" />
                                            </Tooltip>
                                        )}
                                        {!uploading && !config.tooltip && (
                                            <AttachIcon fontSize="medium" />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            )),
                    }}
                />
                <input
                    type="file"
                    disabled={config.disabled || config.readOnly}
                    onChange={handleUpload}
                    ref={fileInput}
                    accept={config.acceptExtension}
                    onBlur={inputBlured}
                    required={config.required}
                    style={{ display: 'none' }}
                />
            </Grid>
            {config.value && (
                <Grid item xs="auto">
                    <a
                        href={config.value}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <small>{translate('buttons.view_document')}</small>
                    </a>
                </Grid>
            )}
        </Grid>
    )
}
