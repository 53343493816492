import React from 'react'
import {
    Grid,
    Typography,
    styled,
    Card,
    List,
    ListItem,
    ListItemText,
} from '@mui/material'
import translate from '../i18n/Translator'
import { ProjectSplashScreenProps } from './ProjectSplashScreen.types'

const Image = styled('img')(({ theme }) => ({
    maxWidth: '100%',
}))

const Background = styled(Grid)(({ theme }) => ({
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: theme.spacing(4),
}))

function ProjectSplashScreen({ splash }: ProjectSplashScreenProps) {
    if (!splash) {
        return (
            <Typography
                variant="caption"
                textAlign="center"
                component="h6"
                sx={{ py: 2 }}
            >
                {translate('projects.splash.empty')}
            </Typography>
        )
    }

    return (
        <Grid
            container
            justifyContent="flex-start"
            justifyItems="center"
            alignContent="center"
            alignItems="center"
            spacing={2}
            sx={{ py: 2 }}
        >
            <Grid item xs={12} md={6}>
                <Card>
                    <Grid container>
                        <Background
                            item
                            xs={12}
                            sx={
                                splash.background
                                    ? {
                                          backgroundImage: `url('${splash.background}')`,
                                      }
                                    : undefined
                            }
                        >
                            <Image
                                src={
                                    splash.logo ||
                                    '/image-target-placeholder.jpg'
                                }
                                alt="logo"
                            />
                        </Background>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <List dense>
                    <ListItem>
                        <ListItemText
                            primary={splash.logo_size || '---'}
                            secondary={
                                translate('projects.splash.logo_size') as string
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={splash.logo_position || '---'}
                            secondary={
                                translate(
                                    'projects.splash.logo_position'
                                ) as string
                            }
                        />
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}

export default ProjectSplashScreen
