import { callAPI, HTTPMethod } from './API'
import {
    Users,
    UserListParams,
    UserRequest,
    User,
    MetadataResponse,
} from '../model/User'

export async function listUsers(params: UserListParams): Promise<Users> {
    return await callAPI('/users', {
        method: HTTPMethod.GET,
        query: {
            page: params?.page?.toString() ?? '',
            offset: params?.offset?.toString() ?? '',
            search: params.search ?? '',
        },
    })
}

export async function getMetadata(): Promise<MetadataResponse> {
    return await callAPI('/users/metadata', {
        method: HTTPMethod.GET,
    })
}

export async function createUser(request: UserRequest): Promise<User> {
    return await callAPI('/users', {
        method: HTTPMethod.POST,
        body: request,
    })
}

export async function getUser(
    userId: string,
    includeMetadata?: boolean
): Promise<User> {
    return await callAPI(`/users/${userId}`, {
        method: HTTPMethod.GET,
        query: {
            include_metadata: `${!!includeMetadata}`,
        },
    })
}

export async function updateUser(
    userId: string,
    request: UserRequest
): Promise<User> {
    return await callAPI(`/users/${userId}`, {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function deleteUser(userId: string): Promise<void> {
    return await callAPI(`/users/${userId}`, {
        method: HTTPMethod.DELETE,
    })
}

export async function blockUser(userId: string): Promise<void> {
    return await callAPI(`/users/${userId}/block`, {
        method: HTTPMethod.PUT,
    })
}

export async function unblockUser(userId: string): Promise<void> {
    return await callAPI(`/users/${userId}/block`, {
        method: HTTPMethod.DELETE,
    })
}
