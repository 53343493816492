import React from 'react'
import { Session } from '../model/Session'
import { ActionId } from '../model/Action'

export const AppContext = React.createContext<AppContextData>({
    session: undefined,
    setSession: (session: Session) => {},
    removeSession: () => {},
    onSignedIn: () => {},
    onChangedTenant: (tenantId: string) => {},
    isGranted: (action: string) => {
        return false
    },
    isGrantedAny: (actions: string[]) => {
        return false
    },
})

export interface AppContextData {
    session?: Session
    setSession(session?: Session): void
    removeSession(): void
    onSignedIn(): void
    onChangedTenant(tenantId: string): void
    isGranted(action: ActionId): boolean
    isGrantedAny(actions: ActionId[]): boolean
}
