import React, { useState, useEffect, useContext, useCallback } from 'react'
import { Grid, Button, Box } from '@mui/material'

import {
    getSetupPassword,
    getSignupUser,
    setupPassword,
    signupUser,
} from '../api/AuthAPI'
import { SetupPasswordRequest } from '../model/SignUp'
import { User } from '../model/User'
import translate from '../i18n/Translator'
import { RouterParams } from '../router/RouterParams'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { ConfirmPasswordValidator } from '../components/Validators'
import { AppContext } from '../context/AppContext'
import Progress from '../components/Progress'
import GoHome from '../components/GoHome'
import ExternSurface from '../components/ExternSurface'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function SetupPassword({ match }: RouterParams) {
    const context = useContext(AppContext)
    const feedbackContext = useContext(FeedbackContext)
    const isSignup = window.location.pathname.startsWith('/signup')
    const code = match.params.code

    const [status, setStatus] = useState<string>('loading')
    const [email, setEmail] = useState<string>()
    const [request, , validations, hasChanged] =
        useValidatedRequest<SetupPasswordRequest>()
    const confirmPasswordValidator = new ConfirmPasswordValidator(
        () => request.password
    )

    const retrievePromise = useCallback((): Promise<User> => {
        if (isSignup) {
            return getSignupUser(code)
        }
        return getSetupPassword(code)
    }, [isSignup, code])

    const submitPromise = (): Promise<User> => {
        if (isSignup) {
            return signupUser(code, request)
        }
        return setupPassword(code, request)
    }

    useEffect(() => {
        setStatus('loading')
        retrievePromise()
            .then((response) => {
                setEmail(response.email)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [retrievePromise])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        submitPromise()
            .then((_) => {
                context.onSignedIn()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return <GoHome message={status} />
    }

    return (
        <ExternSurface
            title={
                translate(
                    isSignup
                        ? 'auth.activate.title'
                        : 'auth.setup_password.title'
                ) as string
            }
            description={
                translate(
                    isSignup ? 'auth.activate.text' : 'auth.setup_password.text'
                ) as string
            }
        >
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <input type="hidden" id="email" name="email" value={email} />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="password"
                            id="password"
                            name="password"
                            value={request.password}
                            label={translate('users.password') as string}
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="password"
                            id="confirm_password"
                            name="confirm_password"
                            value={request.confirm_password}
                            label={
                                translate('users.confirm_password') as string
                            }
                            validator={confirmPasswordValidator}
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                            >
                                {translate(
                                    isSignup
                                        ? 'auth.activate.button'
                                        : 'auth.setup_password.button'
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </ExternSurface>
    )
}
