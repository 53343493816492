import React from 'react'
import { Grid, styled } from '@mui/material'
import { Route, Redirect, Switch } from 'react-router-dom'

import SignInForm from '../auth/SignInForm'
import SignUpForm from '../auth/SignUpForm'
import RecoverPasswordForm from '../auth/RecoverPasswordForm'
import SignUpMessage from '../auth/SignUpMessage'
import ConfirmTenant from '../auth/ConfirmTenant'
import SignIn2FAForm from '../auth/SignIn2FAForm'
import VerifyEmail from '../auth/VerifyEmail'
import SetupPassword from '../auth/SetupPassword'

const MasterGrid = styled(Grid)(({ theme }) => ({
    minHeight: '100vh',
    backgroundImage: 'url("/login-screen-background.png")',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
}))

export default function ExternApp() {
    return (
        <MasterGrid container justifyContent="center" alignItems="center">
            <Switch>
                <Route path="/verify-email/:code" component={VerifyEmail} />
                <Route
                    path="/recover-password"
                    component={RecoverPasswordForm}
                />
                <Route
                    path="/signin/:code/password"
                    component={SetupPassword}
                />
                <Route path="/signin/:code" component={SignIn2FAForm} />
                <Route path="/signin" component={SignInForm} />
                <Route path="/signup/:code/confirm" component={ConfirmTenant} />
                <Route
                    path="/signup/:code/password"
                    component={SetupPassword}
                />
                <Route path="/signup/:code" component={SignUpMessage} />
                <Route path="/signup" component={SignUpForm} />
                <Redirect from="/*" to="/signin" />
            </Switch>
        </MasterGrid>
    )
}
