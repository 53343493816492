import React, { memo } from 'react'
import { styled } from '@mui/material'
import Flippy, { FrontSide, BackSide } from 'react-flippy'

const Image = styled('img')(({ theme }) => ({
    width: '100%',
    float: 'left',
}))

function Flipper({ front, back }) {
    if (front && back) {
        return (
            <Flippy
                flipOnClick
                flipDirection="horizontal"
                style={{ width: '240px', height: '160px' }}
            >
                <FrontSide style={{ padding: 0 }}>
                    <Image src={front} />
                </FrontSide>
                <BackSide style={{ padding: 0 }}>
                    <Image src={back} />
                </BackSide>
            </Flippy>
        )
    }

    return <Image src="/image-target-placeholder.jpg" sx={{ width: '240px' }} />
}

export default memo(Flipper)
