import React, { useRef, useContext } from 'react'
import { IconButton } from '@mui/material'
import { LayoutContext } from './LayoutContext'
import { MenuIcon } from '../components/Icons'

export function MenuButton() {
    const anchorRef = useRef<HTMLButtonElement>(null)
    const layoutContext = useContext(LayoutContext)

    return (
        <IconButton
            ref={anchorRef}
            aria-haspopup="true"
            size="small"
            color="inherit"
            onClick={layoutContext.toggleDrawer}
            sx={(theme) => {
                return {
                    display: { xs: 'flex' },
                    marginRight: theme.spacing(1),
                    marginLeft: theme.spacing(-1.5),
                }
            }}
        >
            <MenuIcon />
        </IconButton>
    )
}
