import React, { useContext } from 'react'
import { Grid, Button, Typography, Box } from '@mui/material'

import translate from '../i18n/Translator'
import { enable2FA, confirm2FA, update2FA } from '../api/MeAPI'
import { User, DoubleFactorAuthenticationRequest } from '../model/User'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { Code2FAValidator } from '../components/Validators'
import { FeedbackContext } from '../feedback/FeedbackContext'

export interface User2FAProps {
    user: User
    onUpdated(user: User): any
}

export default function User2FA(props: User2FAProps) {
    const feedbackContext = useContext(FeedbackContext)
    const code2FAValidator = new Code2FAValidator()
    const [request, , validations, hasChanged, clear] =
        useValidatedRequest<DoubleFactorAuthenticationRequest>()

    const onEnable2FA = () => {
        feedbackContext.showBackdrop()
        enable2FA()
            .then((user) => {
                feedbackContext.showSuccess(
                    translate('users.security.enabling_2fa') as string
                )
                cleanForm(user)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onConfirm2FA = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        confirm2FA(request!)
            .then((user) => {
                feedbackContext.showSuccess(
                    translate('users.security.confirmed_2fa') as string
                )
                cleanForm(user)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const onDisable2FA = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        update2FA(request!)
            .then((user) => {
                feedbackContext.showSuccess(
                    translate('users.security.disabled_2fa') as string
                )
                cleanForm(user)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const cancel2FA = () => {
        feedbackContext.showBackdrop()
        update2FA()
            .then((user) => {
                feedbackContext.showSuccess(
                    translate('users.security.disabled_2fa') as string
                )
                cleanForm(user)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    const cleanForm = (user: User) => {
        clear()
        props.onUpdated(user)
    }

    return (
        <Grid
            container
            spacing={3}
            alignContent="center"
            alignItems="center"
            justifyContent="center"
        >
            {props.user.status_2fa === 'DISABLED' && (
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                {translate(
                                    'users.security.2fa_status.DISABLED'
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box my={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    onClick={onEnable2FA}
                                >
                                    {translate(
                                        'users.security.buttons.enable_2fa'
                                    )}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {props.user.status_2fa === 'NOT_VERIFIED' && (
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="flex-start"
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item xs="auto">
                            <img
                                src={props.user.qr_url_2fa!}
                                alt="2FA QR"
                            ></img>
                        </Grid>
                        <Grid item xs={12} md>
                            <Typography variant="caption">
                                {translate(
                                    'users.security.2fa_status.NOT_VERIFIED',
                                    { secret: props.user.secret_2fa }
                                )}
                            </Typography>
                            <form
                                autoComplete="off"
                                noValidate
                                onSubmit={onConfirm2FA}
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    alignContent="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={12}>
                                        <Box mt={2}>
                                            <ValidatedInput
                                                type="text"
                                                id="code"
                                                name="code"
                                                value={request?.code}
                                                label={
                                                    translate(
                                                        'users.2fa_code'
                                                    ) as string
                                                }
                                                maxLength={6}
                                                onValueChanged={hasChanged}
                                                disabled={false}
                                                required
                                                validator={code2FAValidator}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box my={2}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                size="large"
                                            >
                                                {translate(
                                                    'users.security.buttons.enable_2fa'
                                                )}
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            size="large"
                                            onClick={cancel2FA}
                                        >
                                            {translate(
                                                'users.security.buttons.disable_2fa'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {props.user.status_2fa === 'ENABLED' && (
                <Grid item xs={12}>
                    <form autoComplete="off" noValidate onSubmit={onDisable2FA}>
                        <Grid
                            container
                            alignItems="center"
                            alignContent="center"
                            justifyContent="center"
                        >
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    {translate(
                                        'users.security.2fa_status.ENABLED'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedInput
                                    type="text"
                                    id="code"
                                    name="code"
                                    value={request?.code}
                                    label={
                                        translate('users.2fa_code') as string
                                    }
                                    maxLength={6}
                                    onValueChanged={hasChanged}
                                    disabled={false}
                                    required
                                    validator={code2FAValidator}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box my={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                    >
                                        {translate(
                                            'users.security.buttons.disable_2fa'
                                        )}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            )}
        </Grid>
    )
}
