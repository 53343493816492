import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Stack,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import translate from '../i18n/Translator'
import { ImageTargetViewerProps } from './ImageTargetViewer.types'

const Image = styled('img')(({ theme }) => ({
    maxWidth: '100%',
}))

function ImageTargetViewer({
    imageTarget,
    index,
    onEdit,
    onDelete,
}: ImageTargetViewerProps) {
    return (
        <Grid item xs={12} md={6}>
            <Card>
                <CardContent sx={{ p: 0 }}>
                    <Image
                        src={imageTarget.image}
                        alt={`${imageTarget.image.substring(
                            imageTarget.image.lastIndexOf('/')
                        )}`}
                        crossOrigin="anonymous"
                    />
                </CardContent>
                <CardActions sx={{ pt: 0 }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ width: '100%' }}
                    >
                        <Button
                            size="small"
                            color="inherit"
                            onClick={() => onEdit(index)}
                        >
                            {translate('buttons.edit')}
                        </Button>
                        <Button
                            size="small"
                            color="error"
                            onClick={() => onDelete(index)}
                        >
                            {translate('buttons.delete')}
                        </Button>
                    </Stack>
                </CardActions>
            </Card>
        </Grid>
    )
}

export default ImageTargetViewer
