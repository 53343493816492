import React from 'react'
import { styled } from '@mui/material/styles'

const LogoImage = styled('img')(({ theme }) => ({
    height: theme.spacing(3.5),
    marginTop: theme.spacing(0.75),
    '&.extern': {
        height: 'unset',
        marginTop: 0,
        width: '65%',
        marginBottom: theme.spacing(3),
    },
}))

interface LogoProps {
    text: string
    variant?: 'title' | 'extern'
}

export default function Logo({ text, variant = 'title' }: LogoProps) {
    return (
        <LogoImage
            src="/PIxlBank_ar_logo.png"
            alt="logo"
            className={variant === 'extern' ? 'extern' : ''}
        />
    )
}
