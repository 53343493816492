import React, {
    useState,
    useEffect,
    useContext,
    useMemo,
    useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Button, Typography, Box } from '@mui/material'
import { GuestsIcon } from '../components/Icons'
import { createGuest, getGuest, updateGuest } from '../api/GuestAPI'
import translate from '../i18n/Translator'
import { Guest, GuestRequest } from '../model/Guest'
import Progress from '../components/Progress'
import Surface from '../components/Surface'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { InputRef } from '../components/Input.types'
import { AppContext } from '../context/AppContext'
import { RouterParams } from '../router/RouterParams'
import { FeedbackContext } from '../feedback/FeedbackContext'
import { emptyPromise } from '../api/API'
import { DatePickerInput } from '../components/DatePickerInput'
import {
    DEFAULT_DATE_FORMAT,
    parseDate,
    formatDate,
} from '../components/DateFormat.types'
import { COUNTRY_CODES } from '../model/Country'

export default function GuestForm({ match }: RouterParams) {
    const history = useHistory()
    const feedbackContext = useContext(FeedbackContext)
    const context = useContext(AppContext)
    const paramTenantId = match.params.tenantId
    const tenantId = context.session?.tenant?.id || paramTenantId || '-'
    const guestId = match.params.guestId
    const countryLabels = useMemo(
        () => COUNTRY_CODES.map((el) => translate(`countries.${el}`) as string),
        []
    )

    const [status, setStatus] = useState<string>('loading')
    const [request, setRequest, validations, hasChanged, , hasChangedAny] =
        useValidatedRequest<GuestRequest>()

    const retrievePromise = useCallback(() => {
        if (guestId) {
            return getGuest(tenantId, guestId)
        }
        return emptyPromise()
    }, [tenantId, guestId])

    const submitPromise = (): Promise<Guest> => {
        if (guestId) {
            return updateGuest(tenantId, guestId, request)
        }
        return createGuest(tenantId, request)
    }

    useEffect(() => {
        setStatus('loading')
        retrievePromise()
            .then((guest) => {
                setRequest({
                    first_name: guest?.first_name,
                    last_name: guest?.last_name,
                    email: guest?.email,
                    phone: guest?.phone,
                    arrival: guest?.arrival,
                    departure: guest?.departure,
                    address: guest?.address,
                    line1: guest?.line1,
                    city: guest?.city,
                    state: guest?.state,
                    postal_code: guest?.postal_code,
                    country: guest?.country ?? 'USA',
                } as GuestRequest)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [retrievePromise, setRequest])

    const hasChangedDate = (
        name: string,
        value: string,
        inputRef: InputRef
    ) => {
        hasChangedAny(name, parseDate(value), inputRef)
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        submitPromise()
            .then((guest) => {
                feedbackContext.showSuccess(
                    translate(
                        `guests.${guestId ? 'edited' : 'created'}`,
                        guest
                    ) as string
                )
                history.replace(
                    `${
                        paramTenantId ? `/accounts/${paramTenantId}` : ''
                    }/guests`
                )
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    if (status === 'loading') {
        return <Progress />
    }

    if (status !== 'loaded') {
        return (
            <Typography
                variant="body1"
                component="h5"
                color="error"
                align="center"
            >
                {status}
            </Typography>
        )
    }

    return (
        <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={10} lg={7} xl={5}>
                    <Surface
                        title={translate(
                            guestId ? 'guests.edit' : 'guests.new'
                        )}
                        icon={<GuestsIcon />}
                    >
                        <form autoComplete="off" noValidate onSubmit={onSubmit}>
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                columnSpacing={2}
                            >
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="text"
                                        id="first_name"
                                        name="first_name"
                                        value={request.first_name}
                                        label={
                                            translate(
                                                'guests.first_name'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="text"
                                        id="last_name"
                                        name="last_name"
                                        value={request.last_name}
                                        label={
                                            translate(
                                                'guests.last_name'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={request.email}
                                        label={
                                            translate('guests.email') as string
                                        }
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={request.phone}
                                        label={
                                            translate('guests.phone') as string
                                        }
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2">
                                        {translate('guests.dates')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePickerInput
                                        name="arrival"
                                        value={formatDate({
                                            date: request.arrival,
                                        })}
                                        mode="date"
                                        format={DEFAULT_DATE_FORMAT}
                                        label={
                                            translate(
                                                'guests.arrival'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChangedDate}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePickerInput
                                        name="departure"
                                        value={formatDate({
                                            date: request.departure,
                                        })}
                                        mode="date"
                                        format={DEFAULT_DATE_FORMAT}
                                        label={
                                            translate(
                                                'guests.departure'
                                            ) as string
                                        }
                                        required
                                        minDate={
                                            request.arrival
                                                ? formatDate({
                                                      date: request.arrival,
                                                  })
                                                : undefined
                                        }
                                        onValueChanged={hasChangedDate}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="subtitle2">
                                        {translate('guests.mailing_address')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <ValidatedInput
                                        type="text"
                                        id="address"
                                        name="address"
                                        value={request.address}
                                        label={
                                            translate(
                                                'guests.address'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="text"
                                        id="city"
                                        name="city"
                                        value={request.city}
                                        label={
                                            translate('guests.city') as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="text"
                                        id="state"
                                        name="state"
                                        value={request.state}
                                        label={
                                            translate('guests.state') as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="text"
                                        id="country"
                                        name="country"
                                        value={request.country}
                                        options={COUNTRY_CODES}
                                        optionLabels={countryLabels}
                                        label={
                                            translate(
                                                'guests.country'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ValidatedInput
                                        type="text"
                                        id="postal_code"
                                        name="postal_code"
                                        value={request.postal_code}
                                        label={
                                            translate(
                                                'guests.postal_code'
                                            ) as string
                                        }
                                        required
                                        onValueChanged={hasChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={2}>
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            spacing={1}
                                            direction="row-reverse"
                                        >
                                            <Grid item xs={12} md="auto">
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                >
                                                    {translate(
                                                        guestId
                                                            ? 'buttons.update'
                                                            : 'buttons.add'
                                                    )}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md="auto">
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    size="large"
                                                    onClick={history.goBack}
                                                >
                                                    {translate(
                                                        'buttons.cancel'
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Surface>
                </Grid>
            </Grid>
        </Grid>
    )
}
