import { Project } from '../model/Project'

export const PREVIEW_URL = process.env.REACT_APP_VIEWER_DEV_URL || '#'

export const PRODUCTION_URL = process.env.REACT_APP_VIEWER_URL || '#'

export interface ProjectPreviewPopupProps {
    stage: 'PROD' | 'DEV'
    project: Project
    onClose(): any
}
