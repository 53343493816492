import React, { useState } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import QRCode from 'react-qr-code'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import Clipboard from '../components/Clipboard'
import { styled } from '@mui/material'
import {
    PREVIEW_URL,
    PRODUCTION_URL,
    ProjectPreviewPopupProps,
} from './ProjectPreviewPopup.types'

const Image = styled('img')(({ theme }) => ({
    width: '100%',
}))

function ProjectPreviewPopup({
    stage,
    project,
    onClose,
}: ProjectPreviewPopupProps) {
    const [url] = useState(() => {
        if (stage === 'DEV') {
            return `${PREVIEW_URL}/${project.id}`
        }
        return `${PRODUCTION_URL}/${project.id}`
    })

    return (
        <DialogPopup
            open
            title={translate(`projects.${stage}.title`) as string}
            maxWidth="xs"
            fullWidth
            onClose={onClose}
            button={
                <Button
                    color="primary"
                    component="a"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {translate('buttons.open') as string}
                </Button>
            }
        >
            <Typography variant="body2" paragraph>
                {translate(`projects.${stage}.text`, project)}
            </Typography>
            <Grid
                container
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
            >
                {stage === 'DEV' && (
                    <Grid item xs="auto">
                        <QRCode value={url} />
                    </Grid>
                )}
                {stage === 'PROD' && (
                    <Grid item xs={12} md={10}>
                        <Image src={project.qr_url} alt="qr" />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Clipboard value={url} />
                </Grid>
            </Grid>
        </DialogPopup>
    )
}

export default ProjectPreviewPopup
