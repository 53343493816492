import React from 'react'
import { SvgIcon, styled } from '@mui/material'

const PostcardsStyleOne = styled('rect')(({ theme }) => ({
    fill: '#fff',
}))
const PostcardsStyleTwo = styled('path')(({ theme }) => ({
    fill: 'currentColor',
}))
const PostcardsRectTwo = styled('rect')(({ theme }) => ({
    fill: 'currentColor',
}))
const PostcardsStyleThree = styled('path')(({ theme }) => ({
    fill: 'none',
    stroke: 'currentColor',
    strokeMiterlimit: 10,
    strokeWidth: '5.64px',
}))

export const PostcardsIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 294 203.35" {...props}>
            <PostcardsStyleOne
                x="9.06"
                y="8.42"
                width="275.88"
                height="186.52"
            />
            <g>
                <g>
                    <PostcardsStyleThree d="M274.55,199.09H19.45c-8.02,0-14.55-6.53-14.55-14.55V18.81c0-8.02,6.53-14.55,14.55-14.55h255.1c8.02,0,14.55,6.53,14.55,14.55V184.55c0,8.02-6.53,14.55-14.55,14.55ZM19.45,8.42c-5.73,0-10.39,4.66-10.39,10.39V184.55c0,5.73,4.66,10.39,10.39,10.39h255.1c5.73,0,10.39-4.66,10.39-10.39V18.81c0-5.73-4.66-10.39-10.39-10.39H19.45Z" />
                    <PostcardsRectTwo
                        x="175.84"
                        y="71.13"
                        width="4.16"
                        height="113.93"
                    />
                </g>
                <g>
                    <PostcardsRectTwo
                        x="19.79"
                        y="67.14"
                        width="74.3"
                        height="10.39"
                    />
                    <PostcardsRectTwo
                        x="19.79"
                        y="90.52"
                        width="131.97"
                        height="8.31"
                    />
                    <PostcardsRectTwo
                        x="19.79"
                        y="109.96"
                        width="131.97"
                        height="8.31"
                    />
                    <PostcardsRectTwo
                        x="19.79"
                        y="129.39"
                        width="131.97"
                        height="8.31"
                    />
                    <PostcardsRectTwo
                        x="19.79"
                        y="148.83"
                        width="131.97"
                        height="8.31"
                    />
                    <g>
                        <PostcardsRectTwo
                            x="193.5"
                            y="99.64"
                            width="81.57"
                            height="8.31"
                        />
                        <PostcardsRectTwo
                            x="193.5"
                            y="117.37"
                            width="81.57"
                            height="8.31"
                        />
                        <PostcardsRectTwo
                            x="193.5"
                            y="135.1"
                            width="81.57"
                            height="8.31"
                        />
                    </g>
                </g>
                <PostcardsStyleTwo d="M275.07,21.6v-3.54h-2.5c0,1.29-1.04,2.33-2.33,2.33s-2.33-1.04-2.33-2.33h-2.07c0,1.29-1.04,2.33-2.33,2.33s-2.33-1.04-2.33-2.33h-2.07c0,1.29-1.04,2.33-2.33,2.33s-2.33-1.04-2.33-2.33h-2.07c0,1.29-1.04,2.33-2.33,2.33s-2.33-1.04-2.33-2.33h-2.07c0,1.29-1.04,2.33-2.33,2.33s-2.33-1.04-2.33-2.33h-2.07c0,1.29-1.04,2.33-2.33,2.33s-2.33-1.04-2.33-2.33h-2.07c0,1.29-1.04,2.33-2.33,2.33s-2.33-1.04-2.33-2.33h-2.5v3.54c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v1.81c1.29,0,2.33,1.04,2.33,2.33s-1.04,2.33-2.33,2.33v3.54h2.5c0-1.29,1.04-2.33,2.33-2.33s2.33,1.04,2.33,2.33h2.07c0-1.29,1.04-2.33,2.33-2.33s2.33,1.04,2.33,2.33h2.07c0-1.29,1.04-2.33,2.33-2.33s2.33,1.04,2.33,2.33h2.07c0-1.29,1.04-2.33,2.33-2.33s2.33,1.04,2.33,2.33h2.07c0-1.29,1.04-2.33,2.33-2.33s2.33,1.04,2.33,2.33h2.07c0-1.29,1.04-2.33,2.33-2.33s2.33,1.04,2.33,2.33h2.07c0-1.29,1.04-2.33,2.33-2.33s2.33,1.04,2.33,2.33h2.5v-3.54c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33v-1.81c-1.29,0-2.33-1.04-2.33-2.33s1.04-2.33,2.33-2.33Zm-5.31,54.41h-39.48V23.76h39.48v52.25Z" />
                <g>
                    <PostcardsStyleTwo d="M212.1,37.43c-7.62,0-11.65-2.35-15.56-4.63-3.67-2.14-7.13-4.16-13.6-4.16s-10.8,2.08-15.13,4.28c-4.14,2.11-8.43,4.29-14.97,4.29-.6,0-1.09-.49-1.09-1.09s.49-1.09,1.09-1.09c6.01,0,9.88-1.97,13.98-4.05,4.36-2.22,8.88-4.52,16.12-4.52s10.95,2.27,14.7,4.46c3.82,2.23,7.43,4.33,14.45,4.33s10.35-2.03,14.04-4.18c3.89-2.27,7.92-4.61,15.81-4.61,.6,0,1.09,.49,1.09,1.09s-.49,1.09-1.09,1.09c-7.3,0-10.9,2.1-14.71,4.31-3.78,2.2-7.69,4.48-15.14,4.48Z" />
                    <PostcardsStyleTwo d="M212.1,45.13c-7.62,0-11.65-2.35-15.56-4.63-3.67-2.14-7.13-4.16-13.6-4.16s-10.8,2.08-15.13,4.28c-4.14,2.11-8.43,4.29-14.97,4.29-.6,0-1.09-.49-1.09-1.09s.49-1.09,1.09-1.09c6.01,0,9.88-1.97,13.98-4.05,4.36-2.22,8.88-4.52,16.12-4.52s10.95,2.27,14.7,4.46c3.82,2.23,7.43,4.33,14.45,4.33s10.35-2.03,14.04-4.18c3.89-2.27,7.92-4.61,15.81-4.61,.6,0,1.09,.49,1.09,1.09s-.49,1.09-1.09,1.09c-7.3,0-10.9,2.1-14.71,4.31-3.78,2.2-7.69,4.48-15.14,4.48Z" />
                    <PostcardsStyleTwo d="M212.1,52.82c-7.62,0-11.65-2.35-15.56-4.63-3.67-2.14-7.13-4.16-13.6-4.16s-10.8,2.08-15.13,4.28c-4.14,2.11-8.43,4.29-14.97,4.29-.6,0-1.09-.49-1.09-1.09s.49-1.09,1.09-1.09c6.01,0,9.88-1.97,13.98-4.05,4.36-2.22,8.88-4.52,16.12-4.52s10.95,2.27,14.7,4.46c3.82,2.23,7.43,4.33,14.45,4.33s10.35-2.03,14.04-4.18c3.89-2.27,7.92-4.61,15.81-4.61,.6,0,1.09,.49,1.09,1.09s-.49,1.09-1.09,1.09c-7.3,0-10.9,2.1-14.71,4.31-3.78,2.2-7.69,4.48-15.14,4.48Z" />
                    <PostcardsStyleTwo d="M212.1,60.51c-7.62,0-11.65-2.35-15.56-4.63-3.67-2.14-7.13-4.16-13.6-4.16s-10.8,2.08-15.13,4.28c-4.14,2.11-8.43,4.29-14.97,4.29-.6,0-1.09-.49-1.09-1.09s.49-1.09,1.09-1.09c6.01,0,9.88-1.97,13.98-4.05,4.36-2.22,8.88-4.52,16.12-4.52s10.95,2.27,14.7,4.46c3.82,2.23,7.43,4.33,14.45,4.33s10.35-2.03,14.04-4.18c3.89-2.27,7.92-4.61,15.81-4.61,.6,0,1.09,.49,1.09,1.09s-.49,1.09-1.09,1.09c-7.3,0-10.9,2.1-14.71,4.31-3.78,2.2-7.69,4.48-15.14,4.48Z" />
                    <PostcardsStyleTwo d="M212.1,68.21c-7.62,0-11.65-2.35-15.56-4.63-3.67-2.14-7.13-4.16-13.6-4.16s-10.8,2.08-15.13,4.28c-4.14,2.11-8.43,4.29-14.97,4.29-.6,0-1.09-.49-1.09-1.09s.49-1.09,1.09-1.09c6.01,0,9.88-1.97,13.98-4.05,4.36-2.22,8.88-4.52,16.12-4.52s10.95,2.27,14.7,4.46c3.82,2.23,7.43,4.33,14.45,4.33s10.35-2.03,14.04-4.18c3.89-2.27,7.92-4.61,15.81-4.61,.6,0,1.09,.49,1.09,1.09s-.49,1.09-1.09,1.09c-7.3,0-10.9,2.1-14.71,4.31-3.78,2.2-7.69,4.48-15.14,4.48Z" />
                </g>
            </g>
        </SvgIcon>
    )
}
