import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import FeedbackProvider from './feedback/FeedbackProvider'
import { AppContext } from './context/AppContext'
import SessionHolder from './context/SessionHolder'
import { initTranslator } from './i18n/Translator'
import AppColorTheme from './theme.json'
import './App.css'
import { routes } from './router/Routes'

// https://material-ui.com/customization/default-theme/
// https://material-ui.com/customization/color/
const theme = createTheme(AppColorTheme)

function App() {
    const [context, setContext] = useState(() => {
        let actions = []
        return {
            setSession: (session) => {
                setContext({ ...context, session: session })
                actions = session?.actions ?? []
            },
            isGranted: (action) => actions.findIndex((a) => a === action) >= 0,
            isGrantedAny: (all) => {
                for (let i = 0; i < all.length; i++) {
                    if (actions.findIndex((a) => a === all[i]) >= 0) {
                        return true
                    }
                }
                return false
            },
        }
    })

    initTranslator()

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <FeedbackProvider>
                    <AppContext.Provider value={context}>
                        <SessionHolder routes={routes} />
                    </AppContext.Provider>
                </FeedbackProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App
