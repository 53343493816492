import React, { ReactNode } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import translate from '../i18n/Translator'
import Logo from './Logo'

interface ExternSurfaceProps {
    title: string
    description?: ReactNode
    header?: ReactNode
    children: ReactNode
    showTitle?: boolean
    sm?: true | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    md?: true | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    lg?: true | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    xl?: true | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

export default function ExternSurface(props: ExternSurfaceProps) {
    return (
        <Grid
            item
            xs={12}
            sm={props.sm || 10}
            md={props.md || 6}
            lg={props.lg || 4}
            xl={props.xl || 3}
        >
            <Box my={4} id="xt">
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={11} lg={12}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            {props.header && (
                                <Typography
                                    component="h4"
                                    variant="h4"
                                    color="textSecondary"
                                    gutterBottom
                                >
                                    {props.header}
                                </Typography>
                            )}
                            <Logo
                                text={translate('app.title') as string}
                                variant="extern"
                            />
                            <Grid item xs={12}>
                                {props.showTitle && props.title && (
                                    <Box mb={3}>
                                        <Typography
                                            component="h5"
                                            variant="h5"
                                            align="center"
                                        >
                                            {props.title}
                                        </Typography>
                                    </Box>
                                )}
                                {props.description && (
                                    <Typography
                                        component="p"
                                        variant="body1"
                                        align="center"
                                    >
                                        {props.description}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {props.children}
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ mt: 8 }}
                        >
                            <Grid item xs="auto">
                                <Button
                                    component="a"
                                    variant="text"
                                    color="inherit"
                                    size="small"
                                    href="mailto:arsupport@pixlbank.com"
                                >
                                    {translate('buttons.contact')}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    component="a"
                                    variant="text"
                                    color="inherit"
                                    size="small"
                                    href="https://www.pixlbank.com/ar-privacy-policy"
                                    target="_blank"
                                >
                                    {translate('buttons.privacy_policy')}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    component="a"
                                    variant="text"
                                    color="inherit"
                                    size="small"
                                    href="https://www.pixlbank.com/ar-terms-and-conditions"
                                    target="_blank"
                                >
                                    {translate('buttons.terms_conditions')}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography
                                    component="h6"
                                    variant="caption"
                                    color="primaryText"
                                    textAlign="center"
                                >
                                    {translate('auth.copyright', {
                                        year: new Date().getFullYear(),
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}
