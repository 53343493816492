import React, { useContext, useRef } from 'react'
import { IconButton, TextField } from '@mui/material'
import { CopyIcon } from './Icons'
import { FeedbackContext } from '../feedback/FeedbackContext'
import translate from '../i18n/Translator'

interface ClipboardProps {
    value: string
}

function Clipboard({ value }: ClipboardProps) {
    const feedbackContext = useContext(FeedbackContext)
    const inputRef = useRef<HTMLInputElement>(null)

    const copyToClipboard = () => {
        if (!inputRef.current) {
            feedbackContext.showWarning(
                translate('clipboard.no_input') as string
            )
            return
        }

        if (navigator.userAgent.match(/ipad|iphone/i)) {
            const range = document.createRange()
            range.selectNodeContents(inputRef.current)

            const selection = window.getSelection()
            if (!selection) {
                feedbackContext.showWarning(
                    translate('clipboard.no_selection') as string
                )
                return
            }

            selection.removeAllRanges()
            selection.addRange(range)
            inputRef.current.setSelectionRange(0, 999999)
        } else {
            inputRef.current.select()
        }

        document.execCommand('copy')
        feedbackContext.showSuccess(translate('clipboard.copied') as string)
    }

    return (
        <TextField
            variant="outlined"
            margin="none"
            size="small"
            color="info"
            defaultValue={value}
            inputRef={inputRef}
            InputProps={{
                endAdornment: (
                    <IconButton color="info" onClick={copyToClipboard}>
                        <CopyIcon />
                    </IconButton>
                ),
                readOnly: true,
            }}
            fullWidth
        />
    )
}

export default Clipboard
