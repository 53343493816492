import { callAPI, HTTPMethod, hasJwt } from './API'
import { Session } from '../model/Session'
import {
    User,
    BasicProfileRequest,
    PasswordRequest,
    PasswordResponse,
    DoubleFactorAuthenticationRequest,
    EmailRequest,
} from '../model/User'

export async function me(): Promise<User> {
    return await callAPI('/users/me', {
        method: HTTPMethod.GET,
    })
}

export async function getSession(): Promise<Session> {
    if (hasJwt()) {
        return await callAPI('/users/me/session', {
            method: HTTPMethod.GET,
        })
    }

    return new Promise((_, reject) => {
        reject(new Error('Missing authentication token'))
    })
}

export async function updateSession(tenantId: string): Promise<Session> {
    return await callAPI(`/users/me/session`, {
        method: HTTPMethod.PUT,
        query: {
            tenant_id: tenantId,
        },
    })
}

export async function updateBasicProfile(
    request: BasicProfileRequest
): Promise<User> {
    return await callAPI('/users/me/basic', {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function updatePassword(
    request: PasswordRequest
): Promise<PasswordResponse> {
    return await callAPI('/users/me/password', {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function enable2FA(): Promise<User> {
    return await callAPI('/users/me/2fa', {
        method: HTTPMethod.POST,
    })
}

export async function confirm2FA(
    request: DoubleFactorAuthenticationRequest
): Promise<User> {
    return await callAPI('/users/me/2fa', {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function update2FA(
    request?: DoubleFactorAuthenticationRequest
): Promise<User> {
    return await callAPI('/users/me/2fa', {
        method: HTTPMethod.PATCH,
        body: request ?? ({} as DoubleFactorAuthenticationRequest),
    })
}

export async function updateEmail(request: EmailRequest): Promise<User> {
    return await callAPI('/users/me/email', {
        method: HTTPMethod.PUT,
        body: request,
    })
}

export async function revertEmail(): Promise<User> {
    return await callAPI('/users/me/email', {
        method: HTTPMethod.DELETE,
    })
}
