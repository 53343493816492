import T from 'i18n-react'
import TextsEs from './es.json'
import TextsEn from './en.json'

export function initTranslator(lang: 'en' | 'es' = 'en') {
    if (lang === 'en') {
        T.setTexts(TextsEn)
    } else {
        T.setTexts(TextsEs)
    }
}

export default function translator(key: string, options?: any) {
    return T.translate(key, options)
}
