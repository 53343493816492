import React, { useContext } from 'react'
import { Grid, Button, Link, Box } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { recoverPassword } from '../api/AuthAPI'
import { RecoverPasswordRequest } from '../model/RecoverPassword'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import translate from '../i18n/Translator'
import ExternSurface from '../components/ExternSurface'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function RecoverPasswordForm() {
    const feedbackContext = useContext(FeedbackContext)
    const [request, , validations, hasChanged, clearForm] =
        useValidatedRequest<RecoverPasswordRequest>()

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        recoverPassword(request)
            .then((response) => {
                if (response.content) {
                    feedbackContext.showSuccess(
                        translate('auth.recover.success', {
                            email: request.email,
                        }) as string
                    )
                    clearForm()
                } else {
                    feedbackContext.showError(
                        translate('auth.recover.error') as string
                    )
                }
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    return (
        <ExternSurface title={translate('auth.recover.title') as string}>
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="email"
                            id="email"
                            name="email"
                            label={translate('users.email') as string}
                            value={request.email}
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                            >
                                {translate('buttons.recover')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs="auto">
                        <Link component={RouterLink} to="/signin">
                            <small>{translate('buttons.signin')}</small>
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </ExternSurface>
    )
}
