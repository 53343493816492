import React, { useContext, useState } from 'react'
import { Button, Typography } from '@mui/material'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import { deployProject } from '../api/ProjectAPI'
import { FeedbackContext } from '../feedback/FeedbackContext'
import { ProjectPreviewPopupProps } from './ProjectDeployPopup.types'

function ProjectDeployPopup({
    project,
    onDeployed,
    onClose,
}: ProjectPreviewPopupProps) {
    const feedbackContext = useContext(FeedbackContext)
    const [deploying, setDeploying] = useState(false)

    const onDeploy = () => {
        setDeploying(true)
        onClose()

        feedbackContext.showBackdrop(
            translate('projects.deploy.deploying', {
                name: project.name,
            }) as string
        )
        deployProject(project.id)
            .then((project) => {
                feedbackContext.showSuccess(
                    translate('projects.deploy.success', {
                        name: project.name,
                    }) as string
                )
                onDeployed(project)
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    return (
        <DialogPopup
            open
            title={translate('projects.deploy.title') as string}
            maxWidth="xs"
            fullWidth
            onClose={onClose}
            disableBackdropClick={deploying}
            disableEscapeKeyDown={deploying}
            button={
                <Button color="primary" onClick={onDeploy}>
                    {translate('buttons.deploy') as string}
                </Button>
            }
        >
            <Typography variant="body2">
                {translate('projects.deploy.text', project)}
            </Typography>
        </DialogPopup>
    )
}

export default ProjectDeployPopup
