import React, { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    Fade,
    Divider,
} from '@mui/material'
import translate from '../i18n/Translator'
import {
    DeleteIcon,
    EditIcon,
    VisibilityIcon,
    DeployIcon,
    QrIcon,
} from '../components/Icons'
import { ProjectMenuProps } from './ProjectMenu.types'
import { AppContext } from '../context/AppContext'

export default function ProjectMenu({
    project,
    anchor,
    variant = 'list',
    onClose,
    onPreview,
    onQr,
    onDeploy,
    onDelete,
}: ProjectMenuProps) {
    const context = useContext(AppContext)
    const isGrantedUpdate = useMemo(
        () => context.isGranted('ProjectsUpdate'),
        [context]
    )
    const isGrantedDelete = useMemo(
        () => context.isGranted('ProjectsDelete'),
        [context]
    )

    return (
        <Menu
            id="projects-item"
            anchorEl={anchor}
            keepMounted
            onClose={onClose}
            open
            TransitionComponent={Fade}
        >
            {isGrantedUpdate && variant === 'list' && (
                <MenuItem
                    component={Link}
                    to={`/projects/${project.id}/edit`}
                    onClick={onClose}
                    dense
                >
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.edit')}
                    </Typography>
                </MenuItem>
            )}
            <MenuItem onClick={onPreview} dense>
                <ListItemIcon>
                    <VisibilityIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {translate('buttons.preview')}
                </Typography>
            </MenuItem>
            {isGrantedUpdate && project.content?.DEV && (
                <MenuItem onClick={onDeploy} dense>
                    <ListItemIcon>
                        <DeployIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.deploy')}
                    </Typography>
                </MenuItem>
            )}
            {!!project.content?.PROD && !!project.qr_url && (
                <MenuItem onClick={onQr} dense>
                    <ListItemIcon>
                        <QrIcon fontSize="small" color="success" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.qr')}
                    </Typography>
                </MenuItem>
            )}
            {isGrantedDelete && variant === 'list' && <Divider />}
            {isGrantedDelete && variant === 'list' && (
                <MenuItem onClick={onDelete} dense>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {translate('buttons.delete')}
                    </Typography>
                </MenuItem>
            )}
        </Menu>
    )
}
