import React, { useContext } from 'react'
import { Box, Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InternDrawerProps } from './InternDrawer.types'
import { LayoutContext } from './LayoutContext'
import { AppMenu } from '../router/AppRouter'

const DrawerNav = styled('nav')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        display: 'flex',
    },
}))

export function InternDrawer({ routes }: InternDrawerProps) {
    const layoutContext = useContext(LayoutContext)

    return (
        <DrawerNav
            sx={{
                '& .MuiDrawer-paper': {
                    width: layoutContext.drawer.width,
                },
            }}
        >
            <Drawer
                id="drawer"
                anchor="left"
                variant={layoutContext.mobile ? 'temporary' : 'persistent'}
                open={!layoutContext.mobile || layoutContext.drawer.open}
                onClose={
                    layoutContext.mobile
                        ? () => layoutContext.openDrawer(false, false)
                        : undefined
                }
            >
                <Box
                    id="drawer-menu"
                    sx={(theme) => {
                        return {
                            visibility: layoutContext.drawer.open
                                ? 'visible'
                                : 'hidden',
                            minWidth: layoutContext.mobile
                                ? layoutContext.drawer.width
                                : 'unset',
                            marginTop: theme.spacing(8),
                        }
                    }}
                >
                    <AppMenu />
                </Box>
            </Drawer>
        </DrawerNav>
    )
}
