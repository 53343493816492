import React, { memo, useContext, useMemo } from 'react'
import translate from '../i18n/Translator'
import DialogPopup from '../components/DialogPopup'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { CreditPurchaseRequest } from '../model/Credit'
import { Button } from '@mui/material'
import { purchaseCredits } from '../api/CreditAPI'
import { FeedbackContext } from '../feedback/FeedbackContext'
import { GTZeroValidator, IntegerValidator } from '../components/Validators'
import { Tenant } from '../model/Tenant'

interface CreditPurchaseFormatProps {
    tenant: Tenant
    onClose(): any
}

function CreditPurchaseFormat({ tenant, onClose }: CreditPurchaseFormatProps) {
    const feedbackContext = useContext(FeedbackContext)
    const [request, , validations, hasChanged] =
        useValidatedRequest<CreditPurchaseRequest>()
    const validators = useMemo(
        () => [new IntegerValidator(), new GTZeroValidator()],
        []
    )

    const onSubmit = () => {
        if (!isValid(validations)) return

        feedbackContext.showBackdrop()
        purchaseCredits(tenant.id, request)
            .then((response) => {
                feedbackContext.showSuccess(
                    translate('credits.purchase.success', {
                        credits: response.credits,
                        name: tenant.name,
                    }) as string
                )
                onClose()
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    return (
        <DialogPopup
            open
            title={translate('credits.purchase.title')}
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            button={
                <Button onClick={onSubmit} color="primary">
                    {translate('buttons.add') as string}
                </Button>
            }
        >
            <ValidatedInput
                type="number"
                id="credits"
                name="credits"
                label={translate('credits.purchase.field') as string}
                validators={validators}
                required
                onValueChanged={hasChanged}
            />
        </DialogPopup>
    )
}

export default memo(CreditPurchaseFormat)
