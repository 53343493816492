import React, { memo, useMemo } from 'react'
import { GuestImportResponse } from '../model/Guest'
import DialogPopup from '../components/DialogPopup'
import translate from '../i18n/Translator'
import {
    Card,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material'
import { ErrorIcon, InfoIcon, SuccessIcon } from '../components/Icons'
import { Griddable } from '../components/Griddable'

interface GuestImportResultPopupProps {
    result: GuestImportResponse
    onClose(): any
}

function GuestImportResultPopup({
    result,
    onClose,
}: GuestImportResultPopupProps) {
    const errorCount = useMemo<number>(
        () => Object.keys(result.errors ?? {}).length,
        [result]
    )

    return (
        <DialogPopup
            open
            title={translate('guests.import_results.title')}
            onClose={onClose}
            fullWidth
            maxWidth={errorCount === 0 ? 'xs' : 'sm'}
        >
            <Grid container>
                <Grid item xs={12}>
                    <List dense disablePadding>
                        <ListItem>
                            <ListItemIcon>
                                <InfoIcon color="info" />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate(
                                    'guests.import_results.read'
                                )}
                            />
                            <ListItemSecondaryAction>
                                {result.read}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SuccessIcon color="success" />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate(
                                    'guests.import_results.created'
                                )}
                            />
                            <ListItemSecondaryAction>
                                {result.created}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <ErrorIcon color="error" />
                            </ListItemIcon>
                            <ListItemText
                                primary={translate(
                                    'guests.import_results.errors'
                                )}
                            />
                            <ListItemSecondaryAction>
                                {errorCount}
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
                {errorCount > 0 && (
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Card variant="outlined">
                            <Griddable
                                items={
                                    Object.keys(
                                        result.errors ?? {}
                                    ) as unknown as number[]
                                }
                                getId={(el) => `${el}`}
                                loading={false}
                                columns={[
                                    {
                                        title: translate(
                                            'guests.import_results.index'
                                        ),
                                        converter: (el) => el,
                                        id: 'index',
                                        xs: 3,
                                        sm: 3,
                                        md: 3,
                                        lg: 3,
                                        xl: 2,
                                    },
                                    {
                                        title: translate(
                                            'guests.import_results.error'
                                        ),
                                        converter: (el) => result.errors[el],
                                        id: 'error',
                                        xs: true,
                                        sm: true,
                                        md: true,
                                        lg: true,
                                        xl: true,
                                    },
                                ]}
                            />
                        </Card>
                    </Grid>
                )}
            </Grid>
        </DialogPopup>
    )
}

export default memo(GuestImportResultPopup)
