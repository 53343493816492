import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
    Grid,
    IconButton,
    Typography,
    NativeSelect,
    InputBase,
    Box,
} from '@mui/material'
import { LeftIcon, RightIcon } from '../components/Icons'
import translate from '../i18n/Translator'
import Surface from './Surface'

interface PaginationProps {
    title: string | React.ReactNode
    subtitle?: string | React.ReactNode
    icon: React.ReactNode
    children: React.ReactNode
    action?: React.ReactNode
    page: number
    pageSize: number
    count: number
    total: number
    variant?: 'section' | 'embeded'
    backButton?: boolean
    backTo?: string
    onChangedPage(page: number): void
    onChangedPageSize(page: number, pageSize: number): void
}

const textStyle = {
    fontSize: '0.875rem',
    fontWeight: 500,
}

export default function Pagination(props: PaginationProps) {
    const history = useHistory()
    const from = props.pageSize * (props.page - 1) + (props.count === 0 ? 0 : 1)
    const to = props.pageSize * (props.page - 1) + props.count
    const sizes = [20, 50, 100]
    const query = new URLSearchParams(useLocation().search)

    const page = initialPage(query)
    const pageSize = initialPageSize(query)

    // eslint-disable-next-line
    useEffect(() => props.onChangedPageSize(page, pageSize), [page, pageSize])

    const changedPageSize = (event: React.ChangeEvent<HTMLSelectElement>) => {
        query.set('page', '1')
        query.set('pageSize', event.target.value)

        updateRoute()
        props.onChangedPageSize(1, parseInt(event.target.value))
    }

    const nextPage = () => {
        query.set('page', (props.page + 1).toString())

        updateRoute()
        props.onChangedPage(props.page + 1)
    }

    const previousPage = () => {
        query.set('page', (props.page - 1).toString())

        updateRoute()
        props.onChangedPage(props.page - 1)
    }

    const updateRoute = () => {
        history.push(`${window.location.pathname}?${query.toString()}`)
    }

    const footerActions = () => {
        return (
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs="auto">
                    <NativeSelect
                        onChange={changedPageSize}
                        value={props.pageSize}
                        input={<InputBase />}
                        sx={textStyle}
                    >
                        {sizes.map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </NativeSelect>
                </Grid>
                <Grid item xs="auto">
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item xs="auto">
                            <Typography
                                component="span"
                                variant="body2"
                                sx={textStyle}
                            >
                                {translate('pagination', {
                                    from: from,
                                    to: to,
                                    total: props.total,
                                })}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <IconButton
                                aria-label="Previous page"
                                onClick={previousPage}
                                disabled={props.page === 1}
                            >
                                <LeftIcon />
                            </IconButton>
                            <IconButton
                                aria-label="Next page"
                                onClick={nextPage}
                                disabled={
                                    props.count === 0 ||
                                    props.count < props.pageSize
                                }
                            >
                                <RightIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    if (props.variant === 'embeded') {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {props.children}
                </Grid>
                <Grid item xs={12}>
                    <Box pl={2}>{footerActions()}</Box>
                </Grid>
            </Grid>
        )
    }

    return (
        <Surface
            title={props.title}
            subtitle={props.subtitle}
            icon={props.icon}
            titleActions={props.action}
            footerActions={footerActions()}
            backButton={props.backButton}
            backTo={props.backTo}
        >
            {props.children}
        </Surface>
    )
}

export const initialPagination = (
    query: URLSearchParams,
    callback: (page: number, pageSize: number) => any
) => {
    const page = initialPage(query)
    const pageSize = initialPageSize(query)
    callback(page, pageSize)
}

export const initialPage = (query: URLSearchParams): number => {
    const value = parseInt(query.get('page') || '')
    return isNaN(value) ? 1 : value
}

export const initialPageSize = (query: URLSearchParams): number => {
    const value = parseInt(query.get('pageSize') || '')
    return isNaN(value) ? 20 : value
}

export const getOffset = (page: number, pageSize: number): number => {
    return (page - 1) * pageSize
}
