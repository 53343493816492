import React, { useCallback, useEffect, useState } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import { LayoutContext } from './LayoutContext'
import { LayoutDrawer, LayoutUser } from './LayoutContext.types'
import { LayoutProviderProps } from './LayoutProvider.types'

const DRAWER_WIDTH = 260

export function LayoutProvider({ children }: LayoutProviderProps) {
    const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const isOpen = useCallback((): boolean => {
        return localStorage.getItem('ShowDrawer') !== 'false'
    }, [])

    const defineDrawer = useCallback((open: boolean): LayoutDrawer => {
        const width = open ? DRAWER_WIDTH : 0
        return { open, width }
    }, [])

    const [drawer, setDrawer] = useState<LayoutDrawer>(() => {
        const open = isOpen()
        return defineDrawer(!mobile && open)
    })

    const [user, setUser] = useState<LayoutUser>(() => {
        return {
            blocked: false,
        }
    })

    useEffect(() => {
        const open = isOpen()
        setDrawer(defineDrawer(!mobile && open))
    }, [defineDrawer, isOpen, mobile])

    const toggleDrawer = () => {
        openDrawer(!drawer.open, !mobile)
    }

    const closeDrawer = () => {
        if (mobile && drawer.open) {
            openDrawer(false, false)
        }
    }

    const openDrawer = (open: boolean, write: boolean) => {
        const drawer = defineDrawer(open)
        setDrawer(drawer)

        if (write) {
            localStorage.setItem('ShowDrawer', `${drawer.open}`)
        }
    }

    const blockUser = () => {
        setUser({ ...user, blocked: true })
    }

    return (
        <LayoutContext.Provider
            value={{
                mobile,
                drawer,
                user,
                toggleDrawer,
                openDrawer,
                closeDrawer,
                blockUser,
            }}
        >
            {children}
        </LayoutContext.Provider>
    )
}
