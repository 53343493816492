import { ListParams, ListResponse } from './Generic'

export interface Project {
    id: string
    name: string
    orientation: ProjectOrientation
    description?: string
    domain?: string
    status: ProjectStatus
    content?: ProjectContent
    qr_url?: string
    created_at: Date
}

export interface Projects extends ListResponse<Project> {}
export interface ProjectListParams extends ListParams {}
export interface ProjectGetParams {}

export interface ProjectRequest {
    id: string
    name: string
    orientation: ProjectOrientation
    description?: string
    domain?: string
    stage_data: ProjectStageDataRequest
}

export interface ProjectStageDataRequest {
    image_targets: ImageTarget[]
    splash?: ProjectSplash
    mind: string
}

export interface ImageTarget {
    image: string
    video: string
    width: number
    height: number
    button?: ImageTargetButton
}

export interface ImageTargetButton {
    text: string
    url: string
    image: string
    timing?: string
}

export type ProjectContent = {
    [key in ProjectStage]: ProjectStageData
}

export interface ProjectStageData {
    mind: string
    splash: ProjectSplash
    image_targets: ImageTarget[]
}

export interface ProjectSplash {
    logo: string
    logo_position: string
    logo_size: string
    background: string
}

export interface ProjectPreview extends ProjectStageData {}

export type ProjectStatus = 'NEW' | 'AVAILABLE' | 'DELETED'

export type ProjectStage = 'DEV' | 'PROD'

export type ProjectOrientation = 'LANDSCAPE' | 'PORTRAIT'

export const PROJECT_STATUSES: ProjectStatus[] = ['NEW', 'AVAILABLE', 'DELETED']

export const PROJECT_ORIENTATIONS: ProjectOrientation[] = [
    'LANDSCAPE',
    'PORTRAIT',
]

export const isEqualsImageTarget = (
    current: ImageTarget,
    request: ImageTarget
) => {
    return (
        current.image === request.image &&
        current.video === request.video &&
        current.width === request.width &&
        current.height === request.height
    )
}

export const isEquals = (current: ImageTarget[], request: ImageTarget[]) => {
    if (current.length !== request.length) {
        return false
    }

    for (const imageTarget of current) {
        const requestImageTarget = request.find(
            (el) => el.image === imageTarget.image
        )
        if (
            !requestImageTarget ||
            !isEqualsImageTarget(imageTarget, requestImageTarget)
        ) {
            return false
        }
    }

    return true
}
