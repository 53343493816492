import React, { useContext } from 'react'
import {
    Grid,
    Button,
    Link,
    Box,
    Typography,
    Card,
    CardContent,
} from '@mui/material'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import { signin } from '../api/AuthAPI'
import { SignInRequest } from '../model/SignIn'
import ValidatedInput, {
    isValid,
    useValidatedRequest,
} from '../components/ValidatedInput'
import { AppContext } from '../context/AppContext'
import translate from '../i18n/Translator'
import ExternSurface from '../components/ExternSurface'
import { FeedbackContext } from '../feedback/FeedbackContext'

export default function SignInForm() {
    const history = useHistory()
    const context = useContext(AppContext)
    const feedbackContext = useContext(FeedbackContext)
    const [request, , validations, hasChanged] =
        useValidatedRequest<SignInRequest>()

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!isValid(validations)) {
            return
        }

        feedbackContext.showBackdrop()
        signin(request)
            .then((response) => {
                switch (response.status) {
                    case 'SIGNED_IN':
                        context.onSignedIn()
                        break
                    case 'TENANT_VERIFICATION':
                        history.push(`/signup/${response.tenant_signup_code!}`)
                        break
                    case 'REQUIRED_2FA':
                        history.push(`/signin/${response.signin_2fa_code!}`)
                        break
                    default:
                        break
                }
            })
            .catch((error) => {
                feedbackContext.showError(error.message)
            })
            .finally(() => {
                feedbackContext.closeBackdrop()
            })
    }

    return (
        <ExternSurface
            title={translate('auth.signin.title') as string}
            header={translate('auth.signin.header')}
        >
            <form autoComplete="off" noValidate onSubmit={onSubmit}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="email"
                            id="email"
                            name="email"
                            value={request.email}
                            label={translate('users.email') as string}
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput
                            type="password"
                            id="password"
                            name="password"
                            value={request.password}
                            label={translate('users.password') as string}
                            required
                            onValueChanged={hasChanged}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                            >
                                {translate('buttons.signin')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs="auto">
                        <Link component={RouterLink} to="/recover-password">
                            <small>{translate('buttons.recover')}</small>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle1">
                                    {translate('auth.signin.security.title')}
                                </Typography>
                                <Typography variant="body2">
                                    {translate('auth.signin.security.text')}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </ExternSurface>
    )
}
