import React, { memo, useEffect, useState } from 'react'
import { Design, DesignPreview, DesignPreviewRequest } from '../model/Design'
import Progress from '../components/Progress'
import { Guest, GuestRequest } from '../model/Guest'
import { previewDesign } from '../api/DesignAPI'
import { Grid, Typography, styled } from '@mui/material'

const Image = styled('img')(({ theme }) => ({
    width: '100%',
    float: 'left',
}))

export interface DesignPreviewGridProps {
    tenantId: string
    design: Design
    guest: Guest
    message?: string
}

function DesignPreviewGrid({
    tenantId,
    design,
    guest,
    message,
}: DesignPreviewGridProps) {
    const [status, setStatus] = useState('loading')
    const [preview, setPreview] = useState<DesignPreview>()

    useEffect(() => {
        const request = {
            guest: guest as GuestRequest,
            message,
        } as DesignPreviewRequest

        setStatus('loading')
        previewDesign(tenantId, design.id, request)
            .then((preview) => {
                setPreview(preview)
                setStatus('loaded')
            })
            .catch((error) => {
                setStatus(error.message)
            })
    }, [tenantId, design, guest, message])

    return (
        <Grid container>
            <Grid item xs={12}>
                {status === 'loading' && <Progress mt={1} />}
                {status === 'loaded' && preview && (
                    <Image
                        src={`data:image/png;base64, ${preview.back_image}`}
                    />
                )}
                {status !== 'loading' && status !== 'loaded' && (
                    <Typography
                        variant="body1"
                        component="h5"
                        color="error"
                        align="center"
                    >
                        {status}
                    </Typography>
                )}
            </Grid>
        </Grid>
    )
}

export default memo(DesignPreviewGrid)
