import { styled } from '@mui/material'
import React, { memo } from 'react'

const ImageTag = styled('img')(({ theme }) => ({
    width: '100%',
    float: 'left',
}))

interface ImageProps {
    src: string
}

function Image({ src }: ImageProps) {
    return <ImageTag src={src} />
}

export default memo(Image)
